@use "type-mixins" as *;

@mixin text-9xl {
    font-size: rem-font-size(90px);
    line-height: line-height(90px, 96px);
}

.text-9xl {
    @include text-9xl;
}

@mixin text-8xl {
    font-size: rem-font-size(67px);
    line-height: line-height(67px, 72px);
}

.text-8xl {
    @include text-8xl;
}

@mixin text-7xl {
    font-size: rem-font-size(59px);
    line-height: line-height(59px, 64px);
}

.text-7xl {
    @include text-7xl;
}

@mixin text-6xl {
    font-size: rem-font-size(51px);
    line-height: line-height(51px, 56px);
}

.text-6xl {
    @include text-6xl;
}

@mixin text-5xl {
    font-size: rem-font-size(44px);
    line-height: line-height(44px, 48px);
}

.text-5xl {
    @include text-5xl;
}

@mixin text-4xl {
    font-size: rem-font-size(38px);
    line-height: line-height(38px, 44px);
}

.text-4xl {
    @include text-4xl;
}

@mixin text-3xl {
    font-size: rem-font-size(33px);
    line-height: line-height(33px, 44px);
}

.text-3xl {
    @include text-3xl;
}

@mixin text-2xl {
    font-size: rem-font-size(28px);
    line-height: line-height(28px, 36px);
}

.text-2xl {
    @include text-2xl;
}

@mixin text-xl {
    font-size: rem-font-size(25px);
    line-height: line-height(25px, 32px);
}

.text-xl {
    @include text-xl;
}

@mixin text-lg {
    font-size: rem-font-size(21px);
    line-height: line-height(21px, 32px);
}

.text-lg {
    @include text-lg;
}

@mixin text-md {
    font-size: rem-font-size(18px);
    line-height: line-height(18px, 32px);
}

.text-md {
    @include text-md;
}

@mixin text-base {
    font-size: rem-font-size(16px);
    line-height: line-height(16px, 24px);
}

.text-base {
    @include text-base;
}

@mixin text-sm {
    font-size: rem-font-size(16px);
    line-height: line-height(16px, 28px);
}

.text-sm {
    @include text-sm;
}

@mixin text-xs {
    font-size: rem-font-size(14px);
    line-height: line-height(14px, 20px);
}

.text-xs {
    @include text-xs;
}

@mixin text-2xs {
    font-size: rem-font-size(12px);
    line-height: line-height(12px, 20px);
}

.text-2xs {
    @include text-2xs;
}
