@use "../../1-core" as *;

$hb-carousel-nav-button-size:   40px;
$hb-pagination-bullet-color:    rgba($white, 0.5);
// $hb-carousel-nav-bottom:        18%;

.hero-banner-carousel-block {
    overflow: hidden;

    --swiper-nav-bottom: 96px;

    .hero-banner-carousel-container {
        position: relative;
    }

    .swiper-hbc:not(.one-slide) {
        --swiper-navigation-size: #{$spacing-7};
        --swiper-navigation-icon-size: calc(#{$spacing-7} / 2);

        .swiper-hbc-button-prev,
        .swiper-hbc-button-next {
            position: absolute;
            display: flex;
            bottom: var(--swiper-nav-bottom);
            left: unset;
            width: var(--swiper-navigation-size);
            height: var(--swiper-navigation-size);
            background-color: $white;
            border: 2px solid $hb-pagination-bullet-color;
            border-radius: var(--swiper-navigation-size);
            z-index: 1;
            cursor: pointer;

            &.swiper-button-disabled {
                visibility: hidden;
            }

            &::after {
                content: '';
                font-family: inherit;
                position: absolute;
                inset: 0;
                background-color: $color-2-500;
                mask-position: center center;
                mask-repeat: no-repeat;
                mask-size: var(--swiper-navigation-icon-size) var(--swiper-navigation-icon-size);
            }

            &:hover {
                background-color: $color-2-500;
                border-color: $color-2-500;

                &::after {
                    background-color: $white;
                }
            }
        }

        .swiper-hbc-button-prev {
            right: $spacing-10 + $spacing-7 + $spacing-4;
        }

        .swiper-hbc-button-next {
            right: $spacing-10;
        }

        .swiper-hbc-button-prev::after {
            mask-image: url('/assets/icons/chevron-left.svg');
        }

        .swiper-hbc-button-next::after {
            mask-image: url('/assets/icons/chevron-right.svg');
        }
    }

    .swiper-hbc-pagination {
        padding: 0 $spacing-5;
        position: absolute;
        bottom: var(--swiper-nav-bottom);
        left: unset;
        margin-top: $spacing-6;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1;

        .swiper-pagination-bullet {
            --swiper-pagination-color: #{$white};
            --swiper-pagination-bullet-inactive-color: #{$hb-pagination-bullet-color};
            --swiper-pagination-bullet-inactive-opacity: 1;
        }

        .swiper-pagination-bullet-active {
            box-shadow: 0 0 0 3px $white;

            --swiper-pagination-color: transparent;
        }

        &.swiper-pagination-bullets.swiper-pagination-horizontal {
            bottom: var(--swiper-nav-bottom);
            left: unset;
        }
    }

    .hero-banner {
        .hrbn-button {
            --theme-button-primary: var(--theme-background);
            --theme-button-primary-border: var(--theme-background);
            --theme-button-primary-label: var(--theme-link-text);
            --theme-button-primary-hover: var(--theme-background-reversed);
            --theme-button-primary-border-hover: var(--theme-background-reversed);
            --theme-button-primary-label-hover: $black;
        }
    }

    &:not(.use-theme) {
        .hero-banner {
            .hrbn-button {
                --theme-button-primary-hover: #{$white};
                --theme-button-primary-border-hover: #{$white};
                --theme-button-primary-label-hover: var(--theme-background);
            }
        }
    }
}

@media (max-width: $screen-md-max) {
    .hero-banner-carousel-block {
        .swiper-hbc:not(.one-slide) {
            .swiper-hbc-button-prev,
            .swiper-hbc-button-next {
                display: none;
            }
        }
    }
}

@media (min-width: $screen-xl) {
    .hero-banner-carousel-block {
        --swiper-nav-bottom: 18%;
    }
}
