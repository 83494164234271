@use "../../1-core" as *;

.comparison-grid {
    border: 1px solid $grey-1-300;
    border-radius: $spacing-2;
    display: flex;
    flex-direction: column;
    background-color: $white;

    .compg-image-container {
        border-radius: $spacing-2 $spacing-2 0 0;

        figure picture {
            background-color: transparent;
        }

        img {
            clip-path: url(#clip-path-text-image-mobile);
        }
    }

    .compg-content-wrapper {
        padding: $spacing-6;
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .compg-heading {
        @include text-xl;
        @include text-bold;
        margin-bottom: $spacing-4;
    }

    .compg-content {
        @include rich-text-spacing;
        margin-bottom: $spacing-6;

        &,
        > p,
        > ul li,
        > ol li,
        > ul p,
        > ol p {
            @include text-sm;
        }
    }

    .compg-list-title {
        @include text-normal;
        color: $grey-1-900;
        margin-bottom: $spacing-5;
    }

    .compg-list {
        flex: 1;
    }

    .compg-cta-button {
        align-self: flex-start;
        display: inline-flex;
        margin-top: $spacing-5;
        min-height: var(--btn-height);
        height: auto;
    }
}

@media (min-width: $screen-sm) {
    .comparison-grid {
        .compg-content {
            height: 280px;
        }
    }
}

@media (min-width: $screen-md) {
    .comparison-grid {
        .compg-content {
            height: 240px;
        }
    }
}

@media (min-width: $screen-lg) {
    .comparison-grid {
        .compg-content-wrapper {
            padding: $spacing-8;
        }

        .compg-heading {
            @include text-2xl;
        }

        .compg-content {
            height: 290px;
        }

        .compg-cta-button {
            margin-top: $spacing-6;
        }
    }
}

@media (min-width: $screen-xl) {
    .comparison-grid {
        .compg-content {
            height: 220px;
        }
    }
}
