@use '../../1-core' as *;
@use '../../1-core/typography/list-styles' as *;

@forward './widgets/custom-stats';

.blog-listing {
    padding-top: $spacing-11;
    padding-bottom: $spacing-12;

    .blog-listing-results {
        .ais-Hits {
            margin-bottom: $spacing-5;

            .ais-Hits-list {
                @include list-reset;

                .ais-Hits-item {
                    display: flex;

                    .article-card {
                        width: 100%;
                    }
                }
            }
        }

        .ais-Pagination {
            display: flex;
            justify-content: center;
        }
    }

    .blog-listing-filters {
        margin-bottom: $spacing-7;

        &.hide-filters {
            display: none;
        }
    }
}

@media (max-width: $screen-md-max) {
    .blog-listing {
        .blog-listing-results {
            .ais-Hits {
                .ais-Hits-list {
                    @include one-col-grid;
                }
            }
        }
    }
}

@media (min-width: $screen-md) {
    .blog-listing {
        .blog-listing-results {
            .ais-Hits {
                .ais-Hits-list {
                    @include two-col-grid;
                }
            }
        }
    }
}

@media (min-width: $screen-xl) {
    .blog-listing {
        .blog-listing-results {
            .ais-Hits {
                .ais-Hits-list {
                    @include three-col-grid;
                }
            }
        }
    }
}

