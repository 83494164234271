@use "../../1-core" as *;

.hero-banner-block {
    &:not(.primary-hero-banner-block).use-theme {
        background-color: var(--theme-background);
    }

    &.primary-hero-banner-block {
        .hero-banner {
            .hrbn-button {
                --theme-button-primary: var(--theme-background);
                --theme-button-primary-border: var(--theme-background);
                --theme-button-primary-label: var(--theme-link-text);
                --theme-button-primary-hover: var(--theme-background-reversed);
                --theme-button-primary-border-hover: var(--theme-background-reversed);
                --theme-button-primary-label-hover: $black;
            }
        }
    }

    &:not(.use-theme) {
        .hero-banner {
            .hrbn-button {
                --theme-button-primary-hover: #{$white};
                --theme-button-primary-border-hover: #{$white};
                --theme-button-primary-label-hover: var(--theme-background);
            }
        }
    }
}
