@use "../../1-core/" as *;

$social-share-color:        $black;
$social-share-hit-area:     $spacing-5;

.social-share {
    display: flex;
    align-items: center;
    position: relative;

    .social-share-intro {
        @include text-sm;
        line-height: 1;
        color: $social-share-color;
    }

    a,
    button {
        margin-left: $spacing-4;
    }

    a,
    button {
        height: $social-share-hit-area;
        min-width: $social-share-hit-area;
        color: $social-share-color;
        display: flex;
        justify-content: center;
        align-items: center;
        @include transition(color);
        border-radius: $spacing-1;

        &:hover,
        &:focus-visible {
            @include transition(color, 200ms);
            color: $color-1-500;
        }

        &:focus-visible {
            outline: 2px solid $color-1-500;
        }

        &:active {
            @include transition(color, 200ms);
            color: $color-2-500;
        }
    }

    a {
        text-decoration: none;
    }

    button {
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
    }

    .copy-page-url-btn {
        position: relative;
    }

    .copied-link-text {
        @include text-sm;
        position: absolute;
        top: -#{$spacing-2};
        left: -#{$spacing-12 + $spacing-2};
        background-color: $color-1-100;
        width: $spacing-12;
        padding: $spacing-1 $spacing-2;
        border-radius: $spacing-1;
        color: $black;
        opacity: 0;
        visibility: hidden;
        transition: opacity ease-in-out 0.3s, visibility 0s 0.3s;
        cursor: default;

        &::after {
            content: " ";
            position: absolute;
            top: 50%;
            left: 100%;
            margin-top: -#{$spacing-1};
            border-width: #{$spacing-1};
            border-style: solid;
            border-color: transparent transparent transparent $color-1-100;
        }

        &.visible {
            opacity: 1;
            visibility: visible;
            transition: opacity ease-in-out 0.3s, visibility 0s;
        }
    }
}
