@use "../../1-core" as *;

.cta-prominent-block {
    @include global-section-vertical-spacing;
}

@media (max-width: $screen-md-max) {
    .cta-prominent-block {
        .container {
            padding: 0;
        }
    }
}
