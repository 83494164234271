@use "../../1-core" as *;
@use "../../1-core/typography/list-styles" as *;

.property-for-sale-contact {
    border: 1px solid $grey-1-300;
    border-radius: $spacing-2;
    width: 100%;
    background-color: $white;

    .contact-container {
        display: flex;
        flex-direction: column;
        gap: $spacing-5;
        padding: $spacing-6;
        align-items: center;

        .contact-title {
            text-align: center;
            @include text-sm;
        }

        .contact-person {
            text-align: center;
            display: flex;
            gap: $spacing-4;
            align-items: center;

            .contact-avatar {
                @include light-shadow(md);
                width: $spacing-9;
                border-radius: 50%;
                border: 3px solid $white;
            }

            .contact-info {
                .contact-name {
                    @include text-md;
                    @include text-bold;
                }

                .contact-role {
                    @include text-2xs;
                    text-transform: uppercase;
                }
            }
        }

        .contact-btns {
            display: flex;
            gap: $spacing-4;
            flex-direction: column;
            align-items: center;
        }
    }

    &.fixed {
        position: fixed;
        bottom: 0;
        left: 0;
        display: none;
        z-index: 99;
        border-radius: 0;

        .contact-container {
            justify-content: space-between;
            align-items: center;
            padding: $spacing-5;
            gap: $spacing-4;

            .contact-avatar,
            .contact-text {
                display: none;
            }

            .contact-btns {
                flex-direction: row;

                .btn {
                    width: auto;
                }
            }
        }
    }

    .contact-links {
        display: flex;
        gap: $spacing-4;
        padding: $spacing-5;
        border-top: 1px solid $grey-1-100;
        justify-content: center;

        .property-feature-item {
            margin-bottom: $spacing-2;

            a {
                text-decoration: none;
            }

            .svg-icon {
                color: $color-1-500;
            }
        }
    }
}

@media (min-width: $screen-sm) {
    .property-for-sale-contact {
        .contact-container {
            .contact-btns {
                display: flex;
                gap: $spacing-4;
                flex-direction: column;
                align-items: center;

                .btn {
                    width: 350px;
                }
            }
        }
    }
}

@media (min-width: $screen-lg) {
    .property-for-sale-contact {
        &:not(.fixed) {
            width: fit-content;
        }

        &.fixed {
            .contact-container {
                flex-direction: row;

                .contact-title {
                    text-align: left;
                }

                .contact-avatar,
                .contact-text {
                    display: block;
                }

                .contact-btns {
                    flex-direction: row;
                }
            }
        }
    }
}
