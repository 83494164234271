@use '../../1-core/typography/list-styles' as *;
@use '../../1-core/typography/link-styles' as *;
@use '../../1-core' as *;

.ais-Pagination {
    .ais-Pagination-list {
        @include list-reset;
        display: flex;

        li:not(:last-of-type) {
            margin-right: $spacing-1;
        }

        //separate items a little bit.
        li.ais-Pagination-item--previousPage {
            margin-right: $spacing-5;
        }

        li.ais-Pagination-item--nextPage {
            margin-left: $spacing-5;
        }

        a,
        span {
            width: $spacing-7;
            height: $spacing-7;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        a {
            text-decoration: none;
            color: $black;
            @include text-sm;
            @include text-bold;
            border-radius: 50%;
        }

        a:not(.ais-Pagination-link--selected) {
            @include transition(background-color color);

            &:hover {
                background-color: $color-1-500;
                color: $white;
                @include transition(background-color color, 200ms);
            }
        }

        a.ais-Pagination-link--selected {
            background-color: $color-1-50;
            color: $color-2-500;
        }

        .ais-Pagination-item--disabled {
            color: $grey-1-300;
        }
    }
}
