@use "../../1-core" as *;

.quote-block {
    // margin: $spacing-8 0 $spacing-8;
    @include global-section-vertical-spacing;

    .quote {
        background-color: $grey-1-50;
        border-radius: $spacing-2;
        display: flex;
        flex-direction: column-reverse;

        .quote-wrapper {
            @include grid();
            padding: 0 $spacing-6;
            position: relative;
        }

        .quote-copy-wrapper {
            @include col(4);
            margin-top: -$spacing-9;
            margin-bottom: $spacing-9;
        }

        .quote-icon svg {
            width: $spacing-9;
            height: $spacing-9;
            color: $color-1-500;
        }

        .quote-text {
            @include text-2xl;
            margin-top: $spacing-8;
            margin-bottom: $spacing-5;
            position: relative;
        }

        .quote-person {
            @include text-sm;
            @include text-bold;
        }

        .quote-image {
            figure picture {
                background-color: transparent;
            }

            img {
                border-top-left-radius: $spacing-2;
                border-top-right-radius: $spacing-2;
                clip-path: url('#clip-path-quote-mobile');
            }
        }

        &.no-image {
            .quote-copy-wrapper {
                margin-top: $spacing-8;
            }
        }
    }
}

@media (min-width: $screen-sm) {
    .quote-block {
        // margin: $spacing-9 0 $spacing-9;

        .quote {
            .quote-copy-wrapper {
                @include col(6, 1);
                margin-top: -$spacing-11;
            }

            .quote-text {
                @include text-3xl;
            }
        }
    }
}

@media (min-width: $screen-lg) {
    .quote-block {
        // margin: $spacing-10 0 $spacing-10;

        .quote {
            @include grid();

            .quote-wrapper {
                @include col(6, 1);
                display: flex;
                flex-direction: column;
                justify-content: center;
                padding: 0;
            }

            .quote-copy-wrapper {
                margin: 0;
            }

            .quote-text {
                margin-top: $spacing-6;
                margin-bottom: $spacing-4;
            }

            .quote-image {
                @include col(5);

                img {
                    border-top-left-radius: 0;
                    border-top-right-radius: $spacing-2;
                    border-bottom-right-radius: $spacing-2;
                    clip-path: url('#clip-path-quote-desktop');
                }
            }

            &.no-image {
                .quote-wrapper {
                    @include col(10, 1);
                }

                .quote-copy-wrapper {
                    margin: $spacing-8 0;
                }
            }
        }
    }
}

@media (min-width: $screen-xl) {
    .quote-block {
        .quote {
            .quote-text {
                margin-top: $spacing-8;
                margin-bottom: $spacing-5;
            }
        }
    }
}
