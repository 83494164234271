@use "../../1-core" as *;
@use "../../1-core/typography/list-styles" as *;
@use "../../1-core/typography/link-styles" as *;

$location-card-rl-action-button-side-padding:      20px;

.location-card.location-card-rl {
    .location-content-rl {
        .location-rl-ribbon {
            @include text-2xs;
            @include text-black;
            background-color: $color-gold-500;
            color: $black;
            padding: $spacing-2 $spacing-4;
            border-radius: $spacing-2;
            margin-bottom: $spacing-5;
        }

        .location-content-chip-container {
            margin-bottom: $spacing-4;

            .chip-component {
                margin-bottom: $spacing-2;
                margin-right: $spacing-2;
            }
        }

        .location-content-action-container {
            display: flex;
            margin-bottom: $spacing-5;

            .action-button {
                --btn-side-padding: #{$location-card-rl-action-button-side-padding};
                height: unset;
                min-height: var(--btn-height);
            }

            .action-button:not(:last-of-type) {
                margin-right: $spacing-4;
            }
        }

        .location-info {
            @include list-reset;
            display: grid;
            grid-template-columns: 1fr;
        }

        ul.location-info > li.location-info-item {
            margin-bottom: $spacing-2;
        }

        .location-info-item {
            @include text-sm;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;

            .svg-icon-wrapper {
                margin-right: $spacing-4;
            }

            a {
                @include text-link;
            }
        }
    }
}

@media (min-width: $screen-sm) {
    .location-card.location-card-rl {
        .location-content-rl {
            .location-content-action-container {
                .action-button {
                    --btn-side-padding: #{$spacing-5};
                }
            }

            // .location-info {
            //     grid-template-columns: repeat(2, 1fr);
            // }

            .location-info-item {
                flex-wrap: wrap;

                // &:first-of-type {
                //     @include col(2);
                // }
            }
        }
    }
}
