@use "../../1-core" as *;

$blog-header-min-height-desktop:    658px;

.blog-header-block {
    position: relative;
    background-color: $color-1-500;
    // @include grid();

    .container {
        @include grid();
        align-items: stretch;
    }

    .blhb-image-container {
        display: none;
        // position: absolute;
    }

    .blhb-content {
        @include col(4);
        margin: $spacing-6 $spacing-5;
        display: flex;
        flex-direction: column;
    }

    .blhb-date {
        @include text-xs;
        margin-bottom: $spacing-4;
    }

    .blhb-title {
        margin-bottom: $spacing-7;

        h1 {
            @include text-5xl;
            @include text-black;
        }
    }

    .blhb-chips-container {
        display: flex;
        align-items: center;
        margin-top: $spacing-5;

        .chip-component {
            text-transform: uppercase;
        }

        .chip-component:not(:first-child) {
            margin-left: $spacing-4;
        }
    }

    .blhb-desc {
        @include text-xl;
        flex: 1;
        margin-bottom: $spacing-7;
    }

    .social-share {
        a,
        button {
            &:hover,
            &:focus-visible {
                color: $white;
            }


            &:focus-visible {
                outline: 2px solid $white;
            }

            &:active {
                color: $color-2-500;
            }
        }
    }
}

@media (min-width: $screen-sm) {
    .blog-header-block {
        .blhb-content {
            @include col(8);
            margin: $spacing-10 $spacing-8;
        }

        .blhb-title {
            margin-bottom: $spacing-7;

            h1 {
                @include text-7xl;
            }
        }
    }
}

@media (min-width: $screen-lg) {
    .blog-header-block {
        .container {
            min-height: $blog-header-min-height-desktop;
        }

        .blhb-image-container {
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: calc(50vw - #{$spacing-3} - (((#{$screen-xl} - (11 * #{$spacing-5})) / 12 * 2) + (#{$spacing-5} * 1)));

            figure,
            picture {
                display: flex;
                width: 100%;
                background-color: transparent;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center right;
                clip-path: url(#clip-path-blog-header-desktop);
            }
        }

        .blhb-content {
            @include col(6, 1);
            margin: $spacing-10 0;
        }
    }
}
