@use "../../1-core" as *;

.cta-tertiary {
    @include grid;
    // padding: $spacing-5 $spacing-4;

    .ctat-content-wrapper {
        @include col(4);
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .ctat-copy {
        flex: 1;
        display: flex;
        justify-content: center;
    }

    .ctat-subheading {
        @include text-sm;
        display: inline-block;
        text-align: center;
        margin-bottom: $spacing-5;

        a {
            @include text-link;
        }
    }

    .ctat-action {
        margin-left: $spacing-5;
    }
}

@media (min-width: $screen-sm) {
    .cta-tertiary {
        // padding: $spacing-6 0;

        .ctat-content-wrapper {
            @include col(6, 1);
        }
    }
}

@media (min-width: $screen-lg) {
    .cta-tertiary {
        // padding: $spacing-8 0;

        .ctat-content-wrapper {
            @include col(10, 1);
            flex-direction: row;
        }

        .ctat-subheading {
            text-align: left;
            margin-bottom: 0;
        }
    }
}
