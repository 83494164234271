@use "../../1-core" as *;

.property-feature-block {
    background-color: white;
    padding: $spacing-10 0;

    .container {
        .pfb-title {
            @include text-4xl;
            @include text-black;
            margin-bottom: $spacing-7;
        }

        .pfb-container {
            display: flex;
            flex-direction: column;
            gap: $spacing-2;
        }
    }
}

@media (min-width: $screen-md) {
    .property-feature-block {
        .container {
            .pfb-container {
                display: grid;
                grid-template-columns: 1fr 1fr 1fr;
                column-gap: $spacing-6;
                row-gap: $spacing-2;
            }
        }
    }
}

@media (min-width: $screen-lg) {
    .property-feature-block {
        padding: $spacing-12 0;

        .container {
            .pfb-title {
                @include text-5xl;
            }
        }
    }
}
