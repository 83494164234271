@use "spacing-scale" as *;

// Icons
//----------------------------------------
$icon-lg:  $spacing-6;
$icon-md:  $spacing-5;
$icon-sm:  20px;
$icon-xs:  $spacing-4;

@mixin icon-lg {
    width: $icon-lg;
    height: $icon-lg;
}

// .icon-lg {
//     @include icon-lg;
// }

@mixin icon-md {
    width: $icon-md;
    height: $icon-md;
}

// .icon-md {
//     @include icon-md;
// }

@mixin icon-sm {
    width: $icon-sm;
    height: $icon-sm;
}

// .icon-sm {
//     @include icon-sm;
// }

// Base class
// .svg-icon {
//     @include icon-md;
// }

.svg-icon-wrapper {
    display: inline-flex;

    .svg-icon {
        @include icon-md;
    }

    &.icon-sm .svg-icon {
        @include icon-sm;
    }

    &.icon-lg .svg-icon {
        @include icon-lg;
    }
}
