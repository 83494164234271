@use '../../../1-core' as *;

@mixin chip {
    border: 1px solid rgba($black, 0.3);
    padding: $spacing-2 $spacing-4;
    display: flex;
    justify-content: center;
    align-items: center;
    @include text-2xs;
    border-radius: $spacing-5;
}

.search-result {
    margin-bottom: $spacing-5;
    border-radius: $spacing-2;
    border: 1px solid $grey-1-300;
    padding: $spacing-6;
    display: flex;

    h4 {
        margin-bottom: $spacing-2;
        @include text-lg;
        @include text-bold;
    }

    p {
        @include text-sm;
        margin-bottom: $spacing-3;
    }

    .result-type {
        @include chip;
        flex-shrink: 0;

        .svg-icon {
            margin-right: $spacing-2;
        }
    }
}

@media (max-width: $screen-sm-max) {
    .search-result {
        flex-direction: column;
        align-items: flex-start;

        .result-type {
            order: 1;
            margin-bottom: $spacing-4;
        }

        .result-content {
            order: 2;
        }
    }
}

@media (min-width: $screen-md) {
    .search-result {
        justify-content: space-between;
        align-items: flex-start;

        .result-type {
            margin-left: $spacing-5;
        }
    }
}
