@use "../../1-core" as *;

.property-for-sale-widget {
    .pfsw-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: $spacing-6;
        flex-direction: column;

        .pfsw-copy {
            text-align: center;

            .title {
                @include text-4xl;
                @include text-black;
                margin-bottom: $spacing-4;
            }

            .subtitle {
                @include text-md;
            }
        }

        .btn {
            width: fit-content;
        }
    }

    .pfsw-items {
        margin: $spacing-9 0 $spacing-10 0;
        display: flex;
        flex-direction: column;
        gap: $spacing-5;
    }

    .property-sale-card {
        height: auto;
    }
}

@media (min-width: $screen-lg) {
    .property-for-sale-widget {
        .pfsw-header {
            flex-direction: row;

            .pfsw-copy {
                text-align: left;

                .title {
                    @include text-5xl;
                }
            }
        }

        .pfsw-items {
            flex-direction: row;
            margin: $spacing-10 0;
            gap: $spacing-5;
        }
    }
}
