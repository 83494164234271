@use "../../1-core" as *;

.cta-block {
    @include global-section-vertical-spacing($spacing-10, $spacing-12, $spacing-12);

    &.cta-block-style-secondary {
        @include global-section-vertical-spacing($spacing-7, $spacing-8, $spacing-9);
    }

    &.cta-block-style-tertiary {
        @include global-section-vertical-spacing($spacing-5, $spacing-6, $spacing-8);
    }
}
