@mixin text-thin {
    font-weight: 100;
}

.text-thin {
    @include text-thin;
}

@mixin text-extralight {
    font-weight: 200; // AKA Ultra Light
}

.text-extralight {
    @include text-extralight;
}

@mixin text-light {
    font-weight: 300;
}

.text-light {
    @include text-light;
}

@mixin text-normal {
    font-weight: 400;
}

.text-normal {
    @include text-normal;
}

@mixin text-medium {
    font-weight: 500;
}

.text-medium {
    @include text-medium;
}

@mixin text-semibold {
    font-weight: 600;
}

.text-semibold {
    @include text-semibold;
}

@mixin text-bold {
    font-weight: 700;
}

.text-bold {
    @include text-bold;
}

@mixin text-extrabold {
    font-weight: 800;
}

.text-extrabold {
    @include text-extrabold;
}

@mixin text-black {
    font-weight: 900; // AKA know as Heavy
}

.text-black {
    @include text-black;
}
