@use "../../1-core" as *;

.horizontal-image-card {
    border-radius: $spacing-2;
    border: 1px solid $grey-1-300;
    margin-bottom: $spacing-7;
    background-color: $white;

    &:last-of-type {
        margin-bottom: 0;
    }

    .hrimc-image-container {
        border-radius: $spacing-2 $spacing-2 0 0;

        --aspect-ratio: 16/10;
        --image-alignment: center center;

        figure picture {
            height: 0;
            width: 100%;
            padding-bottom: calc(100% / (var(--aspect-ratio)));
            position: relative;
            background-color: transparent;
        }

        img {
            width: 100%;
            clip-path: url(#clip-path-horizontal-image-mobile);
        }
    }

    .hrimc-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: $spacing-6;
    }

    .hrimc-heading {
        @include text-xl;
        @include text-bold;
        margin-bottom: $spacing-4;
    }

    .hrimc-desc {
        @include rich-text-spacing;
        margin-bottom: $spacing-6;

        &,
        > p,
        > ul li,
        > ol li,
        > ul p,
        > ol p {
            @include text-sm;
        }
    }
}

@media (min-width: $screen-sm) {
    .horizontal-image-card {
        .hrimc-content {
            @include col(4, 7);
            padding: $spacing-9;
        }

        .hrimc-heading {
            @include text-2xl;
        }
    }
}

@media (min-width: $screen-lg) {
    .horizontal-image-card {
        @include grid();
        aspect-ratio: unset;

        .hrimc-image-container {
            @include col(6);
            border-radius: $spacing-2 0 0 $spacing-2;

            --aspect-ratio: initial;

            figure picture {
                height: 100%;
                padding-bottom: 0;
            }

            img {
                min-height: 502px;
                clip-path: url(#clip-path-horizontal-image-desktop-curve-right);
            }
        }

        .hrimc-content {
            @include col(4, 7);
            padding: 0;
        }

        &.reverse-layout {
            flex-direction: row-reverse;

            .hrimc-content {
                @include col(4, 1);
                grid-row: 1;
            }

            .hrimc-image-container {
                @include col(6, 6);
                border-radius: 0 $spacing-2 $spacing-2 0;

                img {
                    clip-path: url(#clip-path-horizontal-image-desktop-curve-left);
                }
            }
        }
    }
}
