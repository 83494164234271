@use "../../1-core" as *;

$element-disabled-color:              $grey-1-100;
$element-disabled-bg:                 $grey-1-50;
$element-disabled-validation-color:   $grey-1-100;

.form-element {
    &.is-disabled {
        input,
        textarea,
        select {
            &:disabled {
                color: $element-disabled-color;
                background-color: $element-disabled-bg;
            }
        }

        .svg-icon {
            color: $element-disabled-color;
        }

        .validation-message {
            color: $element-disabled-validation-color;
        }

        .validation-message-icon {
            background: $element-disabled-validation-color;
        }
    }
}
