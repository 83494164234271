@use "../../1-core" as *;

.property-for-sale-detail-block {
    background-color: white;
    padding: $spacing-10 0;
    position: relative;
    border-bottom: 1px solid $grey-1-300;

    .container {
        display: flex;
        flex-direction: column;
        gap: $spacing-9;
    }
}

@media (min-width: $screen-lg) {
    .property-for-sale-detail-block {
        .container {
            display: grid;
            grid-template-columns: 2fr 1fr;
            align-items: start;
        }
    }
}
