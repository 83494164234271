@use "../../1-core" as *;
@use "../../3-components/Buttons/buttons" as *;
@use "../../1-core/typography/list-styles" as *;

@mixin interactive-text-list {
    ul {
        @include list-reset;

        > li {
            padding-left: $list-icon-width + $list-icon-text-spacing;
            position: relative;
            margin-bottom: $list-item-margin;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                display: block;
                top: 0;
                width: $list-icon-width;
                height: $list-icon-width;
                // background: url('/assets/icons/round-solid.svg');
                // background-size: $list-icon-width $list-icon-width;
                // background-position: center center;
                background: var(--theme-text-prominent);
                mask-image: url('/assets/icons/round-solid.svg');
            }
        }
    }

    ol {
        @include list-reset;
        counter-reset: section;

        > li {
            padding-left: $list-icon-width + $list-icon-text-spacing;
            position: relative;
            margin-bottom: $list-item-margin;

            &::before {
                position: absolute;
                left: 0;
                counter-increment: section;
                content: counter(section) ".";
                width: $list-icon-width;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    ul,
    ol {
        ul li::before {
            background: url('/assets/icons/round-outline.svg');
        }

        ol > li::before {
            content: counter(section, lower-alpha) ".";
        }

        li {
            > ul,
            > ol {
                margin-top: $sub-list-top-margin;
            }
        }
    }

    @media (max-width: $screen-xs-max) {
        ul {
            > li {
                &::before {
                    background: $black;
                }
            }
        }
    }
}

.interactive-text-block {
    @include global-section-vertical-spacing;

    .copy-container {
        @include grid();
    }

    .grid-copy {
        @include col(4);
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-bottom: $spacing-6;
    }

    .grid-copy-title {
        @include text-4xl;
        @include text-black;
        margin-bottom: $spacing-4;
    }

    .grid-copy-desc {
        @include text-md;
    }

    .intb-mobile {
        .accordion {
            background-color: $white;
            padding: 0;
            margin: 0;

            .container {
                padding: 0;
            }

            .accordion-item .accordion-heading {
                button.accordion-title {
                    background-color: var(--theme-background);
                    padding: $spacing-4 $spacing-6;
                    min-height: $spacing-9;
                }
            }

            .accordion-item.is-open {
                border: 2px solid var(--theme-background);

                .accordion-heading {
                    button.accordion-title {
                        background-color: var(--theme-background-reversed);
                        border-radius: $spacing-6;
                    }
                }
            }

            .tile {
                margin-bottom: $spacing-4;

                .tile-title {
                    @include text-sm;
                    @include text-black;
                    margin-bottom: $spacing-2;
                }

                .tile-desc {
                    @include text-sm;
                    @include interactive-text-list;

                    > p,
                    ul li,
                    ol li {
                        @include text-sm;
                    }
                }
            }
        }
    }
}

@media (max-width: $screen-xs-max) {
    .interactive-text-block {
        .intb-desktop {
            display: none;
        }
    }
}

@media (min-width: $screen-sm) {
    .interactive-text-block {
        .grid-copy {
            @include col(6, 1);
            margin-bottom: $spacing-8;
        }

        .intb-mobile {
            display: none;
        }

        .intb-desktop {
            background: linear-gradient(90deg, #{$white} 0%, #{$white} 60%, var(--theme-background) 60%, var(--theme-background) 100%);
        }

        .intb-desktop-tab {
            @include grid();
        }

        .intb-desktop-button-container {
            @include col(3);
        }

        .intb-desktop-tab-item-container {
            @include col(5);
            background-color: var(--theme-background);
        }

        .intb-desktop-button-container {
            display: flex;
            flex-direction: column;
            align-items: stretch;
        }

        .intb-desktop-tab-item-container {
            padding: $spacing-9 $spacing-5 $spacing-9 $spacing-9;
        }

        .intx-item-btn {
            @include button-reset;
            @include text-lg;
            @include text-bold;
            display: inline-flex;
            align-items: center;
            min-height: $spacing-10;
            color: $grey-1-700;
            border-bottom: 1px solid $grey-1-100;

            &:first-of-type {
                border-top: 1px solid $grey-1-100;
            }

            &.is-active {
                color: $black;
            }
        }

        .intx-item-btn-icon {
            margin-right: $spacing-2;
        }

        .interactive-text-item {
            display: none;

            &.is-active {
                display: block;
            }
        }

        .intx-item-content {
            color: var(--theme-text-prominent);
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            column-gap: $spacing-9;
            row-gap: $spacing-8;

            .intx-item-content-head {
                @include col(2);
                @include text-xl;
                @include text-bold;
            }

            .tile {
                @include col(2);

                .tile-title {
                    @include text-md;
                    @include text-black;
                    margin-bottom: $spacing-2;
                }

                .tile-desc {
                    @include text-sm;
                    @include interactive-text-list;

                    > p,
                    ul li,
                    ol li {
                        @include text-sm;
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-lg) {
    .interactive-text-block {
        .grid-copy {
            @include col(8, 2);
            margin-bottom: $spacing-9;
        }

        .grid-copy-title {
            @include text-5xl;
        }

        .intb-desktop-button-container {
            @include col(4);
        }

        .intb-desktop-tab-item-container {
            @include col(8);
            padding: $spacing-11 $spacing-4 $spacing-11 $spacing-11;
        }

        .intx-item-content {
            .tile {
                @include col(1);

                &.single-tile-layout {
                    @include col(2);
                }
            }
        }
    }
}
