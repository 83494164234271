@use "../../1-core" as *;

$chip-padding:          6px $spacing-4;
$chip-icon-size:        $spacing-4;

.chip-component {
    padding: $chip-padding;
    border-radius: calc(#{$spacing-7} / 2);
    border: 1px solid rgba($color: $black, $alpha: 0.3);
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    color: $black;
    // text-transform: uppercase;

    .chip-icon {
        margin-right: $spacing-2;

        .svg-icon {
            width: $chip-icon-size;
            height: $chip-icon-size;
        }
    }

    .chip-title {
        @include text-2xs;
        @include text-normal;
    }

    &.chip-link {
        text-decoration: none;

        &:hover {
            border-color: $black;
        }
    }
}
