@use "../../1-core" as *;
@use "../../1-core/typography/link-styles" as *;

$timeline-dot-size:                         4px;
$timeline-dot-line:                         $timeline-dot-size dotted $color-2-500;
$timeline-line-space:                       52px;
$timeline-row-min-hieght:                   348px;
$timeline-item-width-modifier-mobile:       56px;
$timeline-item-width-modifier-tablet:       64px;

.timeline-item-row {
    position: relative;
    pointer-events: none;

    &:not(:first-of-type) {
        margin-top: $spacing-5;
    }

    .timeline-item {
        width: calc(100% - #{$timeline-item-width-modifier-mobile});
        display: flex;
        border-radius: $spacing-2;
        border: 1px solid $grey-1-300;
        position: relative;
        pointer-events: auto;
        margin-left: auto;
        background-color: $white;

        .tlitm-dot-icon {
            position: absolute;
            top: 50%;
            z-index: 5;
            color: $color-2-500;
            left: 0;
            transform: translate(-12px, -10px);
        }

        &::before {
            background-color: white;
            content: '';
            display: block;
            width: $spacing-4;
            height: $spacing-4;
            position: absolute;
            top: 50%;
            border-radius: 50%;
            z-index: 4;
            left: 0;
            transform: translate(-8px, -6px);
        }

        &::after {
            content: '';
            display: block;
            width: calc(#{$timeline-item-width-modifier-mobile} - 32px);
            height: 1px;
            position: absolute;
            top: 50%;
            border-top: $timeline-dot-line;
            left: 0;
            transform: translate(-36px, 0);
        }
    }

    .tlitm-image-container {
        display: none;
    }

    .tlitm-content {
        flex: 1;
        padding: $spacing-6;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
    }

    .tlitm-heading {
        @include text-xl;
        @include text-bold;
        margin-bottom: $spacing-4;
    }

    .tlitm-desc {
        @include text-sm;
        margin-bottom: $spacing-6;

        a {
            @include text-link;
        }
    }

    .tlitm-cta-button {
        margin-top: auto;
    }
}

@media (min-width: $screen-sm) {
    .timeline-item-row {
        .timeline-item {
            width: calc(100% - #{$timeline-item-width-modifier-tablet});
            min-height: $timeline-row-min-hieght;

            &::after {
                width: calc(#{$timeline-item-width-modifier-tablet} - 32px);
                transform: translate(-44px, 0);
            }
        }

        .tlitm-image-container {
            max-width: 260px;
            width: 44%;
            clip-path: url(#clip-path-timeline);
            border-top-right-radius: $spacing-2;
            border-bottom-right-radius: $spacing-2;
            display: block;

            picture,
            img {
                height: 100%;
            }

            img {
                width: 100%;
            }
        }
    }
}

@media (min-width: $screen-xl) {
    .timeline-item-row {
        .timeline-item {
            width: calc(50% - #{$timeline-line-space});
            margin-left: 0;

            &::after {
                width: calc(#{$timeline-line-space} - #{$timeline-dot-size});
            }
        }

        &:nth-child(odd) {
            .timeline-item {
                .tlitm-dot-icon {
                    left: unset;
                    right: 0;
                    transform: translate(12px, -10px);
                }

                &::before {
                    left: unset;
                    right: 0;
                    transform: translate(8px, -6px);
                }

                &::after {
                    left: unset;
                    right: 0;
                    transform: translate($timeline-line-space - ($timeline-dot-size * 1.5), 0);
                }
            }
        }

        &:nth-child(even) {
            .timeline-item {
                margin-left: auto;

                .tlitm-dot-icon {
                    left: 0;
                    transform: translate(-12px, -10px);
                }

                &::before {
                    left: 0;
                    transform: translate(-8px, -6px);
                }

                &::after {
                    left: 0;
                    transform: translate(calc(-1 * (#{$timeline-line-space - $timeline-dot-size})), 0);
                }
            }
        }
    }
}
