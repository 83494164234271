@use "../../1-core" as *;

$footer-logo-width:                         187px;
$footer-logo-height:                        99px;
$footer-desktop-acknowledgement-max-width:  187px;
$child-safe-logo-width:                     276px;
$child-safe-logo-height:                    53px;
$acnc-logo-size:                            $spacing-12;

.footer {
    background-color: $color-navy-500;
    color: white;

    .container {
        padding-top: $spacing-7;
        padding-bottom: $spacing-7;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: $spacing-8;
        grid-template-areas:
            "logo  logo"
            "copy  copy"
            "copy  copy"
            "rec   rec"
            "blogo blogo"
            "end   end";
    }

    .footer-logo-container {
        grid-area: logo;
        margin-bottom: $spacing-4;

        & > a {
            display: inline-block;
        }

        picture > img {
            width: $footer-logo-width;
            height: $footer-logo-height;
        }
    }

    .footer-acknowlegement-container {
        grid-area: rec;
        display: flex;
        flex-direction: column;
        margin-bottom: $spacing-8;

        .acknowledgement-image-container {
            width: $spacing-10;
            margin-bottom: $spacing-4;

            img {
                width: $spacing-10;
                height: $spacing-10;
            }
        }

        .acknowledgement-text {
            @include text-xs;
            flex: 1;
        }
    }

    .footer-bottom-logo-row {
        grid-area: blogo;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .child-safe-logo-container {
        margin-bottom: $spacing-4;

        img {
            max-width: $child-safe-logo-width;
            width: 100%;
            height: 100%;
        }
    }

    .acnc-logo-container {
        display: flex;
        align-items: center;
        // margin-bottom: $spacing-6;

        img {
            max-width: $acnc-logo-size;
            width: 100%;
            height: 100%;
            opacity: 0.8;
        }
    }

    .footer-copy {
        grid-area: copy;
        position: relative;
        margin-bottom: $spacing-8;
    }

    .footer-row {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        margin-bottom: $spacing-10;
        column-gap: $spacing-8;
        row-gap: $spacing-7;
    }

    .footer-info {
        .footer-text-heading {
            @include text-lg;
            @include text-black;
            margin-bottom: $spacing-4;
        }

        .footer-text-desc,
        .footer-text-desc > p {
            @include text-sm;
        }

        .footer-text-desc a {
            @include footer-link;
        }
    }

    .footer-link {
        @include footer-link;
    }

    .footer-endrow {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: $spacing-5;
        grid-area: end;

        .footer-endrow-hr-container {
            width: 100%;

            hr {
                border: none;
                border-bottom: 1px solid $color-dustyblue-500;
            }
        }

        .footer-endrow-copy {
            display: flex;
            flex-wrap: wrap;
            @include text-xs;

            .footer-endrow-copy-separator {
                margin: 0 $spacing-4;
                border-left: 1px solid $white;
            }
        }

        .footer-endrow-social-media {
            display: flex;

            .footer-endrow-social-button {
                padding: 0;
                color: $white;
                border: none;

                .svg-icon {
                    width: $spacing-5;
                    height: $spacing-5;
                }

                &:not(:first-of-type) {
                    margin-left: $spacing-4;
                }

                &:hover {
                    color: $color-1-500;
                }

                &:active {
                    color: $color-dustyblue-500;
                }

                &:focus-visible {
                    outline: 2px solid $white;
                    outline-offset: 2px;
                }
            }
        }
    }

    .footer-back-to-top {
        position: absolute;
        background-color: $color-dustyblue-500;
        bottom: 0;
        visibility: visible;
        opacity: 1;

        &:hover {
            background-color: $color-1-500;
        }
    }
}

@media (min-width: $screen-md) {
    .footer {
        .container {
            padding-top: $spacing-10;
            padding-bottom: $spacing-9;
            grid-template-columns: repeat(3, 1fr);
            column-gap: $spacing-10;
            grid-template-areas:
                "logo  logo  logo"
                "copy  copy  copy"
                "copy  copy  copy"
                "rec   rec   rec"
                "blogo blogo blogo"
                "end   end   end";
        }

        .footer-acknowlegement-container {
            flex-direction: row;

            .acknowledgement-text {
                margin-left: $spacing-7;
            }
        }

        .footer-copy {
            margin-bottom: $spacing-10;
        }

        .footer-row {
            grid-template-columns: repeat(3, 1fr);

            &:not(.footer-nav) {
                margin-bottom: 0;
            }
        }

        .footer-endrow {
            flex-direction: row;
            align-items: center;

            .footer-endrow-hr-container {
                width: unset;
                flex: 1;
            }
        }
    }
}

@media (min-width: $screen-lg) {
    .footer {
        .container {
            padding-top: $spacing-12;
            padding-bottom: $spacing-10;
            grid-template-columns: repeat(4, 1fr);
            grid-template-areas:
                "logo  copy  copy  copy"
                "rec   copy  copy  copy"
                "rec   copy  copy  copy"
                "rec   copy  copy  copy"
                "blogo blogo blogo blogo"
                "end   end   end   end";
        }

        .footer-logo-container {
            margin-bottom: $spacing-5;
        }

        .footer-acknowlegement-container {
            flex-direction: column;
            max-width: $footer-desktop-acknowledgement-max-width;

            .acknowledgement-image-container {
                margin-bottom: $spacing-4;
            }

            .acknowledgement-text {
                margin-left: 0;
            }
        }

        .footer-row {
            column-gap: $spacing-10;
        }

        .footer-copy {
            margin-bottom: $spacing-5;
        }

        .footer-endrow {
            .footer-endrow-hr-container {
                margin-right: $spacing-5;
            }
        }
    }
}
