@use "../../1-core" as *;

.footer-nav-column {
    // @include rich-text-spacing;

    .footer-item-title {
        @include text-lg;
        @include text-black;
        margin-bottom: $spacing-4;
    }

    .footer-nav-item {
        padding-left: 0;
        @include text-sm;

        &::before {
            display: none;
        }
    }

    .footer-item-link {
        @include footer-link;
    }
}
