@use "../../1-core" as *;
@use "../../1-core/typography/link-styles" as *;

@mixin article-text-link {
    color: $black;
    text-decoration: none;
    @include transition(color);

    &:hover,
    &:active {
        color: $color-1-500;
        @include transition(color, 200ms);
    }

    &:focus-visible {
        color: $color-1-500;
        outline: 2px solid $color-1-500;
        outline-offset: 2px;
    }
}

.article-card {
    border: 1px solid $grey-1-300;
    border-radius: $spacing-2;
    background-color: $white;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;

    .artc-image-container {
        border-radius: $spacing-2 $spacing-2 0 0;
        overflow: visible;
        @include transition(filter);
        @include transition();

        figure picture {
            background-color: transparent;
        }

        img {
            clip-path: url(#clip-path-text-image-mobile);
        }
    }

    > a:hover .artc-image-container {
        @include transition(filter, 200ms);
        filter: drop-shadow(0 3px 6px rgba($grey-1-900, 0.4));
    }

    .artc-content-wrapper {
        flex: 1;
        padding: $spacing-6;
        display: flex;
        flex-direction: column;
    }

    .artc-date {
        @include text-xs;
        margin-bottom: $spacing-2;
    }

    .artc-heading {
        @include text-2xl;
        @include text-bold;
        margin-bottom: $spacing-4;
    }

    .artc-heading-link {
        @include text-2xl;
        @include text-bold;
        @include article-text-link;
    }

    .artc-chips-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: $spacing-4;
        margin-bottom: $spacing-4;

        .chip-component {
            flex-shrink: 0;
            text-transform: uppercase;
        }
    }

    .artc-content {
        @include text-sm;
        margin-bottom: $spacing-5;
        flex: 1;
        display: -webkit-box;
        -webkit-line-clamp: var(--max-line);
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: calc(28px * var(--max-line));

        --max-line: 4;
    }

    .artc-cta-button {
        display: inline-flex;
        margin-top: $spacing-6;
    }

    .author-component {
        margin-top: auto;
    }
}

.article-card-link {
    text-decoration: none;
    color: $black;
    border-radius: $spacing-2;
    width: 100%;

    &:hover {
        @include light-shadow(md);

        .artc-image-container {
            transform: scale(1.1);
        }
    }
}

@media (min-width: $screen-lg) {
    .article-card {
        .artc-heading {
            @include text-2xl;
        }
    }
}
