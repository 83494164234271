@use "../../1-core" as *;

.text-icon-grid {
    padding: $spacing-6;
    border: 1px solid $grey-1-300;
    border-radius: $spacing-2;
    background-color: $white;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .ticg-icon-container .svg-icon {
        width: $spacing-9;
        height: $spacing-9;
        color: $black;
        margin-bottom: $spacing-5;
    }

    .ticg-heading {
        @include text-xl;
        @include text-bold;
        margin-bottom: $spacing-4;
    }

    .ticg-content {
        @include rich-text-spacing;
        margin-bottom: $spacing-6;
    }

    .ticg-button {
        margin-top: auto;
        display: inline-flex;
        min-height: var(--btn-height);
        height: auto;
    }
}

@media (min-width: $screen-sm) {
    .text-icon-grid {
        padding: $spacing-8;
    }
}

@media (min-width: $screen-lg) {
    .text-icon-grid {
        padding: $spacing-8;

        .ticg-icon-container .svg-icon {
            width: $spacing-10;
            height: $spacing-10;
        }

        .ticg-heading {
            @include text-2xl;
        }
    }
}
