@use "../../1-core" as *;
@use "../../1-core/typography/list-styles" as *;
@use "../../3-components/Buttons/buttons" as *;

$phb-feature-content-min-width: 240px;

.property-hero-banner-content {
    padding-bottom: $spacing-10;
    margin-top: $spacing-8;

    .phb-anchors-container {
        @include col(4);
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        margin: $spacing-8 0;

        .phb-anchor {
            @include text-sm;
            text-align: center;
            margin: 0 $spacing-2 $spacing-3;
        }

        .phb-anchor-separator {
            width: $spacing-1;
            height: $spacing-1;
            border-radius: calc(#{$spacing-1} / 2);
            margin: 0 $spacing-1;
            background-color: var(--theme-background-accent);
        }

        .phb-anchor-separator:first-of-type {
            display: none;
        }

        &.no-page-anchor {
            display: none;
        }
    }

    .phb-mobile-anchors-container {
        @include col(4);
        margin-bottom: $spacing-8;

        &.no-page-anchor {
            display: none;
        }
    }

    .phb-section-separator {
        border: none;
        border-bottom: 1px solid var(--theme-divider, --theme-background-accent);
        margin: 0 0 $spacing-10;

        &.no-page-anchor {
            display: none;
        }
    }

    .phb-section-separator.content-only {
        margin: 0 0 $spacing-12;
    }

    .phb-text-container:not(.content-only) {
        @include grid();
        grid-template-areas:
            "sub  sub  sub  sub"
            "feat feat feat feat"
            "text text text text";
    }

    .phb-header {
        grid-area: sub;

        .ribbon {
            width: fit-content;
        }
    }

    .phb-subheading {
        @include text-4xl;
        @include text-black;
        color: var(--theme-text-prominent);
        margin-bottom: $spacing-4;
    }

    .phb-feature-content {
        grid-area: feat;
        color: var(--theme-text-prominent);
        @include list-reset;
        margin-bottom: $spacing-4;
        min-width: $phb-feature-content-min-width;

        .embedsocial-hashtag {
            pointer-events: none;
        }
    }

    .phb-feature-content > .property-feature-item {
        margin-bottom: $spacing-2;
    }

    .phb-chips-container {
        margin-bottom: 40px;

        .chip-component {
            border-color: var(--theme-button-secondary-border);
            margin-right: $spacing-2;
            margin-bottom: $spacing-2;

            .chip-title {
                color: var(--theme-text-prominent);
            }
        }
    }

    .phb-text-content {
        grid-area: text;
        @include text-md;
        color: var(--theme-text-prominent);
    }

    .phb-buttons-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: $spacing-7;

        .phb-button {
            min-height: var(--btn-height);
            height: auto;
        }

        .phb-button:not(:first-of-type) {
            margin-top: $spacing-4;
        }
    }
}


@media (max-width: $screen-xs-max) {
    .property-hero-banner-content {
        .phb-anchors-container {
            display: none;
        }

        .phb-buttons-container {
            align-items: stretch;
            text-align: center;
        }
    }
}

@media (min-width: $screen-sm) {
    .property-hero-banner-content {
        padding-bottom: $spacing-12;

        .phb-mobile-anchors-container {
            display: none;
        }

        .phb-text-container:not(.content-only) {
            grid-template-areas:
                "sub  sub  sub  sub  sub  sub  sub  sub"
                "feat feat feat feat feat feat feat feat"
                "text text text text text text text text";
        }

        .phb-subheading {
            @include text-5xl;
        }

        .phb-buttons-container {
            flex-direction: row;

            .phb-button {
                @include button-size('lg');
            }

            .phb-button:not(:first-of-type) {
                margin-top: 0;
                margin-left: $spacing-4;
            }
        }
    }
}

@media (min-width: $screen-sm) and (max-width: $screen-md-max) {
    .property-hero-banner-content {
        .phb-text-container {
            padding: 0 $spacing-8;
        }
    }
}

@media (min-width: $screen-lg) {
    .property-hero-banner-content {
        padding-bottom: $spacing-12;
        margin-top: $spacing-8;

        .phb-anchors-container {
            margin: $spacing-8 0;
        }

        .phb-text-container:not(.content-only) {
            grid-template-areas:
                ". sub  sub  sub  sub  sub  sub  sub sub  sub  sub  ."
                ". text text text text text text .   feat feat feat .";
        }

        .phb-text-container.content-only {
            display: grid;
            grid-template-areas:
                "sub sub sub sub sub sub"
                "text text text feat feat feat";
            column-gap: $spacing-12;
            row-gap: $spacing-5;
        }
    }
}
