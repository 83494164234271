@use "../../1-core" as *;
@use "./form-element-wrapper" as *;
@use '../icons' as *;

$input-padding:                     0 $element-side-padding;
$input-border-color:                $grey-1-300;
$input-radius:                      $spacing-6;
$input-color:                       $black;
$input-bg:                          $white;

@mixin base-input-styles {
    @include text-sm;
    -webkit-appearance: none;
    width: 100%;
    padding: $input-padding;
    border: 1px solid $input-border-color;
    border-radius: $input-radius;
    color: $input-color;
    background-color: $input-bg;
    display: block;
}

input {
    @include base-input-styles;
}
