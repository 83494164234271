@use "../../1-core" as *;
@use "./inputs" as *;

textarea {
    @include base-input-styles;
    border-radius: $spacing-2;
    padding: $spacing-4;
    min-height: $spacing-11;
    max-width: 100%;
}
