@use "../../1-core" as *;
@use "../../1-core/typography/list-styles" as *;

$author-social-share-divider-color:     #{$black}7F;

.author-social-share {
    display: flex;
    flex-direction: column;
    border-top: 1px solid $author-social-share-divider-color;
    padding: $spacing-4 0;

    .author-component + .aussh-social-share {
        display: flex;
        align-items: center;
        border-top: 1px solid $author-social-share-divider-color;
        padding-top: $spacing-5;
        margin-top: $spacing-5;
    }
}

@media (min-width: $screen-sm) {
    .author-social-share {
        flex-direction: row;

        .author-component + .aussh-social-share {
            margin-left: $spacing-6;
            border-left: 1px solid $author-social-share-divider-color;
            border-top: none;
            padding-top: 0;
            margin-top: 0;

            .social-share {
                margin-left: $spacing-6;
            }
        }
    }
}
