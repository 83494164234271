@use "../../1-core" as *;

$thin-header-min-width:             392px;

.thin-header {
    position: relative;
    min-height: $thin-header-min-width;

    .thin-header-shape {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: var(--theme-background);
        z-index: 0;
        clip-path: url(#clip-path-thin-header-fixed);
    }

    .container {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        min-height: $thin-header-min-width;
        height: 100%;
        justify-content: center;
    }

    .thin-header-copy {
        @include col(4);
        padding: 0;
        margin-bottom: #{$spacing-8 + $spacing-2};
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
    }

    .thin-header-title {
        @include text-5xl;
        @include text-black;
        color: var(--theme-text-prominent);
    }
}

// .thin-header.thin-header-search {
//     .thin-header-copy {
//         @include col(10, 1);
//     }

//     .thin-header-title,
//     .thin-header-search-container {
//         flex: 1;
//     }

//     .thin-header-title {
//         @include text-4xl;
//     }

//     .thin-header-separator {
//         width: 1px;
//         height: 50%;
//         border-left: 1px solid var(--theme-divider);
//         margin: 0 64px;
//     }

//     .thin-header-search-container {
//         display: flex;
//         align-items: flex-end;

//         .form-element {
//             margin-right: 16px;
//             flex: 1;
//         }
//     }
// }

@media (min-width: $screen-sm) {
    .thin-header {
        .container {
            @include grid();
        }

        .thin-header-copy {
            @include col(8);
            padding: 0 $spacing-4;
            text-align: left;
            justify-content: left;
        }

        .thin-header-title {
            @include text-7xl;
        }
    }
}

@media (min-width: $screen-xl) {
    .thin-header {
        .thin-header-copy {
            @include col(8,1);
            padding: 0;
        }

        .thin-header-title {
            @include text-8xl;
        }
    }
}

@media (min-width: $screen-2xl) {
    .thin-header {
        aspect-ratio: var(--aspect-ratio);

        --aspect-ratio: 1440 / 392;

        .thin-header-shape {
            aspect-ratio: var(--aspect-ratio);
            height: 0;
            width: 100%;
            padding-bottom: calc(100% / (var(--aspect-ratio)));
            clip-path: url(#clip-path-thin-header);
        }
    }

    .thin-header:has(.pa-login-form-wrapper) {
        aspect-ratio: auto;
        height: 0;
        width: 100%;
        padding-bottom: calc(100% / (var(--aspect-ratio)) + #{$spacing-8});
        min-height: 650px;

        --aspect-ratio: 1440 / 442;
    }
}
