@use "../../1-core" as *;

$room-card-min-height-tablet:       460px;

.room-card {
    border-radius: $spacing-2;
    border: 1px solid $grey-1-300;
    // margin-bottom: $spacing-7;
    background-color: $white;

    .rmcr-image-container {
        border-radius: $spacing-2 $spacing-2 0 0;

        --aspect-ratio: 16/10;
        --image-alignment: center center;

        figure picture {
            height: 0;
            width: 100%;
            padding-bottom: calc(100% / (var(--aspect-ratio)));
            // position: relative;
            background-color: transparent;
        }

        img {
            width: 100%;
            clip-path: url(#clip-path-horizontal-image-mobile);
            position: absolute;
        }
    }

    .rmcr-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: $spacing-6;
    }

    .rmcr-heading {
        @include text-xl;
        @include text-bold;
        margin-bottom: $spacing-5;
    }

    .rmcr-chips-container {
        margin-bottom: $spacing-4;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        flex-wrap: wrap;

        .chip-component {
            margin-right: $spacing-2;
            margin-bottom: $spacing-2;

            &:last-of-type {
                margin-right: 0;
            }
        }
    }

    .rmcr-desc {
        @include rich-text-spacing;

        > h5 {
            @include text-lg;
            margin-bottom: 0;
        }

        .rmcr-feature-list {
            p,
            > li,
            > ul li,
            > ol li {
                @include text-sm;
            }
        }


        .rmcr-feature-list-item::before {
            margin-top: 2px;
            background-image: url("/assets/icons/check.svg");
        }

        p.rmcr-sold-date {
            margin-top: 0;
            @include text-sm;
        }
    }
}

@media (min-width: $screen-sm) {
    .room-card {
        @include grid();

        .rmcr-image-container {
            @include col(3);
            border-radius: $spacing-2 0 0 $spacing-2;
            min-height: $room-card-min-height-tablet;

            --aspect-ratio: initial;

            figure picture {
                height: 100%;
                padding-bottom: 0;
            }

            img {
                height: 100%;
                clip-path: url(#clip-path-horizontal-image-desktop-curve-right);
            }
        }

        .rmcr-content {
            @include col(5, 3);
            padding: $spacing-8;
            padding-left: $spacing-8 - $spacing-5;
        }

        .rmcr-heading {
            @include text-2xl;
        }
    }
}

@media (min-width: $screen-lg) {
    .room-card {
        @include grid();
        aspect-ratio: unset;

        .rmcr-image-container {
            @include col(5);
            border-radius: $spacing-2 0 0 $spacing-2;

            --aspect-ratio: initial;

            figure picture {
                height: 100%;
                padding-bottom: 0;
            }

            img {
                height: 100%;
            }
        }

        .rmcr-content {
            @include col(7, 5);
            padding: $spacing-9;
        }

        .rmcr-heading {
            @include text-3xl;
        }
    }
}
