@use "../../1-core" as *;
@use "./accordion-image-item";

// Accordions
//----------------------------------------
$accordion-block-padding-desktop:                   160px 0;
$accordion-block-padding-tablet:                    80px 0;
$accordion-block-padding-mobile:                    128px 0;
$accordion-border-radius:                           80px;
$accordion-opened-heading-border-radius:            32px 32px 0 0;
$accordion-opened-content-border-radius:            0 0 32px 32px;
$accordion-content-color:                           $black;
$accordion-content-transition:                      all 150ms cubic-bezier(0.17, 0.04, 0.03, 0.94);

$accordion-heading-transition-props:                background-color, border-radius;

@mixin accordion-content-link {
    color: var(--accordion-link-color);
    outline: var(--accordion-link-outline);
    outline-offset: var(--accordion-link-outline-offset);
    box-shadow: none;

    &:hover,
    &:active {
        --accordion-link-color: #{$black};
    }

    &:focus-visible {
        --accordion-link-color: #{$black};
        --accordion-link-outline: 2px solid #{$black};
        --accordion-link-outline-offset: 1px;
    }

    --accordion-link-color: #{$color-1-500};
    --accordion-link-outline: none;
    --accordion-link-outline-offset: 0;
}

.accordion {
    background-color: var(--theme-background);
    padding: $accordion-block-padding-mobile;
    @include global-section-vertical-spacing;

    .container {
        @include grid();
    }

    .accordion-group-heading,
    .accordion-item {
        @include col(4);
    }

    .accordion-group-heading {
        @include text-4xl;
        @include text-black;
        text-align: center;
        margin-bottom: $spacing-10;
        color: var(--theme-text-prominent);
    }

    .accordion-item {
        display: block;
        @include transition(margin-bottom, 300ms);
        // margin-bottom: $spacing-5;

        .accordion-heading {
            button.accordion-title {
                @include text-sm;
                @include text-bold;
                border: none;
                background-color: var(--theme-background-accent);
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: $spacing-5 $spacing-6;
                color: var(--theme-text-prominent);
                position: relative;
                width: 100%;
                cursor: pointer;
                @include transition($accordion-heading-transition-props, 300ms);
                border-radius: $accordion-border-radius;
                outline: none;
                margin: 0;

                &:focus-visible {
                    outline: 2px solid var(--theme-background);
                    outline-offset: 2px;
                }
            }

            .accordion-heading-icon .svg-icon {
                color: var(--theme-icon);
                margin-right: $spacing-3;
            }

            .accordion-title-text-wrapper {
                display: flex;
                text-align: left;
                margin-right: $spacing-3;
            }

            .accordion-icon .svg-icon {
                flex-shrink: 0;
                color: var(--theme-icon);
                transition: $accordion-content-transition;
            }
        }

        .accordion-content {
            width: 100%;
            color: $accordion-content-color;
            overflow: hidden;
            height: 0;
            background-color: var(--theme-background-reversed);
            @include transition(height, 300ms, ease, 1ms);
            border-radius: $accordion-opened-content-border-radius;

            .accordion-content-display {
                @include rich-text-spacing;
                padding: 0 $spacing-6 $spacing-6;

                & > .body-base,
                & > p,
                & > ul li,
                & > ol li,
                & > ul p,
                & > ol p {
                    @include text-sm;
                }

                a,
                a:not(.btn):not(.has-icon) {
                    @include accordion-content-link;
                }
            }
        }

        &.is-open {
            border-radius: 32px;

            .accordion-heading {
                button.accordion-title {
                    background-color: var(--theme-background-reversed);
                    color: var(--theme-text-reversed);
                    border-radius: $accordion-opened-heading-border-radius;

                    &:focus-visible {
                        outline: none;
                    }
                }

                .accordion-heading-icon .svg-icon {
                    color: var(--theme-text-reversed);
                }

                .accordion-icon .svg-icon {
                    transform: rotateZ(180deg);
                    color: var(--theme-text-reversed);
                }
            }
        }


        &.closing {
            .accordion-heading {
                button.accordion-title {
                    color: var(--theme-text-reversed);
                    @include transition($accordion-heading-transition-props, 300ms, ease, 200ms);
                }
            }
        }
    }
}

@media (min-width: $screen-sm) {
    .accordion {
        padding: $accordion-block-padding-tablet;

        .accordion-group-heading,
        .accordion-item {
            @include col(8);
        }

        .accordion-item {
            .accordion-heading {
                button.accordion-title {
                    @include text-md;
                    padding: $spacing-5 $spacing-7;
                }
            }

            .accordion-content {
                .accordion-content-display {
                    padding: 0 $spacing-7 $spacing-7;
                }
            }
        }
    }
}

@media (min-width: $screen-lg) {
    .accordion {
        padding: $accordion-block-padding-desktop;

        .accordion-group-heading {
            @include text-5xl;
        }

        .accordion-group-heading,
        .accordion-item {
            @include col(10, 1);
        }
    }
}
