@use "../../1-core" as *;
@use "../../3-components/Buttons/buttons" as *;
@use "../../4-sections/DesktopHeader/desktop-header" as *;


$mh-top-button-size:            40px;
$mh-main-height:                72px;
$mh-cta-container-max-width:    230px;
$mh-menu-item-link-icon-size:   20px;
$mh-menu-third-level-top:       0;
$mh-logo-width:                 90px;
$mh-logo-height:                48px;

.mobile-header {
    background-color: $white;
    position: relative;

    .mh-main-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $grey-1-300;
        height: $mh-main-height;
        position: relative;
        z-index: 70;
        background-color: $white;
    }

    .mh-button {
        @include button-reset;
        width: $mh-top-button-size;
        height: $mh-top-button-size;
        display: flex;
        justify-content: center;
        align-items: center;

        .svg-icon {
            width: 20px;
            height: 20px;
        }

        .mh-button-menu-icon-close {
            display: none;
        }

        .mh-button-search-icon-close {
            display: none;
        }
    }

    .mh-logo-container {
        margin: $spacing-2 0 $spacing-4;

        img {
            width: $mh-logo-width;
            height: $mh-logo-height;
        }
    }

    .mh-menu-dropdown-container {
        position: absolute;
        top: $mh-main-height;
        left: -100vw;
        right: 0;
        width: 100vw;
        height: calc(100vh - #{$mh-main-height} + #{$spacing-8});
        z-index: 70;
        background-color: $grey-1-50;
        transition: all 400ms ease;
        transform: translateX(0);
        overflow-x: hidden;
        visibility: hidden;
    }

    .mh-menu-dropdown-first-level {
        background-color: $grey-1-50;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        min-height: calc(100vh - #{$mh-main-height});

        &.displaying-sublevel {
            @include transition(all, 400ms, ease-in-out, 400ms);
            visibility: hidden;
        }

        .mh-first-level-menu-list {
            background-color: $white;
        }

        .mh-first-level-item {
            padding: $spacing-4 $spacing-5;
            margin: 0;
            border-bottom: 1px solid $grey-1-100;
            position: initial;

            &::before {
                display: none;
            }
        }

        .mh-first-level-item-link {
            @include text-sm;
            @include text-bold;
            @include dh-link-color;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .mh-first-level-item-icon .svg-icon {
            width: $mh-menu-item-link-icon-size;
            height: $mh-menu-item-link-icon-size;
            color: $color-1-500;
        }

        // .mh-secondary-header-menu-list {
        //     padding-bottom: 48px;
        // }

        .mh-secondary-header-item {
            padding: $spacing-4 $spacing-5;
            margin: 0;

            &::before {
                display: none;
            }
        }

        .mh-secondary-header-item-link {
            @include text-sm;
            @include dh-link-color;
        }
    }

    .mh-menu-dropdown-second-level {
        background-color: $grey-1-50;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        min-height: calc(100vh - #{$mh-main-height});
        transform: translateX(200vw);
        @include transition(all, 400ms, ease-in-out);
        z-index: 71;
        visibility: hidden;
        opacity: 0;

        &.active-second-level {
            transform: translateX(0);
            visibility: visible;
            opacity: 1;
        }

        &.displaying-sublevel {
            @include transition(all, 400ms, ease-in-out, 400ms);
            visibility: hidden;
        }

        .mh-second-level-back {
            padding: $spacing-4 $spacing-5;
            background-color: $white;
            border-bottom: 1px solid $grey-1-100;
        }

        .mh-second-level-back-button {
            display: flex;
            align-items: center;
            @include text-sm;
            @include text-bold;
        }

        .mh-second-level-back-icon {
            margin-right: $spacing-2;

            .svg-icon {
                color: $color-1-500;
            }
        }

        .mh-second-level-menu-list {
            padding: $spacing-4 0;
            background-color: $white;
        }

        .mh-second-level-item {
            padding: $spacing-4 $spacing-5;
            margin: 0;
            position: initial;

            &::before {
                display: none;
            }
        }

        .mh-second-level-item-icon .svg-icon {
            width: $mh-menu-item-link-icon-size;
            height: $mh-menu-item-link-icon-size;
            color: $color-1-500;
        }

        .mh-second-level-item-link,
        .mh-second-level-overview-link {
            @include text-sm;
            @include dh-link-color;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        // .mh-second-level-overview-link {
        //     @include text-bold;
        // }
    }

    .mh-menu-dropdown-third-level {
        background-color: $grey-1-50;
        position: absolute;
        top: $mh-menu-third-level-top;
        left: 0;
        right: 0;
        min-height: calc(100vh - #{$mh-main-height});
        transform: translateX(200vw);
        @include transition(all, 400ms, ease-in-out);
        z-index: 72;
        visibility: hidden;

        &.active-third-level {
            transform: translateX(0);
            visibility: visible;
        }

        .mh-third-level-back {
            padding: $spacing-4 $spacing-5;
            background-color: $white;
            border-bottom: 1px solid $grey-1-100;
        }

        .mh-third-level-back-button {
            display: flex;
            align-items: center;
            @include text-sm;
            @include text-bold;
        }

        .mh-third-level-back-icon {
            margin-right: $spacing-2;

            .svg-icon {
                color: $color-1-500;
            }
        }

        .mh-third-level-menu-list {
            padding: $spacing-4 0;
            background-color: $white;
        }

        .mh-third-level-item {
            padding: $spacing-4 $spacing-5;
            margin: 0;

            &::before {
                display: none;
            }
        }

        .mh-third-level-item-link,
        .mh-third-level-overview-link {
            @include text-sm;
            @include dh-link-color;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        // .mh-third-level-overview-link {
        //     @include text-bold;
        // }
    }

    .mh-cta {
        max-width: $mh-cta-container-max-width;
        margin: $spacing-7 auto;
        display: flex;
        flex-direction: column;

        .cta-intro {
            text-align: center;
            margin-bottom: $spacing-5;
        }

        .mh-main-cta,
        .mh-secondary-cta {
            justify-content: center;
            margin-top: $spacing-4;
        }

        // .mh-secondary-cta {
        //     margin-top: $spacing-4;
        // }
    }

    &.mh-show-menu {
        .mh-button {
            .mh-button-menu-icon {
                display: none;
            }

            .mh-button-menu-icon-close {
                display: inline-flex;
            }
        }

        .mh-menu-dropdown-container {
            visibility: visible;
            overflow-y: auto;
            transform: translateX(100vw);
        }
    }

    &.mh-show-search {
        .mh-button {
            .mh-button-search-icon {
                display: none;
            }

            .mh-button-search-icon-close {
                display: inline-flex;
            }
        }
    }

    .mh-header-search-container {
        @include container;
        padding-top: $spacing-2;
        padding-bottom: $spacing-2;
        background-color: $grey-1-50;
        margin-top: -$spacing-9;
        @include transition(margin-top);

        .header-search-hits {
            display: none;
        }

        &.is-active {
            margin-top: 0;
            @include transition(margin-top, 200ms);

            .header-search-hits {
                display: block;
            }
        }
    }
}

@media (min-width: $screen-xl) {
    .mobile-header {
        display: none;
    }
}
