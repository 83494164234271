@use "../../1-core/" as *;

// Buttons
//----------------------------------------
$btn-skin-primary-bg:                     $color-1-500;
$btn-skin-primary-text-color:             $white;
$btn-skin-primary-border-color:           transparent;
$btn-skin-primary-border-size:            2px;

$btn-skin-primary-hover-bg:               $btn-skin-primary-text-color;
$btn-skin-primary-hover-text-color:       $btn-skin-primary-bg;
$btn-skin-primary-hover-border-color:     $btn-skin-primary-bg;

$btn-skin-primary-focus-bg:               $color-1-500;
$btn-skin-primary-focus-text-color:       $white;
$btn-skin-primary-focus-border-color:     $btn-skin-primary-focus-bg;
$btn-skin-primary-focus-outline-color:    $btn-skin-primary-focus-bg;
$btn-skin-primary-focus-outline-width:    2px;
$btn-skin-primary-focus-outline-offset:   2px;

$btn-skin-primary-active-bg:              $color-2-500;
$btn-skin-primary-active-text-color:      $white;
$btn-skin-primary-active-border-color:    $btn-skin-primary-active-bg;

$btn-skin-primary-disabled-bg:            $grey-1-50;
$btn-skin-primary-disabled-text-color:    $grey-1-300;
$btn-skin-primary-disabled-border-color:  $btn-skin-primary-disabled-bg;

// Secondary
$btn-skin-secondary-bg:                     $white;
$btn-skin-secondary-text-color:             $color-1-500;
$btn-skin-secondary-border-color:           $color-1-500;
$btn-skin-secondary-border-size:            2px;

$btn-skin-secondary-hover-bg:               $color-1-500;
$btn-skin-secondary-hover-text-color:       $white;
$btn-skin-secondary-hover-border-color:     $btn-skin-secondary-hover-bg;

$btn-skin-secondary-focus-bg:               $white;
$btn-skin-secondary-focus-text-color:       $color-1-500;
$btn-skin-secondary-focus-border-color:     $color-1-500;
$btn-skin-secondary-focus-outline-color:    $color-1-500;
$btn-skin-secondary-focus-outline-width:    2px;
$btn-skin-secondary-focus-outline-offset:   2px;

$btn-skin-secondary-active-bg:              $color-2-500;
$btn-skin-secondary-active-text-color:      $white;
$btn-skin-secondary-active-border-color:    $btn-skin-secondary-active-text-color;

$btn-skin-secondary-disabled-bg:            $grey-1-50;
$btn-skin-secondary-disabled-text-color:    $grey-1-300;
$btn-skin-secondary-disabled-border-color:  $btn-skin-secondary-disabled-bg;


// Ghost
$btn-skin-ghost-bg:                     transparent;
$btn-skin-ghost-text-color:             $color-1-500;
$btn-skin-ghost-border-color:           transparent;
$btn-skin-ghost-border-size:            2px;

$btn-skin-ghost-hover-bg:               transparent;
$btn-skin-ghost-hover-text-color:       $color-1-500;
$btn-skin-ghost-hover-border-color:     $color-1-500;

$btn-skin-ghost-focus-bg:               transparent;
$btn-skin-ghost-focus-text-color:       $color-1-500;
$btn-skin-ghost-focus-border-color:     $color-1-500;
$btn-skin-ghost-focus-outline-color:    $color-1-500;
$btn-skin-ghost-focus-outline-width:    2px;
$btn-skin-ghost-focus-outline-offset:   2px;

$btn-skin-ghost-active-bg:              transparent;
$btn-skin-ghost-active-text-color:      $color-2-500;
$btn-skin-ghost-active-border-color:    $color-2-500;

$btn-skin-ghost-disabled-bg:            $grey-1-50;
$btn-skin-ghost-disabled-text-color:    $grey-1-100;
$btn-skin-ghost-disabled-border-color:  $btn-skin-ghost-disabled-bg;

@mixin button-reset {
    padding: 0;
    text-decoration: none;
    background: none; // Button tag reset
    border: none; // Button tag reset
    cursor: pointer; // For Button tag
}

@mixin button-skin($skin: 'primary') {
    @include button-reset;
    // border-radius: $spacing-1;
    background-color: var(--btn-bg);
    color: var(--btn-color);
    border: var(--btn-border-size) solid var(--btn-border-color);
    @include transition(background-color border-color color outline);
    outline: var(--btn-outline);
    outline-offset: var(--btn-outline-offset);

    &.btn-disabled,
    &:disabled {
        cursor: default;

        &:hover {
            cursor: default;
        }
    }

    &:hover {
        @include transition(background-color border-color color, 200ms);
    }

    &:focus-visible {
        @include transition(background-color color, 200ms);
    }

    &:active {
        @include transition(background-color color, 200ms);
    }

    @if $skin == 'primary' {
        --btn-bg: var(--theme-button-primary, #{$btn-skin-primary-bg});
        --btn-color: var(--theme-button-primary-label, #{$btn-skin-primary-text-color});
        --btn-border-color: var(--theme-button-primary-border, #{$btn-skin-primary-border-color});
        --btn-outline: none;
        --btn-outline-offset: 0;
        --btn-border-size: #{$btn-skin-primary-border-size};

        &:hover {
            --btn-bg: var(--theme-button-primary-hover, #{$btn-skin-primary-hover-bg});
            --btn-color: var(--theme-button-primary-label-hover, #{$btn-skin-primary-hover-text-color});
            --btn-border-color: var(--theme-button-primary-border-hover, #{$btn-skin-primary-hover-border-color});
        }

        &:focus-visible {
            --btn-bg: var(--theme-button-primary-hover, #{$btn-skin-primary-focus-bg});
            --btn-color: var(--theme-button-primary-label-hover, #{$btn-skin-primary-focus-text-color});
            --btn-border-color: var(--theme-button-primary-border-hover, #{$btn-skin-primary-focus-border-color});
            --btn-outline: #{$btn-skin-primary-focus-outline-width} solid var(--theme-button-primary-border-hover, #{$btn-skin-primary-focus-outline-color});
            --btn-outline-offset: #{$btn-skin-primary-focus-outline-offset};
        }

        &:active {
            --btn-bg: var(--theme-button-primary-active, #{$btn-skin-primary-active-bg});
            --btn-color: var(--theme-button-primary-label-active, #{$btn-skin-primary-active-text-color});
            --btn-border-color: var(--theme-button-primary-border-active, #{$btn-skin-primary-active-border-color});
        }

        &.btn-disabled,
        &:disabled {
            --btn-bg: var(--theme-button-disabled, #{$btn-skin-primary-disabled-bg});
            --btn-color: var(--theme-button-disabled-label, #{$btn-skin-primary-disabled-text-color});
            --btn-border-color: var(--theme-button-disabled-border, #{$btn-skin-primary-disabled-border-color});
            --btn-outline: none;
        }
    }


    @if $skin == 'secondary' {
        --btn-bg: var(--theme-button-secondary, #{$btn-skin-secondary-bg});
        --btn-color: var(--theme-button-secondary-label, #{$btn-skin-secondary-text-color});
        --btn-border-color: var(--theme-button-secondary-border, #{$btn-skin-secondary-border-color});
        --btn-outline: none;
        --btn-outline-offset: 0;
        --btn-border-size: #{$btn-skin-secondary-border-size};

        &:hover {
            --btn-bg: var(--theme-button-secondary-hover, #{$btn-skin-secondary-hover-bg});
            --btn-color: var(--theme-button-secondary-label-hover, #{$btn-skin-secondary-hover-text-color});
            --btn-border-color: var(--theme-button-secondary-border-hover, #{$btn-skin-secondary-hover-border-color});
        }

        &:focus-visible {
            --btn-bg: var(--theme-button-secondary-hover, #{$btn-skin-secondary-focus-bg});
            --btn-color: var(--theme-button-secondary-label-hover, #{$btn-skin-secondary-focus-text-color});
            --btn-border-color: var(--theme-button-secondary-border-hover, #{$btn-skin-secondary-focus-border-color});
            --btn-outline: #{$btn-skin-secondary-focus-outline-width} solid var(--theme-button-secondary-border-hover, #{$btn-skin-secondary-focus-outline-color});
            --btn-outline-offset: #{$btn-skin-secondary-focus-outline-offset};
        }

        &:active {
            --btn-bg: var(--theme-button-secondary-active, #{$btn-skin-secondary-active-bg});
            --btn-color: var(--theme-button-secondary-label-active, #{$btn-skin-secondary-active-text-color});
            --btn-border-color: var(--theme-button-secondary-border-active, #{$btn-skin-secondary-active-border-color});
        }

        &.btn-disabled,
        &:disabled {
            --btn-bg: var(--theme-button-disabled, #{$btn-skin-secondary-disabled-bg});
            --btn-color: var(--theme-button-disabled-label, #{$btn-skin-secondary-disabled-text-color});
            --btn-border-color: var(--theme-button-disabled-border, #{$btn-skin-secondary-disabled-border-color});
            --btn-outline: none;
        }
    }

    @if $skin == 'ghost' {
        --btn-bg: var(--theme-button-tertiary, #{$btn-skin-ghost-bg});
        --btn-color: var(--theme-button-tertiary-label, #{$btn-skin-ghost-text-color});
        --btn-border-color: var(--theme-button-tertiary-border, #{$btn-skin-ghost-border-color});
        --btn-outline: none;
        --btn-outline-offset: 0;
        --btn-border-size: #{$btn-skin-ghost-border-size};

        &:hover {
            --btn-color: var(--theme-button-tertiary-label-hover, #{$btn-skin-ghost-hover-text-color});
            --btn-border-color: var(--theme-button-tertiary-label-hover, #{$btn-skin-ghost-hover-border-color});
        }

        &:focus-visible {
            --btn-bg: var(--theme-button-tertiary-hover, #{$btn-skin-ghost-focus-bg});
            --btn-color: var(--theme-button-tertiary-label-hover, #{$btn-skin-ghost-focus-text-color});
            --btn-border-color: var(--theme-button-tertiary-border-hover, #{$btn-skin-ghost-focus-border-color});
            --btn-outline: #{$btn-skin-ghost-focus-outline-width} solid var(--theme-button-tertiary-border-hover, #{$btn-skin-ghost-focus-outline-color});
            --btn-outline-offset: #{$btn-skin-ghost-focus-outline-offset};
        }

        &:active {
            --btn-bg: var(--theme-button-tertiary-active, #{$btn-skin-ghost-active-bg});
            --btn-color: var(--theme-button-tertiary-label-active, #{$btn-skin-ghost-active-text-color});
            --btn-border-color: var(--theme-button-tertiary-border-active, #{$btn-skin-ghost-active-border-color});
        }

        &.btn-disabled,
        &:disabled {
            --btn-bg: var(--theme-button-disabled, #{$btn-skin-ghost-disabled-bg});
            --btn-color: var(--theme-button-disabled-label, #{$btn-skin-ghost-disabled-text-color});
            --btn-border-color: var(--theme-button-disabled-border, #{$btn-skin-ghost-disabled-border-color});
            --btn-outline: none;
        }
    }
}

.btn-skin-1 {
    @include button-skin('primary');
}

.btn-skin-2 {
    @include button-skin('secondary');
}

.btn-skin-3 {
    @include button-skin('ghost');
}

@mixin button-label($size: 'md') {
    display: flex;
    justify-content: center;
    align-items: center;
    @include text-bold;
    gap: var(--label-gap);

    .svg-icon {
        width: var(--label-icon-width);
        height: var(--label-icon-width);
    }

    @if $size == 'lg' {
        @include text-md;

        --label-gap: #{$spacing-3};
        --label-icon-width: #{$icon-md};
    }

    @if $size == 'md' {
        @include text-md;

        --label-gap: #{$spacing-3};
        --label-icon-width: #{$icon-sm};
    }

    @if $size == 'sm' {
        @include text-sm;

        --label-gap: #{$spacing-2};
        --label-icon-width: #{$icon-sm};
    }
}

$btn-lg-height:             $spacing-9;
$btn-lg-side-padding:       $spacing-6;
$btn-lg-icon-side-padding:  20px;//$spacing-4;
$btn-md-height:             $spacing-8;
$btn-md-side-padding:       $spacing-5;
$btn-md-icon-side-padding:  $spacing-3;
$btn-sm-height:             $spacing-7;
$btn-sm-side-padding:       $spacing-5;
$btn-sm-icon-side-padding:  $spacing-2;

@mixin button-size($size: 'md', $onlyIcon: false) {
    // We deliberately set height on buttons, otherwise it changes depending on font height and padding
    min-height: var(--btn-height);
    padding: 0 var(--btn-side-padding); // Button tag reset. 230 460
    border-radius: var(--btn-height);

    @if $size == 'lg' {
        --btn-height: #{$btn-lg-height};
        @include button-label('lg');

        @if $onlyIcon {
            --btn-side-padding: #{$btn-lg-icon-side-padding};
        }
        @else {
            --btn-side-padding: #{$btn-lg-side-padding};
        }
    }

    @if $size == 'md' {
        --btn-height: #{$btn-md-height};
        @include button-label('md');

        @if $onlyIcon {
            --btn-side-padding: #{$btn-md-icon-side-padding};
        }
        @else {
            --btn-side-padding: #{$btn-md-side-padding};
        }
    }

    @if $size == 'sm' {
        --btn-height: #{$btn-sm-height};
        @include button-label('sm');

        @if $onlyIcon {
            --btn-side-padding: #{$btn-sm-icon-side-padding};
        }
        @else {
            --btn-side-padding: #{$btn-sm-side-padding};
        }
    }
}

.btn-size-lg {
    &:not(.btn-only-icon) {
        @include button-size('lg');
    }

    &.btn-only-icon {
        @include button-size('lg', true);
    }
}

.btn-size-md {
    &:not(.btn-only-icon) {
        @include button-size('md');
    }

    &.btn-only-icon {
        @include button-size('md', true);
    }
}

.btn-size-sm {
    &:not(.btn-only-icon) {
        @include button-size('sm');
    }

    &.btn-only-icon {
        @include button-size('sm', true);
    }
}
