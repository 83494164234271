@use '../../1-core' as *;
@use '../../1-core/typography/list-styles' as *;

@forward './widgets/search-result';
@forward './widgets/custom-menu-filter';
@forward './widgets/custom-refinement-list';
@forward './widgets/custom-refinement-list-mobile';
@forward './widgets/custom-toggle-refinement-list';
@forward './widgets/powered-by-algolia';

.algolia-search {
    .search-title {
        @include text-4xl;
        margin-bottom: $spacing-7;

        span {
            @include text-normal;
        }
    }

    .algolia-search-searchbar {
        max-width: 627px;
        width: 100%;

        .ais-SearchBox {
            margin-bottom: $spacing-4;

            input {
                height: $spacing-9;
                border-radius: 120px;
                color: $black;
                padding-left: $spacing-5;
                outline-color: $black;
                padding-right: ($spacing-12 - $spacing-2);
            }

            .ais-SearchBox-submit {
                width: $spacing-5;
                height: $spacing-5;
                top: 20px;
                right: $spacing-5;
            }

            .svg-icon {
                width: $spacing-5;
                height: $spacing-5;
                color: $black;
            }

            .ais-SearchBox-reset {
                width: $spacing-5;
                height: $spacing-5;
                top: 20px;
                right: $spacing-9;
            }
        }

        .custom-stats {
            text-transform: uppercase;
            @include text-2xs;
        }
    }

    .algolia-search-results {
        .ais-Hits {
            .ais-Hits-list {
                @include list-reset;
            }

            .ais-Hits-item:last-of-type .search-result {
                margin-bottom: 0;
            }
        }

        .ais-Pagination {
            display: flex;
            justify-content: center;
            margin-top: $spacing-8;
            margin-bottom: $spacing-8;
        }
    }
}

@media (max-width: $screen-sm-max) {
    .algolia-search {
        padding-top: $spacing-10;
        padding-bottom: $spacing-10;

        .algolia-search-searchbar {
            padding-bottom: $spacing-5;
            border-bottom: 1px solid $grey-1-100;
            margin-bottom: $spacing-5;
        }
    }
}

@media (min-width: $screen-md) and (max-width: $screen-md-max) {
    .algolia-search {
        padding-top: $spacing-11;
        padding-bottom: $spacing-11;

        .custom-menu-filter {
            margin-bottom: $spacing-5;
        }

        .algolia-search-searchbar {
            margin-bottom: $spacing-9;
        }

        .custom-menu-filter {
            margin-bottom: $spacing-9;
        }

        .algolia-search-results {
            .ais-Pagination {
                margin-top: $spacing-9;
                margin-bottom: $spacing-9;
            }
        }
    }
}

@media (min-width: $screen-lg) {
    .algolia-search {
        padding-top: $spacing-12;
        padding-bottom: $spacing-12;

        .algolia-search-searchbar {
            margin-bottom: $spacing-9;
        }

        .custom-menu-filter {
            margin-bottom: $spacing-10;
        }

        .algolia-search-results {
            .ais-Pagination {
                margin-top: $spacing-10;
                margin-bottom: $spacing-10;
            }
        }
    }
}
