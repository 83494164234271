@use "../../1-core" as *;

$footer-form-intro-min-width: 302px;
$footer-form-intro-max-width: 350px;

.footer-form-block {
    background-color: $color-navy-500;
    padding-top: $spacing-7;

    .container {
        padding-bottom: $spacing-7;
        border-bottom: 1px solid $color-dustyblue-500;

        .form-intro {
            @include rich-text-spacing;
            color: $white;
            min-width: $footer-form-intro-min-width;
            max-width: $footer-form-intro-max-width;
            text-align: left;
            margin-bottom: $spacing-5;

            .svg-icon {
                width: $spacing-10;
                height: $spacing-10;
            }

            h2 {
                @include text-3xl;
                margin: $spacing-4 0;
            }

            h3 {
                @include text-xl;
                margin: 0;
            }
        }

        form {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: $spacing-5;

            .custom-form-section {
                display: flex;
                flex-direction: column-reverse;
                gap: $spacing-4;
                align-self: stretch;

                .custom-form-zone-wrapper {
                    display: flex;
                    flex-direction: column;
                    gap: $spacing-5;
                    align-items: flex-start;
                }

                .form-field {
                    align-self: stretch;
                    margin: 0;

                    ::placeholder {
                        color: rgba($white, 0.4);
                    }

                    label {
                        color: $white;
                        gap: $spacing-1;
                    }

                    .field-validation-error {
                        color: $white;
                    }
                }

                input {
                    background-color: rgba($white, 0.1);
                    border-color: transparent;
                    color: $white;
                    padding: ($spacing-4 + 2px) $spacing-5;

                    &:active,
                    &:focus {
                        border-color: $color-1-500;
                    }
                }
            }
        }

        .notification.hidden {
            display: none;
        }

        .notification {
            flex: 1;
        }
    }

    #subscription-preference-form-content .inpage-form-block {
        .subscription-form-intro h2 {
            @include text-xl;
            margin-top: 0;
        }

        .form-field {
            &.fullname,
            &.emailaddress {
                display: none;
            }

            &.contactgroups {
                margin-top: 0;

                .ktc-checkbox + .ktc-checkbox {
                    margin-top: $spacing-1;
                }
            }
        }

        label.control-label {
            display: none;
        }
    }
}

@media (min-width: $screen-md) {
    .footer-form-block {
        padding-top: $spacing-8;

        .container {
            padding-bottom: $spacing-9;

            .form-intro {
                h2 {
                    @include text-4xl;
                }

                h3 {
                    @include text-2xl;
                }
            }
        }

        #subscription-preference-form-content .inpage-form-block {
            display: grid;
            grid-template-columns: 1.5fr 1fr;
            grid-template-areas: "intro form";
            gap: $spacing-10;
            align-items: start;

            .subscription-form-intro h2 {
                @include text-3xl;
            }
        }
    }
}

@media (min-width: $screen-lg) {
    .footer-form-block {
        padding-top: $spacing-10;

        .container {
            display: flex;
            padding-bottom: $spacing-10;
            align-items: center;

            .form-intro {
                margin-bottom: 0;

                h2 {
                    @include text-5xl;
                }

                h3 {
                    @include text-3xl;
                }
            }

            .custom-form-wrapper {
                flex: 1;

                form {
                    flex-direction: row;
                    align-items: center;

                    .custom-form-section {
                        flex: 1;

                        .custom-form-zone-wrapper {
                            flex-direction: row;
                            align-items: flex-end;
                        }

                        .form-field {
                            flex: 1;
                        }
                    }
                }
            }
        }
    }
}
