@use "../../1-core" as *;
@use "./desktop-mega-menu";
@use "./desktop-mega-menu-group";

$dh-logo-width:                 122px;
$dh-logo-height:                65px;
$dh-active-nav-box-shadow:      inset 0 -4px 0 $color-1-500;
$dh-focused-search-width:       328px;

@mixin dh-link-color {
    color: $black;
    outline: 2px solid transparent;
    outline-offset: 2px;
    text-decoration: none;

    &:hover {
        color: $color-1-500;

        .nav-item-icon .svg-icon {
            color: $color-1-500;
        }
    }

    &:active {
        color: $color-dustyblue-500;

        .nav-item-icon .svg-icon {
            color: $grey-1-700;
        }
    }

    &:focus-visible {
        outline: 2px solid black;

        .nav-item-icon .svg-icon {
            color: $black;
        }
    }
}

.desktop-header {
    background-color: $white;
    position: relative;
    border-bottom: 1px solid $grey-1-300;

    .container {
        padding: 0 $spacing-5;
    }

    .dh-container {
        display: flex;
        align-items: center;
        position: relative;
        z-index: 5;
    }

    .dh-logo-container {
        width: $dh-logo-width;
        padding: $spacing-4 0;

        img {
            width: $dh-logo-width;
            height: $dh-logo-height;
        }
    }

    .dh-nav {
        margin-left: $spacing-3;
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-self: stretch;

        .dh-nav-item {
            display: flex;
            align-items: stretch;

            // &:hover {
            //     box-shadow: $dh-active-nav-box-shadow;
            // }
        }

        .dh-nav-item.active-nav-item {
            box-shadow: $dh-active-nav-box-shadow;

            .nav-item-icon {
                .svg-icon {
                    transform: rotateZ(180deg);
                }
            }
        }

        .dh-nav-item-link {
            align-items: center;
            margin: 0 $spacing-4;
            @include text-sm;
            @include dh-link-color;
            display: flex;

            .nav-item-icon {
                margin-left: $spacing-2;

                .svg-icon {
                    color: $grey-1-500;
                }
            }
        }
    }

    .dh-actions {
        position: relative;
        width: $spacing-12 - $spacing-2;
        height: $spacing-8;

        .header-search {
            position: absolute;
            width: $spacing-12 - $spacing-2;
            right: 0;
            @include transition(width);

            &.active {
                width: 480px;
                @include transition(width, 200ms);
            }
        }

        .header-search-hits {
            width: 480px;
        }
    }

    &.expand-second-header {
        .dh-container {
            margin-top: $spacing-8;
        }
    }
}

header#header {
    z-index: 25;
    position: absolute;
    width: 100%;
    top: var(--top-modifier, 0);

    &.header-sticky {
        position: fixed;
        transform: translateY(calc(-1 * var(--hide-modifier, 200%)));
        @include light-shadow(sm);
    }

    &.header-sticky-show {
        transform: translateY(0);
        transition: all 300ms ease-out;
        top: 0;
    }

    &.header-sticky-hide {
        transform: translateY(calc(-1 * var(--hide-modifier, 200%)));
        transition: all 300ms ease-out;
    }

    &.header-sticky:not(.header-sticky-show) {
        .header-search-hits {
            display: none;
        }
    }
}

@media (max-width: $screen-lg-max) {
    .desktop-header {
        display: none;
    }
}
