@use "../sg-core" as *;
@use '../../1-core/typography/link-styles' as *;

.project-examples {
    margin-bottom: $sg-spacing-7;
    border-top: 2px solid $sg-color-shade-4;
    border-bottom: 2px solid $sg-color-shade-4;
    padding-top: $sg-spacing-7;
    padding-bottom: $sg-spacing-7;

    &:last-child {
        margin-bottom: 0;
    }

    > section > .container,
    > .container {
        position: relative;
    }

    > .container > a:not(.article-card-link) {
        @include text-link;
    }
}
