@use "../../1-core" as *;
@use "../../3-components/Buttons/buttons" as *;

$kentico-form-element-border-color-focus:   $black;
$kentico-form-textarea-height-mobile:       115px;
$kentico-form-textarea-height-tablet:       143px;
$kentico-form-field-checkbox-icon-size:     28px;

.custom-form-wrapper {
    .formwidget-submit-text,
    .formwidget-error {
        display: none;
    }
}

.custom-form-wrapper form {
    label {
        color: $black;
    }

    .form-note {
        @include text-xs;
        color: $color-1-500;
    }

    .control-label {
        margin-bottom: $spacing-2;
    }

    .form-field {
        margin: $spacing-7 0;

        input,
        textarea,
        select {
            @include transition(border-color);

            &:focus {
                @include transition(border-color, 200ms);
                border-color: $kentico-form-element-border-color-focus;
                outline: none;
                box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.1);
            }
        }

        input {
            &:not([type="radio"]):not([type="checkbox"]) {
                min-height: $spacing-8;
            }
        }

        textarea {
            min-height: $kentico-form-textarea-height-mobile;
        }

        select {
            min-height: $spacing-8;
            background-image: url(/assets/icons/chevron-down.svg);
            background-repeat: no-repeat;
            background-position-y: center;
            background-position-x: calc(100% - #{$spacing-4});
            background-size: $spacing-5;
            padding: $spacing-2 $spacing-7 $spacing-2  $spacing-4;
        }

        .ktc-radio,
        .ktc-checkbox {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: left;

            input {
                margin: 0;
            }

            label {
                flex: 1;
                margin: 0;
            }
        }

        .ktc-radio.ktc-radio-list {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        .ktc-radio {
            position: relative;

            &:not(:last-of-type) {
                margin-bottom: $spacing-4;
            }

            input[type="radio"] {
                opacity: 0;
                width: auto;
            }

            input[type="radio"] + label {
                &::before {
                    content: " ";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: $kentico-form-field-checkbox-icon-size;
                    height: $kentico-form-field-checkbox-icon-size;
                    background-color: $color-1-500;
                    mask-image: url("/assets/icons/circle.svg");
                    opacity: 1;
                }
            }

            input[type="radio"]:checked + label {
                &::before {
                    opacity: 0;
                }

                &::after {
                    content: " ";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: $kentico-form-field-checkbox-icon-size;
                    height: $kentico-form-field-checkbox-icon-size;
                    background-color: $color-1-500;
                    mask-image: url("/assets/icons/circle-filled.svg");
                }
            }
        }

        .ktc-checkbox {
            position: relative;

            input[type="checkbox"] {
                opacity: 0;
                width: auto;
            }

            input[type="checkbox"] + label {
                &::before {
                    content: " ";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: $kentico-form-field-checkbox-icon-size;
                    height: $kentico-form-field-checkbox-icon-size;
                    background-color: $color-1-500;
                    mask-image: url("/assets/icons/square.svg");
                    opacity: 1;
                }
            }

            input[type="checkbox"]:checked + label {
                &::before {
                    opacity: 0;
                }

                &::after {
                    content: " ";
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: $kentico-form-field-checkbox-icon-size;
                    height: $kentico-form-field-checkbox-icon-size;
                    background-color: $color-1-500;
                    mask-image: url("/assets/icons/check-square.svg");
                }
            }
        }

        ::placeholder {
            color: $grey-1-300;
        }

        select:invalid {
            color: $grey-1-300;
        }

        .field-validation-error {
            @include text-2xs;
            color: $support-error-500;
            margin-top: $spacing-2;
        }

        .explanation-text {
            @include text-2xs;
            color: $grey-1-900;
            margin-top: $spacing-2;
        }
    }

    input[type="submit"] {
        @include button-size('md');
        @include button-skin('primary');
        padding: $spacing-2  $spacing-5;
        // display: inline-grid;
        width: auto;
    }

    .notification {
        margin-bottom: $spacing-7;
    }
}

@media (min-width: $screen-sm) {
    .custom-form-wrapper form {
        .form-field {
            input {
                &:not([type="radio"]):not([type="checkbox"]) {
                    min-height: $spacing-9;
                }
            }

            textarea {
                min-height: $kentico-form-textarea-height-tablet;
            }

            select {
                min-height: $spacing-9;
                padding: $spacing-4 $spacing-7  $spacing-4  $spacing-5;
            }
        }

        input[type="submit"] {
            @include button-size('lg');
            // display: inline-grid;
            width: auto;
        }
    }
}
