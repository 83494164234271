@use "../../1-core" as *;

.media-carousel-block {
    margin: $spacing-10 0;

    .grid-copy {
        @include col(4);
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-bottom: $spacing-6;
    }

    .grid-copy-title {
        @include text-4xl;
        @include text-black;
        margin-bottom: $spacing-4;
    }

    .grid-copy-desc {
        @include text-md;
    }

    .media-carousel-container {
        width: 100%;
        overflow-x: hidden;
    }

    .swiper-slide-image {
        border-radius: $spacing-2;
    }

    .swiper-slide .video-embed .video-embed-overlay {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 38.89%, rgba(0, 0, 0, 0.5) 100%);
    }

    .swiper-main {
        margin-bottom: $spacing-4;
        position: relative;

        .swiper-button-prev,
        .swiper-button-next {
            display: none;
        }
    }

    .swiper-thumb.hidden {
        display: none;
    }

    .swiper-thumb .swiper-slide {
        position: relative;
        max-width: $spacing-13;

        &::after {
            content: " ";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba($color: $black, $alpha: 0.6);
            z-index: 1;
            border-radius: $spacing-2;
        }

        &.swiper-slide-thumb-active {
            opacity: 1;

            &::after {
                background-color: rgba($color: $black, $alpha: 0);
            }
        }
    }

    .swiper-thumb .swiper-slide .video-embed-container {
        border-radius: $spacing-2;

        .video-embed-img-container,
        .video-embed-img-container img {
            border-radius: $spacing-2;
        }

        .video-embed-overlay {
            background: none;
            border-radius: $spacing-2;
        }

        .video-embed-info {
            display: none;
            opacity: 0;
        }

        .video-embed-play-btn {
            margin-top: calc(-1 * #{$spacing-6} / 2);
            margin-left: calc(-1 * #{$spacing-6} / 2);
            cursor: default;
            pointer-events: none;

            .svg-icon {
                width: $spacing-6;
                height: $spacing-6;
            }
        }
    }

    .swiper-pagination {
        padding: 0 $spacing-5;
        position: relative;
        bottom: unset;
        left: unset;
        margin-top: $spacing-6;

        .swiper-pagination-bullet {
            --swiper-pagination-color: #{$white};
            --swiper-pagination-bullet-inactive-color: #{$black};
            --swiper-pagination-bullet-inactive-opacity: 1;
        }

        .swiper-pagination-bullet-active {
            box-shadow: 0 0 0 2px black;
        }
    }
}

@media (min-width: $screen-sm) {
    .media-carousel-block {
        margin: $spacing-12 0;

        .grid-copy {
            margin-bottom: $spacing-8;
        }

        .swiper-main {
            margin-bottom: $spacing-5;
        }

        .swiper-pagination {
            margin-top: $spacing-8;
        }
    }
}

@media (min-width: $screen-lg) {
    .media-carousel-block {
        .grid-copy {
            margin-bottom: $spacing-9;
        }

        .grid-copy-title {
            @include text-5xl;
        }

        .swiper-main:not(.one-slide) {
            --swiper-navigation-size: #{$spacing-9};
            --swiper-navigation-icon-size: #{$spacing-5};

            .swiper-button-prev,
            .swiper-button-next {
                display: flex;
                top: 50%;
                left: unset;
                width: var(--swiper-navigation-size);
                height: var(--swiper-navigation-size);
                background-color: $color-1-500;
                border-radius: var(--swiper-navigation-size);
            }

            .swiper-button-prev {
                left: $spacing-5;
            }

            .swiper-button-next {
                right: $spacing-5;
            }

            .swiper-button-prev::after,
            .swiper-button-next::after {
                content: '';
                font-family: inherit;
                position: absolute;
                inset: 0;
                background-color: $white;
                mask-position: center center;
                mask-repeat: no-repeat;
                mask-size: var(--swiper-navigation-icon-size) var(--swiper-navigation-icon-size);
            }

            .swiper-button-prev::after {
                mask-image: url('/assets/icons/chevron-left.svg');
            }

            .swiper-button-next::after {
                mask-image: url('/assets/icons/chevron-right.svg');
            }
        }

        .swiper-pagination {
            padding: 0 $spacing-6;
        }
    }
}
