@use "../../1-core" as *;

$carousel-cover-height-dt: 720px;
$carousel-cover-height-mb: 500px;

.property-carousel-block {
    .property-carousel-nav {
        @include text-md;
        display: flex;
        width: fit-content;
        cursor: pointer;
        color: rgba($white, 0.5);
        margin: 0 auto $spacing-7 auto;

        .nav-item {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            column-gap: $spacing-4;
            row-gap: $spacing-1;
            justify-content: center;
            text-align: center;
            padding: $spacing-3 $spacing-4;

            .svg-icon {
                color: rgba($white, 0.5);
                width: $spacing-5;
            }

            &:hover,
            &.active {
                color: $white;

                .svg-icon {
                    color: $white;
                }
            }
        }
    }

    .property-carousel-cover {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 100%;
        height: $carousel-cover-height-mb;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        cursor: pointer;

        .property-carousel-nav {
            border-radius: $spacing-2;
            margin: auto auto $spacing-4 auto;

            .nav-item {
                background-color: $black;
                color: $white;

                .svg-icon {
                    color: $white;
                }

                &:first-child {
                    border-radius: $spacing-1 0 0 $spacing-1;
                }

                &:last-child {
                    border-radius: 0 $spacing-1 $spacing-1 0;
                }

                &:only-child {
                    border-radius: $spacing-1;
                }

                &:hover {
                    background-color: $white;
                    color: $black;

                    .svg-icon {
                        color: $black;
                    }
                }
            }

            .nav-item + .nav-item {
                border-left: 1px solid $grey-1-900;
            }
        }
    }

    .property-carousel {
        display: none;
        position: fixed;
        top: 0;
        height: 100%;
        width: 100%;
        background: $color-navy-500;
        z-index: 99;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;

        .property-carousel-head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: $spacing-3;
            color: $white;

            .swiper-pagination-property {
                position: static;
                text-align: center;
                @include text-md;
            }

            .exit-carousel {
                cursor: pointer;

                .svg-icon {
                    width: $spacing-6;
                }
            }
        }

        .property-carousel-container {
            width: 100%;
            overflow: hidden;
            margin: auto;
        }

        .swiper-main {
            .swiper-button-prev,
            .swiper-button-next {
                display: none;
            }

            .image-wrapper {
                --image-object-fit: contain !important;

                img,
                picture {
                    margin: auto;
                    background-color: unset;
                }
            }
        }
    }
}

@media (min-width: $screen-sm) {
    .property-carousel-block {
        .swiper-main {
            margin-bottom: $spacing-5;
        }
    }
}

@media (min-width: $screen-md) {
    .property-carousel-block {
        .property-carousel-cover {
            height: $carousel-cover-height-dt;

            .property-carousel-nav {
                margin-bottom: $spacing-5;
            }
        }

        .property-carousel {
            .property-carousel-container {
                width: 80%;
                max-width: $screen-2xl;
            }
        }

        .swiper-main:not(.one-slide) {
            --swiper-navigation-size: #{$spacing-7};
            --swiper-navigation-icon-size: #{($spacing-5 - $spacing-1)};

            .swiper-button-prev,
            .swiper-button-next {
                display: flex;
                top: 50%;
                left: unset;
                width: var(--swiper-navigation-size);
                height: var(--swiper-navigation-size);
                background-color: $white;
                border-radius: var(--swiper-navigation-size);
                @include transition();

                &:hover {
                    background-color: $color-2-500;

                    &::after {
                        background-color: $white;
                    }
                }
            }

            .swiper-button-prev {
                left: $spacing-5;
            }

            .swiper-button-next {
                right: $spacing-5;
            }

            .swiper-button-prev::after,
            .swiper-button-next::after {
                content: '';
                font-family: inherit;
                position: absolute;
                inset: 0;
                background-color: $color-2-500;
                mask-position: center center;
                mask-repeat: no-repeat;
                mask-size: var(--swiper-navigation-icon-size) var(--swiper-navigation-icon-size);
            }

            .swiper-button-prev::after {
                mask-image: url('/assets/icons/chevron-left.svg');
            }

            .swiper-button-next::after {
                mask-image: url('/assets/icons/chevron-right.svg');
            }
        }
    }
}
