@use "../spacing-scale" as *;

// Lists
// ----------------------------------------
$list-item-margin:                  $spacing-3;
$list-icon-width:                   $spacing-5;
$list-icon-text-spacing:            $spacing-2;
$sub-list-top-margin:               $spacing-2;

// Reset lists
//----------------------------------------
@mixin list-reset {
    list-style: none;
    padding: 0;
    margin: 0;

    > li {
        list-style: none;
        padding: 0;
        margin-bottom: 0;
    }

    > li::before {
        display: none;
    }
}

ul {
    @include list-reset;

    > li {
        padding-left: $list-icon-width + $list-icon-text-spacing;
        position: relative;
        margin-bottom: $list-item-margin;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            display: block;
            top: 0;
            width: $list-icon-width;
            height: $list-icon-width;
            background: url('/assets/icons/round-solid.svg');
            background-size: $list-icon-width $list-icon-width;
            background-position: center center;
        }
    }
}

ol {
    @include list-reset;
    counter-reset: section;

    > li {
        padding-left: $list-icon-width + $list-icon-text-spacing;
        position: relative;
        margin-bottom: $list-item-margin;

        &::before {
            position: absolute;
            left: 0;
            counter-increment: section;
            content: counter(section) ".";
            width: $list-icon-width;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}

ul,
ol {
    ul li::before {
        background: url('/assets/icons/round-outline.svg');
    }

    ol > li::before {
        content: counter(section, lower-alpha) ".";
    }

    li {
        > ul,
        > ol {
            margin-top: $sub-list-top-margin;
        }
    }
}
