@use "../../1-core" as *;
@use "./inputs" as *;
@use "./form-element-wrapper" as *;
@use "./labels" as *;
@use "./disabled-styles" as *;
@use "./validation-styles" as *;

$checkbox-width: 28px;

.checkbox,
.radio {
    label {
        margin-bottom: 0;
        position: relative;
        padding-left: $checkbox-width + $spacing-1;
        min-height: $checkbox-width;

        &::before,
        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            background-color: $color-2-500; //$input-color;
            width: $checkbox-width;
            height: $checkbox-width;
            @include transition(opacity);
        }
    }

    &:not(.radio) {
        label {
            &::before {
                mask-image: url('/assets/icons/square.svg');
            }

            &::after {
                opacity: 0;
                // mask-image: url('/assets/icons/square-checked.svg');
                mask-image: url("/assets/icons/check-square-fill.svg");
            }
        }
    }

    &:not(.checkbox) {
        label {
            &::before {
                mask-image: url('/assets/icons/circle.svg');
            }

            &::after {
                opacity: 0;
                mask-image: url('/assets/icons/circle-filled.svg');
            }
        }
    }

    input:checked + label {
        &::before {
            opacity: 0;
        }

        &::after {
            @include transition(opacity, 200ms);
            opacity: 1;
        }
    }

    input:disabled {
        + label {
            color: $element-disabled-color;
            cursor: not-allowed;

            &::before,
            &::after {
                background-color: $element-disabled-color;
            }
        }
    }

    input:focus-visible + label {
        outline: 1px dashed $element-border-color-focus;
    }

    &.validation-error {
        label::before,
        label::after {
            background-color: $element-validation-error;
        }

        .validation-message {
            color: $element-validation-error;
        }
    }

    &.validation-success {
        label::before,
        label::after {
            background-color: $element-validation-success;
        }

        .validation-message {
            color: $element-validation-success;
        }
    }

    .validation-message {
        @include validation-message-style;
    }
}

.checkbox-group,
.radio-group {
    //fieldset defualt overides.
    padding: 0;
    border: 0;
    margin: 0;

    legend {
        @include base-label-styles;
        margin-bottom: $spacing-2;
        //override browser fieldset legend positioning
        float: left;
        width: 100%;

        + * {
            clear: both;
        }
    }


    &.validation-error {
        label::before,
        label::after {
            background-color: $element-validation-error;
        }

        legend,
        .validation-message {
            color: $element-validation-error;
        }
    }

    &.validation-success {
        label::before,
        label::after {
            background-color: $element-validation-success;
        }

        legend,
        .validation-message {
            color: $element-validation-success;
        }
    }

    .validation-message {
        @include validation-message-style;
    }
}


.checkbox-wrapper,
.radio-wrapper {
    &:not(:last-child) {
        margin-bottom: $spacing-3;
    }

    label {
        display: inline-flex;
        align-items: center;
        flex-direction: row-reverse;
        justify-content: flex-end;
        position: relative;
        padding-left: 40px;
        cursor: pointer;
        min-height: $checkbox-width;

        input {
            width: $checkbox-width;
            height: $checkbox-width;
            margin: 0;
            opacity: 0;
        }

        input,
        .svg-icon {
            position: absolute;
            top: 0;
            left: 0;
        }

        .svg-icon {
            width: $checkbox-width;
            height: $checkbox-width;
        }

        input + .input-label + .unchecked {
            color: $grey-1-500;
            transition: opacity 0.1s ease-in;
        }

        input + .input-label + .unchecked + .checked {
            opacity: 0;
            color: $grey-1-500;
            transition: opacity 0.1s ease-in;
        }

        input:checked + .input-label + .unchecked {
            opacity: 0;
        }

        input:checked + .input-label + .unchecked + .checked {
            opacity: 1;
        }

        input:disabled {
            + .input-label {
                color: $grey-1-300;
                cursor: not-allowed;
            }

            + .input-label + .unchecked {
                color: $grey-1-300;
            }
        }
    }
}

.radio-wrapper {
    label {
        // radio button has different icon set
        input:checked + .input-label + .svg-icon {
            opacity: 1;
            color: $color-2-500;
        }
    }
}
