@use "../../1-core" as *;
@use "../../3-components/Buttons/buttons" as *;

.location-listing-map-container {
    height: 640px;
    margin-bottom: $spacing-9;

    .map-pin-container {
        position: relative;
    }

    .location-map-pin {
        @include button-reset;
        position: absolute;
        transform: translate(-50%, -100%);
        top: 0;
        left: 0;
        z-index: 1;

        & > img {
            width: $spacing-5;
        }
    }

    .info-window {
        background-color: white;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, calc((100% + 40px) * -1));
        min-width: 260px;
        padding: $spacing-4 32px $spacing-4 $spacing-4;
        text-align: left;
        border-radius: $spacing-2;
        z-index: 2;
        @include light-shadow(sm);

        &::before {
            content: "";
            display: block;
            width: $spacing-5;
            height: $spacing-5;
            position: absolute;
            bottom: calc(-#{$spacing-3});
            left: calc(50% - #{$spacing-3});
            background-color: white;
            transform: rotate(45deg);
        }

        .info-title {
            @include text-xs;
            @include text-bold;
        }

        .info-address {
            @include text-xs;
        }

        .info-action a {
            @include text-xs;
            @include text-link;
            margin-top: $spacing-1;
        }

        .info-close-button {
            @include button-reset;
            position: absolute;
            width: $spacing-4;
            height: $spacing-4;
            top: $spacing-2;
            right: $spacing-2;

            .svg-icon-wrapper {
                width: $spacing-4;
                height: $spacing-4;
            }
        }
    }
}
