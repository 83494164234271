@use "../../1-core" as *;

$element-validation-error:            $support-error-500;
$element-validation-success:          $support-success-500;
$element-validation-info:             $grey-1-300;

@mixin validation-message-style {
    @include text-xs;
    margin-top: $spacing-2;
}

.form-element {
    &[class*="validation-"] {
        .validation-message {
            @include validation-message-style;
            display: flex;
            align-items: center;
        }

        .validation-message-icon {
            mask-position: center center;
            mask-repeat: no-repeat;
            width: $spacing-4;
            height: $spacing-4;
            display: inline-block;
            margin-right: $spacing-1;
        }
    }

    &.validation-error {
        :not([type="checkbox"]):not([type="radio"]),
        textarea,
        select {
            border-color: $element-validation-error;
        }

        .svg-icon {
            color: $element-validation-error;
        }

        // label,
        .validation-message {
            color: $element-validation-error;
        }

        .validation-message-icon {
            background: $element-validation-error;
            mask-image: url('/assets/icons/x-circle.svg');
        }
    }

    &.validation-success {
        :not([type="checkbox"]):not([type="radio"]),
        textarea,
        select {
            border-color: $element-validation-success;
        }

        .svg-icon {
            color: $element-validation-success;
        }

        // label,
        .validation-message {
            color: $element-validation-success;
        }

        .validation-message-icon {
            background: $element-validation-success;
            mask-image: url('/assets/icons/check-circle.svg');
        }
    }

    &.validation-info {
        .validation-message {
            color: $element-validation-info;
        }

        .validation-message-icon {
            background: $element-validation-info;
            mask-image: url('/assets/icons/alert-circle.svg');
        }
    }
}
