@use "../../1-core" as *;
@use "../../1-core/typography/list-styles" as *;
@use "../../1-core/typography/link-styles" as *;

$location-card-hc-action-button-side-padding:      20px;
$location-card-hc-min-height-desktop:              330px;

.location-card.location-card-hc {
    .location-content-hc {
        .location-content-chip-container {
            margin-bottom: $spacing-4;

            .chip-component {
                margin-bottom: $spacing-2;
                margin-right: $spacing-2;
            }
        }

        .location-content-action-container {
            display: flex;
            margin-top: $spacing-5;

            .action-button {
                --btn-side-padding: #{$location-card-hc-action-button-side-padding};
                height: unset;
                min-height: var(--btn-height);
            }

            .action-button:not(:last-of-type) {
                margin-right: $spacing-4;
            }
        }

        .location-info {
            @include list-reset;
            display: grid;
            grid-template-columns: 1fr;
        }

        ul.location-info > li.location-info-item {
            margin-bottom: $spacing-2;
        }

        .location-info-item {
            @include text-sm;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;

            .svg-icon-wrapper {
                margin-right: $spacing-4;
            }

            a {
                @include text-link;
            }
        }
    }
}

@media (min-width: $screen-sm) {
    .location-card.location-card-hc {
        .location-content-hc {
            .location-content-action-container {
                .action-button {
                    --btn-side-padding: #{$spacing-5};
                }
            }

            .location-info-item {
                flex-wrap: wrap;
            }
        }
    }
}

@media (min-width: $screen-lg) {
    .location-card.location-card-hc {
        min-height: $location-card-hc-min-height-desktop;
    }
}
