@use "../../1-core/" as *;

$link-skin-1-color:                     $color-1-500;
$link-skin-1-icon-color:                $color-1-500;
$link-skin-1-underline-color:           $link-skin-1-color;
$link-skin-1-hover-color:               $black;
$link-skin-1-hover-icon-color:          $link-skin-1-hover-color;
$link-skin-1-hover-underline-color:     $link-skin-1-hover-color;
$link-skin-1-hover-underline-height:    2px;
$link-skin-1-focus-color:               $black;
$link-skin-1-focus-icon-color:          $black;
$link-skin-1-focus-outline-color:       $black;
$link-skin-1-focus-outline-width:       2px;
$link-skin-1-focus-outline-offset:      4px;
$link-skin-1-active-color:              $grey-1-500;
$link-skin-1-active-underline-color:    $link-skin-1-active-color;
$link-skin-1-active-icon-color:         $link-skin-1-active-color;
$link-skin-1-disabled-color:            $grey-1-100;
$link-skin-1-disabled-icon-color:       $link-skin-1-disabled-color;

$link-skin-2-color:                     $grey-1-500;
$link-skin-2-icon-color:                $link-skin-2-color;
$link-skin-2-underline-color:           $link-skin-2-color;
$link-skin-2-hover-color:               $color-2-500;
$link-skin-2-hover-icon-color:          $link-skin-2-hover-color;
$link-skin-2-hover-underline-color:     $link-skin-2-hover-color;
$link-skin-2-hover-underline-height:    2px;
$link-skin-2-focus-color:               $black;
$link-skin-2-focus-icon-color:          $link-skin-2-focus-color;
$link-skin-2-focus-outline-color:       $link-skin-2-focus-color;
$link-skin-2-focus-outline-width:       2px;
$link-skin-2-focus-outline-offset:      4px;
$link-skin-2-active-color:              $color-1-500;
$link-skin-2-active-underline-color:    $link-skin-2-active-color;
$link-skin-2-active-icon-color:         $link-skin-2-active-color;
$link-skin-2-disabled-color:            $grey-1-100;
$link-skin-2-disabled-icon-color:       $link-skin-2-disabled-color;

@mixin link-skin($skin: 'primary') {
    text-decoration: none;
    cursor: pointer;
    @include transition(color);
    color: var(--theme-link-text, --link-color);
    box-shadow: 0 var(--link-underline-height) 0 var(--theme-link-text, --link-underline-color);

    .svg-icon {
        color: var(--link-icon-color);
        @include transition(color);
    }

    &:not(.link-has-icons):not(.link-disabled) {
        @include transition(all);

        &:hover {
            color: var(--theme-link-text-hover, --link-hover-color);
            box-shadow: 0 var(--link-underline-height) 0 var(--theme-link-text-hover, --link-hover-color);
            @include transition(all, 200ms);
        }

        &:active {
            color: var(--theme-link-text-active, --link-color);

            .svg-icon {
                color: var(--theme-link-text-active, --link-icon-color);
                @include transition(color);
            }
        }
    }

    &.link-has-icons {
        &:hover {
            color: var(--theme-link-text-hover, --link-hover-color);
            @include transition(color, 200ms);

            .svg-icon {
                color: var(--theme-link-text-hover, --link-hover-color);
                @include transition(color, 200ms);
            }
        }
    }

    &.link-disabled {
        color: var(--link-color);
        box-shadow: 0 var(--link-underline-height) 0 var(--link-underline-color);
        cursor: default;

        &:hover {
            color: var(--link-color);
            cursor: default;

            .svg-icon {
                color: var(--link-color);
                @include transition(color, 200ms);
            }
        }
    }

    @if $skin == 'primary' {
        --link-color: #{$link-skin-1-color};
        --link-icon-color: #{$link-skin-1-icon-color};

        &:not(.link-has-icons) {
            --link-underline-height: #{$link-skin-1-hover-underline-height};
            --link-underline-color: #{$link-skin-1-underline-color};

            &:hover {
                --link-hover-color: #{$link-skin-1-hover-color};
                --link-underline-color: #{$link-skin-1-hover-underline-color};
                --link-icon-color: #{$link-skin-1-hover-color};
            }

            &:active {
                --link-color: #{$link-skin-1-active-color};
                --link-underline-color: #{$link-skin-1-active-underline-color};
            }
        }

        &.link-has-icons {
            &:hover {
                --link-color: #{$link-skin-1-hover-color};
                --link-icon-color: #{$link-skin-1-hover-icon-color};
            }

            &:active {
                --link-color: #{$link-skin-1-active-color};
                --link-icon-color: #{$link-skin-1-active-icon-color};
            }
        }

        &:focus-visible {
            --link-color: #{$link-skin-1-focus-color};
            --link-icon-color: #{$link-skin-1-focus-icon-color};
            outline: $link-skin-1-focus-outline-width solid $link-skin-1-focus-outline-color;
            outline-offset: $link-skin-1-focus-outline-offset;
            box-shadow: none;
        }

        &.link-disabled {
            --link-color: #{$link-skin-1-disabled-color};
            --link-icon-color: #{$link-skin-1-disabled-icon-color};

            &:active {
                --link-color: #{$link-skin-1-disabled-color};
            }

            &:not(.link-has-icons) {
                --link-underline-height: #{$link-skin-1-hover-underline-height};
                --link-underline-color: #{$link-skin-1-disabled-color};
            }

            &.link-has-icons:hover {
                --link-color: #{$link-skin-1-disabled-color};
                --link-icon-color: #{$link-skin-1-disabled-icon-color};
            }
        }
    }


    @if $skin == 'secondary' {
        --link-color: #{$link-skin-2-color};
        --link-icon-color: #{$link-skin-2-icon-color};

        &:not(.link-has-icons) {
            --link-underline-height: #{$link-skin-2-hover-underline-height};
            --link-underline-color: #{$link-skin-2-underline-color};

            &:hover {
                --link-hover-color: #{$link-skin-2-hover-color};
                --link-underline-color: #{$link-skin-2-hover-underline-color};
                --link-icon-color: #{$link-skin-2-hover-color};
            }

            &:active {
                --link-color: #{$link-skin-2-active-color};
                --link-underline-color: #{$link-skin-2-active-underline-color};
            }
        }

        &.link-has-icons {
            &:hover {
                --link-color: #{$link-skin-2-hover-color};
                --link-icon-color: #{$link-skin-2-hover-icon-color};
            }

            &:active {
                --link-color: #{$link-skin-2-active-color};
                --link-icon-color: #{$link-skin-2-active-icon-color};
            }
        }

        &:focus-visible {
            --link-color: #{$link-skin-2-focus-color};
            --link-icon-color: #{$link-skin-2-focus-icon-color};
            outline: $link-skin-2-focus-outline-width solid $link-skin-2-focus-outline-color;
            outline-offset: $link-skin-2-focus-outline-offset;
            box-shadow: none;
        }

        &.link-disabled {
            --link-color: #{$link-skin-2-disabled-color};
            --link-icon-color: #{$link-skin-2-disabled-icon-color};

            &:active {
                --link-color: #{$link-skin-2-disabled-color};
            }

            &:not(.link-has-icons) {
                --link-underline-height: #{$link-skin-2-hover-underline-height};
                --link-underline-color: #{$link-skin-2-disabled-color};
            }

            &.link-has-icons:hover {
                --link-color: #{$link-skin-2-disabled-color};
                --link-icon-color: #{$link-skin-2-disabled-icon-color};
            }
        }
    }
}

.link-skin-1 {
    @include link-skin('primary');
}

.link-skin-2 {
    @include link-skin('secondary');
}

@mixin link-size($size: 'md') {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    gap: var(--link-size-gap);

    .svg-icon {
        width: var(--link-icon-width);
        height: var(--link-icon-width);
    }

    @if $size == 'lg' {
        @include text-md;

        --link-size-gap: #{$spacing-2};
        --link-icon-width: #{$icon-md};
    }

    @if $size == 'md' {
        @include text-base;

        --link-size-gap: #{$spacing-2};
        --link-icon-width: #{$icon-md};
    }

    @if $size == 'sm' {
        @include text-sm;

        --link-size-gap: #{$spacing-1};
        --link-icon-width: #{$icon-sm};
    }
}

.link-size-lg {
    @include link-size('lg');
}

.link-size-md {
    @include link-size('md');
}

.link-size-sm {
    @include link-size('sm');
}
