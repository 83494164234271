// Don't want to couple the React and non-React modal classes too tightly.
@use '../../1-core/' as *;
@use "../Buttons/buttons" as *;

body {
    .ReactModalPortal .modal-overlay {
        animation: fadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
    }
}

body.ReactModal__Body--open {
    .ReactModalPortal .modal-overlay {
        animation: fadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
    }
}

.ReactModalPortal {
    .modal-container,
    .modal-overlay {
        will-change: transform;
    }

    .modal-container {
        overflow-x: hidden;
    }

    .modal-overlay {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba($black, 0.8);
        z-index: map-get($z-index, modal);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .modal-container {
        background: $white;
        padding: $spacing-6;
        min-width: 400px;
        max-width: 600px;
        max-height: 100vh;
    }

    .modal-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: $spacing-5;
    }

    .modal-close {
        flex-shrink: 0;
        @include button-reset;
        cursor: pointer;
        z-index: map-get($z-index, 'modal-close');
        width: $spacing-7;
        height: $spacing-7;
        display: flex;
        justify-content: center;
        align-items: center;

        .svg-icon-wrapper {
            pointer-events: none;
        }

        &:focus-visible {
            outline: 2px solid $support-info-500;
            outline-offset: 2px;
        }
    }

    .modal-content {
        @include rich-text-spacing;
    }
}
