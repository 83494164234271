@use '../../../1-core/' as *;
@use '../../../1-core/typography/list-styles' as *;
@use '../../../3-components/Buttons/buttons' as *;
@use '../../../1-core/FormElements/form-element-wrapper' as *;

@mixin base-filter-styles {
    display: flex;
    justify-content: center;
    align-items: center;
    @include text-sm;
    color: $black;
    height: $spacing-7;
    margin-right: $spacing-5;
    @include transition(color border-bottom);
}

@mixin filter-active-styles {
    color: $color-1-500;
    border-bottom: 1px solid $color-1-500;
    @include transition(color border-bottom, 200ms);
}

.custom-menu-filter.mobile-version {
    p {
        margin-bottom: $spacing-2;
    }

    .ais-MenuSelect {
        width: 100%;
        max-width: 400px;
        margin-bottom: $spacing-5;
        position: relative;

        &::after {
            content: '';
            @include form-svg-base-styles();
            background-color: $black;
            mask-image: url('../assets/icons/chevron-down.svg');
            mask-position: center center;
            mask-repeat: no-repeat;
            mask-size: $spacing-5 $spacing-5;
            right: 16px;
            width: 20px;
            height: 20px;
        }

        .ais-MenuSelect-select {
            border-radius: 120px;
            height: $spacing-8;
            color: $black;
        }
    }
}

.custom-menu-filter.desktop-version {
    display: flex;
    border-bottom: 1px solid $grey-1-100;

    .ais-ClearRefinements-button {
        @include button-reset;
        @include base-filter-styles;

        &.ais-ClearRefinements-button--disabled {
            @include filter-active-styles;
        }
    }

    .ais-Menu-item {
        .ais-Menu-link {
            @include base-filter-styles;
            text-decoration: none;

            .ais-Menu-count {
                display: flex;
                justify-content: center;
                align-items: center;
                min-width: $spacing-5;
                height: $spacing-5;
                background: $color-1-50;
                border-radius: $spacing-3;
                margin-left: $spacing-2;
                color: $black;
                padding: 2px 6px;
            }
        }

        &.ais-Menu-item--selected .ais-Menu-link,
        .ais-Menu-link:hover,
        .ais-Menu-link:focus {
            @include filter-active-styles;
        }
    }

    .ais-Menu-list {
        display: flex;
        @include list-reset;
    }
}
