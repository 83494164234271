@use "../../1-core" as *;
@use "../../1-core/typography/link-styles" as *;
@use "../../1-core/typography/list-styles" as *;
@use "../../3-components/Buttons/buttons" as *;

$property-carousel-nav-size: $spacing-7;
$property-carousel-nav-icon-size: 20px;

.property-sale-card {
    border: 1px solid $grey-1-300;
    border-radius: $spacing-2;
    background-color: $white;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    height: 100%;
    width: 100%;
    position: relative;

    .swiper {
        position: relative;

        .swiper-wrapper {
            height: auto !important;
            flex-direction: row;

            .swiper-slide {
                width: 100% !important;
            }
        }

        .swiper-button-prev,
        .swiper-button-next {
            width: $property-carousel-nav-size;
            height: $property-carousel-nav-size;
            background-color: white;
            border-radius: 50%;

            &::before,
            &::after {
                background-color: $color-1-500;
                @include transition();

                --swiper-navigation-size: #{$property-carousel-nav-icon-size};
            }

            &:not(.swiper-button-disabled):hover {
                background-color: $color-2-500;

                &::before,
                &::after {
                    background-color: $white;
                }
            }
        }
    }

    .swiper-main:not(.one-slide) {
        --swiper-navigation-size: #{$spacing-9};
        --swiper-navigation-icon-size: #{($spacing-5 - $spacing-1)};
        position: relative;
        width: 100%;
        height: 100%;
        overflow: hidden;

        .swiper-wrapper {
            height: 100% !important;

            .swiper-slide {
                height: 100%;
            }
        }

        .swiper-button-prev,
        .swiper-button-next {
            position: absolute;
            width: $property-carousel-nav-size;
            height: $property-carousel-nav-size;
            display: flex;
            top: 50%;
            left: unset;
            background-color: $white;
            border-radius: var(--swiper-navigation-size);

            &:hover {
                background-color: $color-1-500;

                &::after {
                    background-color: $white;
                }
            }
        }

        .swiper-button-prev {
            left: $spacing-5;
        }

        .swiper-button-next {
            right: $spacing-5;
        }

        .swiper-button-prev::after,
        .swiper-button-next::after {
            content: '';
            font-family: inherit;
            position: absolute;
            @include transition();
            inset: 0;
            background-color: $color-1-500;
            mask-position: center center;
            mask-repeat: no-repeat;
            mask-size: var(--swiper-navigation-icon-size) var(--swiper-navigation-icon-size);
        }

        .swiper-button-prev::after {
            mask-image: url('/assets/icons/chevron-left.svg');
        }

        .swiper-button-next::after {
            mask-image: url('/assets/icons/chevron-right.svg');
        }
    }

    // .swiper-container {
    //     width: 100%;
    //     max-width: 100%;
    //     max-height: 100vh;
    //     // CSS Grid/Flexbox bug size workaround
    //     // @see https://github.com/kenwheeler/slick/issues/982
    //     // @see https://github.com/nolimits4web/swiper/issues/3599
    //     min-height: 0;
    //     min-width: 0;
    // }

    .prsc-image-container {
        border-radius: $spacing-2 $spacing-2 0 0;
        overflow: visible;
        @include transition(filter);

        figure picture {
            background-color: transparent;
        }

        img {
            clip-path: url(#clip-path-text-image-mobile);
        }
    }

    .prsc-content-wrapper {
        flex: 1;
        padding: $spacing-5 $spacing-6;
        display: flex;
        flex-direction: column;
    }

    .prsc-top-container {
        display: flex;
        flex-direction: column;

        .prsc-content-wrapper {
            padding-bottom: $spacing-4;
        }
    }

    .prsc-location {
        display: flex;
        flex-direction: row;
        margin-bottom: $spacing-2;

        .svg-icon-wrapper {
            margin-right: $spacing-4;
        }

        span {
            @include text-sm;
        }
    }

    .prsc-content-title {
        @include text-xl;
        @include text-bold;
        margin-bottom: $spacing-4;

        .prsc-name-link {
            color: $black;
            text-decoration: none;
        }
    }

    .prsc-content-chip-container {
        .chip-component {
            margin-right: $spacing-2;
            margin-bottom: $spacing-2;
        }
    }

    .prsc-bottom-container {
        flex: 1;
        display: flex;

        .prsc-content-wrapper {
            padding-top: 0;
        }
    }

    .prsc-info {
        @include list-reset;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: $spacing-4 $spacing-2;
        border-top: 1px solid $grey-1-100;
        border-bottom: 1px solid $grey-1-100;
        padding: $spacing-5 0;
        margin-bottom: auto;

        .prsc-info-item {
            display: flex;
            flex-direction: row;
            align-items: center;

            .svg-icon-wrapper {
                margin-right: $spacing-4;
            }
        }
    }

    .prsc-info-action {
        margin-top: $spacing-5;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .prsc-price-start {
        flex: 1;

        .price-text {
            @include text-lg;
            @include text-bold;

            .price-text-info {
                @include text-xs;
                @include text-normal;
                display: flex;
                flex-direction: column;
            }
        }
    }

    .prsc-chips-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: $spacing-4;
        margin-bottom: $spacing-4;

        .chip-component {
            flex-shrink: 0;
            text-transform: uppercase;
        }
    }

    .prsc-content {
        @include text-sm;
        margin-bottom: $spacing-5;
        flex: 1;
        display: -webkit-box;
        -webkit-line-clamp: var(--max-line);
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        max-height: calc(28px * var(--max-line));

        --max-line: 4;
    }

    .prsc-cta-button {
        display: inline-flex;
        margin-top: $spacing-6;
    }

    .author-component {
        margin-top: auto;
    }
}

// phone only
@media (max-width: $screen-xs-max) {
    .property-sale-card {
        .swiper {
            min-height: auto;
        }
    }
}

// tablet
@media (min-width: $screen-sm) and (max-width: $screen-md-max) {
    .property-sale-card {
        .swiper-container {
            display: flex;
        }

        .swiper {
            position: relative;
            height: 100%;

            .swiper-wrapper {
                height: 100% !important;
                flex-direction: row;

                .swiper-slide {
                    width: 100% !important;
                    height: 100% !important;
                }
            }

            .swiper-button-prev,
            .swiper-button-next {
                top: auto;
            }

            .swiper-button-prev {
                left: $spacing-4;
                bottom: $spacing-4;
            }

            .swiper-button-next {
                left: $spacing-4 + $property-carousel-nav-size + $spacing-4;
                bottom: $spacing-4;
            }
        }

        .swiper-main:not(.one-slide) {
            .swiper-button-prev,
            .swiper-button-next {
                bottom: $spacing-5;
                top: unset;
                left: unset;
            }

            .swiper-button-prev {
                left: $spacing-5;
            }

            .swiper-button-next {
                left: $spacing-10;
            }
        }

        // .swiper-container {
        //     width: 100%;
        //     max-width: 100%;
        //     max-height: 100vh;
        //     // CSS Grid/Flexbox bug size workaround
        //     // @see https://github.com/kenwheeler/slick/issues/982
        //     // @see https://github.com/nolimits4web/swiper/issues/3599
        //     min-height: 0;
        //     min-width: 0;
        // }

        .prsc-image-container {
            height: 100%;

            figure picture {
                height: 100%;
            }

            img {
                clip-path: url(#clip-path-cta-pro-desktop);
                display: block;
                width: 100%;
                height: 100%;
            }
        }

        .prsc-top-container {
            flex-direction: row;

            .swiper-container {
                width: 50%;
            }

            .prsc-content-wrapper {
                padding: $spacing-6 $spacing-7;
                justify-content: center;
            }
        }

        .prsc-location {
            margin-bottom: $spacing-4;
        }

        .prsc-content-title {
            @include text-2xl;
            margin-bottom: $spacing-5;
        }

        .prsc-bottom-container {
            border-top: 1px solid $grey-1-100;

            .prsc-content-wrapper {
                padding: 0;
                flex-direction: row;
            }
        }

        .prsc-info {
            gap: $spacing-4 $spacing-2;
            border: none;
            padding: $spacing-5;
            margin: 0;
            flex: 1;

            .prsc-info-item {
                display: flex;
                flex-direction: row;
                align-items: center;

                .svg-icon-wrapper {
                    margin-right: $spacing-4;
                }
            }
        }

        .prsc-info-action {
            margin-top: 0;
            padding: $spacing-5 $spacing-7;
            flex-direction: column;
            justify-content: center;
            border-left: 1px solid $grey-1-100;
        }

        .prsc-price-start {
            margin-bottom: $spacing-3;
        }
    }
}

@media (min-width: $screen-lg) {
    .property-sale-card {
        .swiper-main:not(.one-slide) {
            .swiper-button-prev,
            .swiper-button-next {
                top: 50%;
                left: unset;
            }

            .swiper-button-prev {
                left: $spacing-5;
            }

            .swiper-button-next {
                right: $spacing-5;
            }
        }
    }
}
