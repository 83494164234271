@use "text-styles" as *;
@use "../spacing-scale" as *;
@use "../grids-breakpoints" as *;
@use "font-weights" as *;
@use "link-styles" as *;

@mixin body-lg {
    @media (max-width: $screen-sm-max) {
        @include text-lg;
    }

    @media (min-width: $screen-md) {
        @include text-xl;
    }
}

@mixin body-base {
    // @media (max-width: $screen-sm-max) {
    //     @include text-base;
    // }

    // @media (min-width: $screen-md) {
    //     @include text-lg;
    // }
    @include text-md;
}

@mixin body-sm {
    @include text-sm;
}

@mixin rich-text-spacing {
    > *:first-child {
        margin-top: 0;
    }

    > *:last-child {
        margin-bottom: 0;
    }

    > .body-lg {
        @include body-lg;
        margin-top: $spacing-5;
        margin-bottom: $spacing-5;
    }

    > .body-base,
    > p,
    > ul li,
    > ol li,
    > ul p,
    > ol p {
        @include body-base;
    }

    > .body-base,
    > p,
    > ul p,
    > ol p {
        margin-top: $spacing-5;
        margin-bottom: $spacing-5;
    }

    a:not(.btn) {
        @include text-link;
    }

    > .body-sm {
        @include body-sm;
        margin-top: $spacing-4;
        margin-bottom: $spacing-4;
    }

    @media (max-width: $screen-sm-max) {
        > h2 {
            @include text-4xl;
            @include text-bold;
            margin-top: $spacing-7;
            margin-bottom: $spacing-5;
        }

        > h3 {
            @include text-3xl;
            @include text-bold;
            margin-top: $spacing-5;
            margin-bottom: $spacing-4;
        }

        > h4 {
            @include text-2xl;
            @include text-bold;
            margin-top: $spacing-5;
            margin-bottom: $spacing-4;
        }

        > h5,
        > h6 {
            @include text-xl;
            @include text-bold;
            margin-top: $spacing-4;
            margin-bottom: $spacing-2;
        }

        > .image-wrapper,
        > .video-embed {
            margin-top: $spacing-4;
            margin-bottom: $spacing-4;
        }
    }

    @media (min-width: $screen-md) {
        > h2 {
            @include text-5xl;
            @include text-bold;
            margin-top: $spacing-9;
            margin-bottom: $spacing-7;
        }

        > h3 {
            @include text-3xl;
            @include text-bold;
            margin-top: $spacing-9;
            margin-bottom: $spacing-5;
        }

        > h4 {
            @include text-2xl;
            @include text-bold;
            margin-top: $spacing-7;
            margin-bottom: $spacing-5;
        }

        > h5,
        > h6 {
            @include text-xl;
            @include text-bold;
            margin-top: $spacing-6;
            margin-bottom: $spacing-2;
        }

        > .image-wrapper,
        > .video-embed {
            margin-top: $spacing-6;
            margin-bottom: $spacing-6;
        }
    }
}
