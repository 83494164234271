// Colors
// =======================================================================
/* stylelint-disable color-no-hex */
/* stylelint-disable */
$color-1-50:            #E1ECEB;
$color-1-100:           #B5CFCD;
$color-1-300:           #9ACDCA;
$color-1-500:           #6B9E9B;
// $color-1-700:           #250FCC;
// $color-1-900:           #0A0AB8;

// $color-2-50:            #FFF5FA;
// $color-2-100:           #FFD8DB;
// $color-2-300:           #FF9A8A;
$color-2-500:           #407373;
// $color-2-700:           #E02800;
// $color-2-900:           #B92C19;

$color-gold-100:        #FFDA9E;    
$color-gold-500:        #DEA64C;

$color-dustypink-500:   #D6B8BB;

$color-rust-500:        #BE5F58;

$color-plum-500:        #69081D;

$color-dustyblue-500:   #637982;

$color-navy-500:        #002C41;

$color-lightgreen-300:  #9AAE76;
$color-lightgreen-500:  #677D45;

$color-khaki-500:       #505A42;

$color-lightgrey-500:   #8D978C;

$color-deepgrey-500:    #616962;

$white:                 #FFFFFF;
$grey-1-50:             #F7F8F7;
$grey-1-100:            #E8EAE8;
$grey-1-300:            #C6CBC6;
$grey-1-500:            #B6C0B5;
$grey-1-700:            #8D978C;
$grey-1-900:            #616962;
$black:                 #000000;

// $support-info-50:       #E5F5FF;
// $support-info-100:      #A7DAFF;
// $support-info-300:      #63BEFF;
$support-info-500:      $color-navy-500;
// $support-info-700:      #0B5499;
// $support-info-900:      #073A69;

$support-success-50:    #F2FBFA;
$support-success-100:   #DEEFEE;
// $support-success-300:   #7DE74B;
$support-success-500:   #0E7C76;
// $support-success-700:   #0C5515;
// $support-success-900:   #224412;

$support-error-50:      #FEF6F5;
$support-error-100:     #F4DDDC;
// $support-error-300:     #FF5D5D;
$support-error-500:     #BF4C43;
// $support-error-700:     #8A1616;
// $support-error-900:     #5C1818;

$support-warning-50:    #FFFFF7;
$support-warning-100:   #DEA64C;
// $support-warning-300:   #FFD56A;
$support-warning-500:   #9E660C;
// $support-warning-700:   #926E13;
// $support-warning-900:   #65501B;
/* stylelint-enable color-no-hex */

// Layout colours
$bg-theme-1: $grey-1-50;
$bg-theme-2: $color-1-50;
// TBD not in use yet in Figma
// $page-bg:                 $white;
// $dark-accent-bg:          $color-1-100;
// $light-accent-bg:         $color-2-50;
// $light-grey-bg:           $grey-1-50;
// $global-header:           $color-1-500;
// $img-load-bg:             $color-2-50;
// $border-color:            $grey-1-100;

// Background Themes
@mixin bg-theme-1 {
    background-color: $bg-theme-1;
}

.bg-theme-1 {
    @include bg-theme-1;
}

@mixin bg-theme-2 {
    background-color: $bg-theme-2;
}

.bg-theme-2 {
    @include bg-theme-2;
}


// Colour maps - USED FOR STYLE GUIDE ONLY
// NOTE: please update templates/styleguide/core-elements/colours.hbs if adding/removing colours during project setup
$primary-colors: (
    color-1-50: $color-1-50,
    color-1-100: $color-1-100,
    color-1-300: $color-1-300,
    color-1-500: $color-1-500,
    // color-1-700: $color-1-700,
    // color-1-900: $color-1-900,
    
    // color-2-50: $color-2-50,
    // color-2-100: $color-2-100,
    // color-2-300: $color-2-300,
    color-2-500: $color-2-500,
    // color-2-700: $color-2-700,
    // color-2-900: $color-2-900,
    color-gold-500: $color-gold-500,
    color-dustypink-500: $color-dustypink-500,
    color-rust-500: $color-rust-500,
    color-plum-500: $color-plum-500,
    color-dustyblue-500: $color-dustyblue-500,
    color-navy-500: $color-navy-500,
    color-lightgreen-300: $color-lightgreen-300,
    color-lightgreen-500: $color-lightgreen-500,
    color-khaki-500: $color-khaki-500,
    color-lightgrey-500: $color-lightgrey-500,
    color-deepgrey-500: $color-deepgrey-500,
);

$shades: (
    "white": $white,
    grey-1-50: $grey-1-50,
    grey-1-100: $grey-1-100,
    grey-1-300: $grey-1-300,
    grey-1-500: $grey-1-500,
    grey-1-700: $grey-1-700,
    grey-1-900: $grey-1-900,
    "black": $black,
);

$alerts: (
    // info-50: $support-info-50,
    // info-100: $support-info-100,
    // info-300: $support-info-300,
    info-500: $support-info-500,
    // info-700: $support-info-700,
    // info-900: $support-info-900,
    // success-50: $support-success-50,
    // success-100: $support-success-100,
    // success-300: $support-success-300,
    success-500: $support-success-500,
    // success-700: $support-success-700,
    // success-900: $support-success-900,
    // error-50: $support-error-50,
    // error-100: $support-error-100,
    // error-300: $support-error-300,
    error-500: $support-error-500,
    // error-700: $support-error-700,
    // error-900: $support-error-900,
    // warning-50: $support-warning-50,
    // warning-100: $support-warning-100,
    // warning-300: $support-warning-300,
    warning-500: $support-warning-500,
    // warning-700: $support-warning-700,
    // warning-900: $support-warning-900,
);
