@use "../../1-core" as *;
@use "./profile-thumbnail";

.profile-carousel-block {
    margin: $spacing-10 0;
    background-color: var(--theme-background);
    padding: $spacing-10 0;

    .grid-copy {
        @include col(4);
        display: flex;
        flex-direction: column;
        margin-bottom: $spacing-6;
        color: var(--theme-text-prominent);
        text-align: center;
    }

    .grid-copy-title {
        @include text-4xl;
        @include text-black;
        margin-bottom: $spacing-4;
    }

    .grid-copy-desc {
        @include text-md;
    }

    .profile-carousel-container {
        width: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column-reverse;
        gap: $spacing-5;
    }

    .swiper-main {
        width: 100%;
        overflow: hidden;

        .video-embed-play-btn {
            .svg-icon {
                path:first-child {
                    fill-opacity: 0.8;
                }

                path:nth-child(2) {
                    color: $black;
                }
            }
        }
    }

    .swiper-slide-image {
        border-radius: $spacing-2;
    }

    .swiper-thumb {
        .swiper-wrapper {
            scroll-behavior: smooth;
            overflow: auto;

            // hide scrollbar
            -ms-overflow-style: none;// Internet Explorer 10+
            scrollbar-width: none;// Firefox

            &::-webkit-scrollbar {
                display: none;// Safari and Chrome
            }
        }

        .swiper-controls {
            display: grid;
            grid-template-columns: 1fr 4fr 1fr;
            gap: $spacing-3;
            align-items: center;
            margin-top: $spacing-5;

            p {
                @include text-xs;
                color: var(--theme-text-prominent);
                width: 100px;
            }

            .swiper-controls-divider {
                width: 100%;
                height: 1px;
                background-color: var(--theme-text-prominent);
            }

            .swiper-controls-btns {
                --swiper-navigation-size: #{$spacing-8};
                --swiper-navigation-icon-size: #{$spacing-5};
                display: flex;
                height: var(--swiper-navigation-size);

                .swiper-button-prev,
                .swiper-button-next {
                    display: none;
                }

                .swiper-button-next {
                    margin-left: $spacing-6;
                }

                .vertical-button-prev,
                .vertical-button-next {
                    &.vertical-button-disabled {
                        opacity: 0.35;
                        cursor: auto;
                        pointer-events: none;
                    }
                }

                .vertical-button-next {
                    margin-left: $spacing-2;
                }

                .swiper-button-prev,
                .swiper-button-next,
                .vertical-button-prev,
                .vertical-button-next {
                    position: relative;
                    width: var(--swiper-navigation-size);
                    height: var(--swiper-navigation-size);
                    border: 2px solid var(--theme-text-prominent);
                    border-radius: var(--swiper-navigation-size);
                    cursor: pointer;
                }

                .swiper-button-prev::after,
                .swiper-button-next::after,
                .vertical-button-prev::after,
                .vertical-button-next::after {
                    content: '';
                    font-family: inherit;
                    position: absolute;
                    inset: 0;
                    background-color: var(--theme-text-prominent);
                    mask-position: center center;
                    mask-repeat: no-repeat;
                    mask-size: var(--swiper-navigation-icon-size) var(--swiper-navigation-icon-size);
                }

                .swiper-button-prev::after {
                    mask-image: url('/assets/icons/chevron-left.svg');
                }

                .swiper-button-next::after {
                    mask-image: url('/assets/icons/chevron-right.svg');
                }

                .vertical-button-prev::after {
                    mask-image: url('/assets/icons/chevron-up.svg');
                }

                .vertical-button-next::after {
                    mask-image: url('/assets/icons/chevron-down.svg');
                }
            }
        }
    }

    .swiper-slide {
        height: auto !important;
    }

    .swiper-slide .video-embed {
        border: none;

        iframe {
            border-radius: $spacing-2;
        }

        .video-embed-img-container {
            border-radius: $spacing-2;
            overflow: hidden;
        }

        .video-embed-overlay {
            border-radius: $spacing-2;
            background: linear-gradient(180deg, rgba(0, 0, 0, 0) 38.89%, rgba(0, 0, 0, 0.5) 100%);
        }

        .video-embed-info {
            padding: 0;
            margin-top: $spacing-4;

            .info-heading-row,
            .video-embed-info-separator {
                display: none;
            }

            .video-caption {
                color: var(--theme-text-core);
            }
        }
    }


    .swiper-main {
        margin-bottom: $spacing-4;
        position: relative;
    }

    .swiper-thumb.hidden,
    .swiper-controls.hidden {
        display: none;
    }
}

@media (min-width: $screen-sm) {
    .profile-carousel-block {
        .swiper-slide .video-embed {
            .video-embed-info {
                padding: $spacing-5 $spacing-7;
                margin: unset;

                .video-caption {
                    color: $white;
                }
            }
        }
    }
}


@media (min-width: $screen-md) {
    .profile-carousel-block {
        margin: $spacing-12 0;

        .grid-copy {
            margin-bottom: $spacing-8;
        }

        .swiper-main {
            margin-bottom: $spacing-5;
        }

        .swiper-thumb {
            .swiper-wrapper {
                overflow: visible;
                height: unset;
            }

            .swiper-controls {
                .swiper-controls-btns {
                    .vertical-button-prev,
                    .vertical-button-next {
                        display: none;
                    }

                    .swiper-button-prev,
                    .swiper-button-next {
                        display: block;
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-lg) {
    .profile-carousel-block {
        .grid-copy {
            margin-bottom: $spacing-9;
            text-align: left;
        }

        .grid-copy-title {
            @include text-5xl;
        }

        .profile-carousel-container {
            display: grid;
            grid-template-columns: 1fr 2fr;

            &.one-slide {
                display: flex;
            }
        }

        .swiper-thumb {
            .swiper-wrapper {
                overflow: auto;
            }

            .swiper-controls {
                .swiper-controls-btns {
                    .vertical-button-prev,
                    .vertical-button-next {
                        display: block;
                    }

                    .swiper-button-prev,
                    .swiper-button-next {
                        display: none;
                    }
                }
            }
        }
    }
}

// remove transform on vertical carousel (mobile $ desktop)
// to prevent unwanted transformation on swipper wrapper during slide click
@media (min-width: $screen-lg), (max-width: $screen-md) {
    .profile-carousel-block {
        .swiper-thumb {
            .swiper-wrapper {
                transform: none !important;
            }
        }
    }
}
