@use "font-stacks" as *;
@use "text-styles" as *;
@use "../colours" as *;

// Base Site Setup
// =======================================================================
body {
    // font-family: $font-poppins;
    font-family: $font-cerapro;
    @include text-base;
    color: $black;
    background-color: $white;
    -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape */

    // &.no-overflow {
    //     overflow: hidden;
    // }

    &.theme-form {
        background-color: $color-1-50;
    }
}

p {
    @include text-base;
}
