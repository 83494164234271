@use "../../1-core" as *;

@mixin dmm-link-color {
    color: $black;
    outline: 2px solid transparent;
    outline-offset: 2px;
    text-decoration: none;

    &:hover {
        color: $color-1-500;

        .nav-item-icon .svg-icon {
            color: $color-1-500;
        }
    }

    &:active {
        color: $color-dustyblue-500;

        .nav-item-icon .svg-icon {
            color: $grey-1-700;
        }
    }

    &:focus-visible {
        outline: 2px solid black;

        .nav-item-icon .svg-icon {
            color: $black;
        }
    }
}

.desktop-mega-menu {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 5;
    display: none;
    background-color: $white;
    border-top: 1px solid $grey-1-300;
    @include light-shadow(2xl);

    .mega-menu-container {
        background-image: linear-gradient(to right, $white 50%, $grey-1-50);
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        padding: 0 $spacing-10;
    }

    .mega-menu-column-container {
        padding: $spacing-8;
    }

    .mega-menu-column-container.main {
        grid-column: span 3;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: $spacing-8;
        background-color: $white;

        .mega-menu-overview {
            @include col(3);
        }
    }

    .mega-menu-overview-link {
        @include text-2xl;
        @include text-normal;
        display: flex;
        align-items: center;
        @include dmm-link-color;

        .mega-menu-overview-icon {
            margin-left: $spacing-3;
        }
    }

    .mega-menu-column-container.sidebar {
        background-color: $grey-1-50;
        display: flex;
        flex-direction: column;

        .mega-menu-overview {
            height: $spacing-6 + $spacing-1; // same as menu overview line height (text-2xl line height is 36px)
            margin-bottom: $spacing-8; // same .mega-menu-container.main gap = $spacing-8 = 48px
        }
    }
}

.active-nav-item {
    .desktop-mega-menu {
        display: block;
        max-height: calc(100vh - 146px);
        overflow: auto;
    }
}
