@use "../../1-core" as *;
@use "../../3-components/TimelineItem/timeline-item" as *;


.timeline-block {
    // margin: $spacing-8 0 $spacing-8;
    @include global-section-vertical-spacing;

    .container {
        @include grid();
    }

    .grid-copy {
        @include col(4);
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-bottom: $spacing-8 - $spacing-5;
    }

    .grid-copy-title {
        @include text-4xl;
        @include text-black;
        margin-bottom: $spacing-4;
    }

    .grid-copy-desc {
        @include text-md;
    }

    .grid-tile-container {
        position: relative;
        @include col(4);
    }

    .tlblk-vertical-line {
        position: absolute;
        border-right: $timeline-dot-line;
        width: 1px;
        top: 0;
        bottom: 0;
        left: 16px;
        transform: translateX(-1px);
        margin-left: calc(-1 * ((#{$timeline-dot-size}) / 2 ));
    }
}

@media (min-width: $screen-sm) {
    .timeline-block {
        // margin: $spacing-9 0 $spacing-9;

        .grid-copy {
            @include col(6, 1);
            margin-bottom: $spacing-9 - $spacing-5;
        }

        .grid-tile-container {
            @include col(8);
        }
    }
}

@media (min-width: $screen-lg) {
    .timeline-block {
        // margin: $spacing-10 0 $spacing-10;

        .grid-copy {
            @include col(8, 2);
            margin-bottom: $spacing-10 - $spacing-5;
        }

        .grid-copy-title {
            @include text-5xl;
        }

        .grid-tile-container {
            @include col(10, 1);
        }
    }
}

@media (min-width: $screen-xl) {
    .timeline-block {
        .tlblk-vertical-line {
            left: 50%;
        }

        .grid-tile-container {
            @include col(12);
        }

        .timeline-item-row:not(:first-of-type) {
            margin-top: calc((-1 * (#{$timeline-row-min-hieght} / 2)) + 32px);
        }
    }
}
