@use "../../1-core" as *;
// @use "../../1-core/typography/list-styles" as *;
// @use "../../1-core/typography/link-styles" as *;

@forward "./location-card-rl";
@forward "./location-card-rac";
@forward "./location-card-church";
@forward "./location-card-hc";
@forward "./location-card-fst";
@forward "./location-card-fam";

// $location-card-action-button-side-padding:      20px;
$location-card-min-height-desktop:              660px;

.location-card {
    border-radius: $spacing-2;
    border: 1px solid $grey-1-300;
    @include grid();
    margin-bottom: $spacing-6;
    background-color: $white;

    .location-card-image-container {
        @include col(4);
        border-radius: $spacing-2 $spacing-2 0 0;

        --aspect-ratio: 16/10;

        figure picture {
            height: 0;
            width: 100%;
            padding-bottom: calc(100% / (var(--aspect-ratio)));
            position: relative;
            background-color: transparent;
        }

        img {
            width: 100%;
            clip-path: url(#clip-path-horizontal-image-mobile);
        }
    }

    .location-content {
        @include col(4);
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        padding: $spacing-6;
        padding-top: $spacing-2;

        .location-content-distance {
            @include text-sm;
            margin-bottom: $spacing-2;

            .distance-number {
                @include text-bold;
            }

            .distance-green {
                color: $support-success-500;
            }

            .distance-red {
                color: $support-error-500;
            }
        }

        // .location-rl-ribbon {
        //     @include text-2xs;
        //     @include text-black;
        //     background-color: $color-gold-500;
        //     color: $black;
        //     padding: $spacing-2 $spacing-4;
        //     border-radius: $spacing-2;
        //     margin-bottom: $spacing-5;
        // }

        .location-content-title {
            @include text-xl;
            @include text-bold;
            margin-bottom: $spacing-5;
        }

        .location-name-link {
            @include text-link;
            text-decoration: none;

            --text-link-color: #{$black};

            &:hover,
            &:active,
            &:focus-visible {
                --text-link-color: #{$color-1-500};
            }
        }

        // .location-content-chip-container {
        //     margin-bottom: $spacing-4;

        //     .chip-component {
        //         margin-bottom: $spacing-2;
        //         margin-right: $spacing-2;
        //     }
        // }

        .location-content-desc {
            margin-bottom: $spacing-5;
            @include text-sm;
        }

        // .location-content-action-container {
        //     display: flex;
        //     margin-bottom: $spacing-5;

        //     .action-button {
        //         --btn-side-padding: #{$location-card-action-button-side-padding};
        //         height: unset;
        //         min-height: var(--btn-height);
        //     }

        //     .action-button:not(:last-of-type) {
        //         margin-right: $spacing-4;
        //     }
        // }

        // .location-info {
        //     @include list-reset;
        //     display: grid;
        //     grid-template-columns: 1fr;
        // }

        // ul.location-info > li.location-info-item {
        //     margin-bottom: $spacing-2;
        // }

        // .location-info-item {
        //     @include text-sm;
        //     display: flex;
        //     flex-wrap: nowrap;
        //     align-items: center;

        //     .svg-icon-wrapper {
        //         margin-right: $spacing-4;
        //     }

        //     a {
        //         @include text-link;
        //     }
        // }

        ul.location-info > li.location-info-item > a {
            line-break: anywhere;
        }
    }
}


@media (min-width: $screen-sm) {
    .location-card {
        .location-card-image-container {
            @include col(8);
        }

        .location-content {
            @include col(8);
            padding: $spacing-9;
            padding-top: $spacing-7;

            .location-content-title {
                @include text-2xl;
            }

            // .location-content-action-container {
            //     .action-button {
            //         --btn-side-padding: #{$spacing-5};
            //     }
            // }

            // .location-info {
            //     grid-template-columns: repeat(2, 1fr);
            // }

            // .location-info-item {
            //     flex-wrap: wrap;

            //     &:first-of-type {
            //         @include col(2);
            //     }
            // }
        }
    }
}

@media (min-width: $screen-lg) {
    .location-card {
        min-height: $location-card-min-height-desktop;

        .location-card-image-container {
            @include col(5);
            border-radius: $spacing-2 0 0 $spacing-2;

            --aspect-ratio: initial;

            figure picture {
                height: 100%;
                padding-bottom: 0;
            }

            img {
                height: 100%;
                clip-path: url(#clip-path-horizontal-image-desktop-curve-right);
            }
        }

        .location-content {
            @include col(7);
            padding: $spacing-9;
            padding-left: $spacing-7;

            .location-content-title {
                @include text-3xl;
            }
        }
    }
}
