@use "../../1-core" as *;

.related-articles-block {
    background-color: $grey-1-50;
    padding: $spacing-10 0;

    .container {
        @include grid();
    }

    .grid-copy {
        @include col(4);
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-bottom: $spacing-6;
    }

    .grid-copy-title {
        @include text-4xl;
        @include text-black;
        margin-bottom: $spacing-4;
    }

    .grid-copy-desc {
        @include text-lg;
        margin-bottom: $spacing-2;
    }

    .grid-tile-container {
        @include col(4);
        display: flex;
        flex-direction: column;
        gap: $spacing-5;
    }
}

@media (min-width: $screen-sm) {
    .related-articles-block {
        padding: $spacing-12 0;

        .grid-copy {
            @include col(6, 1);
        }

        .grid-tile-container {
            @include col(8);
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@media (min-width: $screen-lg) {
    .related-articles-block {
        .grid-copy {
            @include col(6, 3);
        }

        .grid-copy-title {
            @include text-5xl;
        }

        .grid-copy-desc {
            @include text-xl;
        }

        .grid-tile-container {
            @include col(12);
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
