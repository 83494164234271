@use "../../1-core" as *;

.thin-header.thin-header-search {
    .container {
        align-items: center;
    }

    .thin-header-copy {
        @include col(4);
        flex-direction: column;
        align-items: center;
    }

    .thin-header-title,
    .thin-header-search-container {
        flex: 1;
    }

    .thin-header-title {
        @include text-2xl;
        margin-bottom: 24px;
    }

    .thin-header-separator {
        width: 1px;
        height: 0;
        display: none;
    }

    .thin-header-search-container {
        display: flex;
        align-items: stretch;
        flex-direction: column;
        width: 100%;

        label {
            color: var(--theme-text-prominent);
        }

        .thin-header-search-button {
            margin-top: 16px;
            @include transition();
            visibility: hidden;

            &.visible {
                visibility: visible;
            }
        }
    }
}

@media (min-width: $screen-sm) {
    .thin-header.thin-header-search {
        .thin-header-copy {
            @include col(6, 1);
            align-items: flex-start;
        }

        .thin-header-title {
            @include text-3xl;
        }

        .thin-header-search-container {
            align-items: flex-end;
            flex-direction: row;

            .form-element {
                margin-right: 16px;
                flex: 1;
            }

            // .input-autocomplete-wrapper {
            //     margin-right: 0;
            // }

            .thin-header-search-button {
                margin-top: 0;
            }
        }
    }
}

@media (min-width: $screen-lg) {
    .thin-header.thin-header-search {
        .thin-header-copy {
            @include col(10, 1);
            flex-direction: row;
            align-items: center;
        }

        .thin-header-title {
            @include text-4xl;
            margin-bottom: 0;
        }

        .thin-header-separator {
            height: 200px;
            border-left: 1px solid var(--theme-divider);
            margin: 0 64px;
            display: block;
        }
    }
}
