@use "../../../1-core/" as *;

.header-search-result a {
    padding: $spacing-2 $spacing-4;
    display: flex;
    width: 100%;
    align-items: flex-start;
    color: $black;
    gap: $spacing-2;
    @include transition(background-color);
    text-decoration: none;

    &:hover,
    &:focus {
        background-color: $grey-1-50;
        @include transition(background-color, 200ms);
    }

    .svg-icon {
        color: $grey-1-500;
    }
}
