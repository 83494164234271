@use "../../1-core" as *;

$ctapro-desktop-height: 615px;

.cta-prominent {
    border-radius: $spacing-2;

    .ctapro-copy-container {
        background-color: var(--theme-background);
        position: relative;
        z-index: 2;
        display: flex;
        align-items: center;
        padding: #{$spacing-8 + $spacing-2} $spacing-6 $spacing-6;
        margin-top: -$spacing-9;
        clip-path: url(#clip-path-cta-pro-mobile);
    }

    .ctapro-image-container {
        figure picture,
        img {
            width: 100%;
        }
    }

    .ctapro-copy-heading {
        @include text-2xl;
        @include text-black;
        color: var(--theme-text-prominent);
        margin-bottom: $spacing-4;
    }

    .ctapro-copy-subheading {
        @include text-sm;
        color: var(--theme-text-prominent);
        margin-bottom: $spacing-6;
    }

    .ctapro-action-button {
        display: inline-flex;
    }
}

@media (min-width: $screen-sm) {
    .cta-prominent {
        .ctapro-copy-container {
            background-color: var(--theme-background);
            padding: $spacing-10 $spacing-9 $spacing-6;
            margin-top: -$spacing-12;
            clip-path: url(#clip-path-cta-pro-tablet);
        }

        .ctapro-copy-heading {
            @include text-4xl;
        }
    }
}

@media (min-width: $screen-lg) {
    .cta-prominent {
        @include grid();
        position: relative;
        min-height: $ctapro-desktop-height;
        border-radius: $spacing-2;

        .ctapro-image-container {
            position: absolute;
            right: 0;
            top: 0;
            left: 40%;
            bottom: 0;
            // width: calc(var(--aspect-ratio) * #{$ctapro-desktop-height});
            z-index: 1;
            border-radius: 0 $spacing-2 $spacing-2 0;

            // --aspect-ratio: 4/3;

            figure picture,
            img {
                height: 100%;
            }
        }

        .ctapro-copy-container {
            @include col(7);
            padding: $spacing-9;
            margin-top: 0;
            clip-path: url(#clip-path-cta-pro-desktop);
            border-radius: $spacing-2 0 0 $spacing-2;
            display: grid;
            grid-template-columns: repeat(7, 1fr);
            gap: 24px;
        }

        .ctapro-copy {
            @include col(5,1);
        }

        .ctapro-copy-heading {
            @include text-5xl;
        }

        .ctapro-copy-subheading {
            @include text-md;
        }
    }
}
