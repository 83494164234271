@use "../../1-core" as *;

.profile-thumbnail-template {
    display: none;
}

.swiper-thumb .swiper-slide.profile-thumbnail {
    display: grid;
    grid-template-columns: 1.3fr 2fr;
    gap: $spacing-2;
    background-color: var(--theme-button-primary);
    border-radius: $spacing-1;
    overflow: hidden;
    cursor: pointer;

    &:hover {
        .profile-thumbnail-image-container {
            transform: scale(1.1);
        }

        .profile-thumbnail-content {
            .profile-thumbnail-play-icon::after {
                opacity: 1;
            }
        }
    }

    .profile-thumbnail-image-container {
        @include transition();

        figure picture {
            height: 100%;
            padding-bottom: 0;
            background-color: transparent;
        }

        img {
            height: 100%;
            clip-path: url(#clip-path-horizontal-image-desktop-curve-right);
        }
    }

    .profile-thumbnail-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: $spacing-5;

        .profile-thumbnail-heading {
            @include text-lg;
            @include text-black;
            color: var(--theme-button-primary-label);
        }

        .profile-thumbnail-subheading {
            @include text-2xs;
            text-transform: uppercase;
            color: var(--theme-button-primary-label);
            max-width: 85%;
        }

        .profile-thumbnail-play-icon {
            position: absolute;
            bottom: $spacing-4;
            right: $spacing-4;
            width: $spacing-5;
            height: $spacing-5;

            &::after {
                content: '';
                font-family: inherit;
                position: absolute;
                inset: 0;
                background-color: $white;
                opacity: 0.3;
                mask-position: center center;
                mask-repeat: no-repeat;
                mask-size: $spacing-5;
                mask-image: url('/assets/icons/play-circle.svg');
            }
        }
    }

    .video-embed-container {
        .video-embed-img-container,
        .video-embed-img-container img {
            border-radius: $spacing-2;
        }

        .video-embed-overlay {
            display: none;
        }

        .video-embed-info {
            display: none;
            opacity: 0;
        }

        .video-embed-play-btn {
            margin-top: calc(-1 * #{$spacing-6} / 2);
            margin-left: calc(-1 * #{$spacing-6} / 2);
            cursor: default;
            pointer-events: none;

            .svg-icon {
                width: $spacing-6;
                height: $spacing-6;
            }
        }
    }

    &.swiper-slide-thumb-active {
        background-color: var(--theme-button-primary-hover);

        .profile-thumbnail-content {
            .profile-thumbnail-heading,
            .profile-thumbnail-subheading {
                color: var(--theme-button-primary-label-hover);
            }

            .profile-thumbnail-play-icon {
                &::after {
                    background-color: $black;
                    opacity: 1;
                }
            }
        }
    }
}
