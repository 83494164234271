@use "../../1-core" as *;

$ctap-height-desktop: 302px;
$ctap-height-tablet: 348px;
$ctap-ornament-width-desktop: 414px;
$ctap-ornament-height-desktop: 230px;
$ctap-ornament-width-tablet: 300px;
$ctap-ornament-height-tablet: 230px;
$ctap-ornament-width-mobile: 343px;
$ctap-ornament-height-mobile: 144px;


.cta-primary {
    // background-color: $color-2-500;
    background-color: var(--theme-background);
    border-radius: $spacing-2;
    display: flex;
    flex-direction: column;

    .ctap-image-container {
        border-top-left-radius: $spacing-2;
        border-top-right-radius: $spacing-2;
        border-bottom-left-radius: $spacing-2;

        figure picture {
            background-color: transparent;
        }

        img {
            clip-path: url(#clip-path-cta-primary-mobile);
        }
    }

    .ctap-content-wrapper {
        flex: 1;
        display: flex;
        padding: 0 $spacing-6;
        align-items: center;
        justify-content: space-around;
    }

    .ctap-copy {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: $spacing-2 0 $spacing-6;
    }

    .ctap-heading {
        @include text-2xl;
        @include text-black;
        color: var(--theme-text-prominent);
        margin-bottom: $spacing-4;
    }

    .ctap-subheading {
        @include text-sm;
        color: var(--theme-text-prominent);
        //$white;
        margin-bottom: $spacing-6;
    }

    // .ctap-action-button {
    //     // --theme-button-primary-hover: #{$white};
    //     // --theme-button-primary-border-hover: #{$white};
    //     // --theme-button-primary-label-hover: var(--theme-background);
    // }

    &.no-image {
        flex-direction: column-reverse;

        .ctap-shape-ornament {
            align-self: flex-end;
            background-color: var(--theme-background-accent);
            max-width: 100%;
            width: $ctap-ornament-width-mobile;
            height: $ctap-ornament-height-mobile;
            border-radius: 0 $spacing-2 0 0;
            clip-path: url(#clip-path-cta-primary-ornament-mobile-fixed);
        }
    }
}


@media (min-width: $screen-sm) {
    .cta-primary {
        flex-direction: row;
        min-height: $ctap-height-tablet;

        .ctap-image-container {
            width: 40%;
            max-width: 300px;
            border-top-right-radius: 0;

            img {
                clip-path: url(#clip-path-cta-primary-mobile);
            }
        }

        .ctap-content-wrapper {
            padding: 0 $spacing-7 0 $spacing-6;
        }

        .ctap-copy {
            margin: $spacing-6 0;
        }

        &.no-image {
            flex-direction: row;

            .ctap-content-wrapper {
                padding: 0  $spacing-6 0 $spacing-9;
            }

            .ctap-copy {
                margin: $spacing-7 0;
            }

            .ctap-shape-ornament {
                align-self: initial;
                width: $ctap-ornament-width-tablet;
                height: $ctap-ornament-height-tablet;
                clip-path: url(#clip-path-cta-primary-ornament-tablet);
            }
        }
    }
}

@media (min-width: $screen-md) {
    .cta-primary {
        .ctap-image-container {
            figure picture {
                max-width: $ctap-height-tablet;
            }
        }
    }
}

@media (min-width: $screen-lg) {
    .cta-primary {
        min-height: $ctap-height-desktop;

        .ctap-image-container {
            width: 40%;
            max-width: $ctap-height-desktop;

            img {
                clip-path: url(#clip-path-cta-primary-mobile);
            }
        }

        .ctap-content-wrapper {
            padding: 0 $spacing-6;
        }

        .ctap-heading {
            @include text-3xl;
        }

        .ctap-subheading {
            @include text-md;
        }

        &.no-image {
            .ctap-content-wrapper {
                padding: 0 $spacing-9;
            }

            .ctap-copy {
                margin: $spacing-6 0;
            }

            .ctap-shape-ornament {
                width: $ctap-ornament-width-desktop;
                height: $ctap-ornament-height-desktop;
                clip-path: url(#clip-path-cta-primary-ornament-desktop);
            }
        }
    }
}

@media (min-width: $screen-xl) {
    .cta-primary {
        .ctap-image-container {
            img {
                clip-path: url(#clip-path-cta-primary-desktop);
            }
        }
    }
}
