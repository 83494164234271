@use "../../1-core" as *;

.property-grid-block {
    // margin: $spacing-8 0 $spacing-8;
    @include global-section-vertical-spacing;

    .container {
        @include grid();
    }

    .grid-copy {
        @include col(4);
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-bottom: $spacing-6;
    }

    .grid-copy-title {
        @include text-3xl;
        @include text-black;
        margin-bottom: $spacing-4;
    }

    .grid-copy-desc {
        @include text-md;
    }

    .grid-tile-container {
        @include col(4);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: $spacing-5;
    }
}

@media (min-width: $screen-sm) {
    .property-grid-block {
        .grid-copy {
            @include col(6, 1);
            margin-bottom: $spacing-7;
        }

        .grid-copy-title {
            @include text-4xl;
        }

        .grid-tile-container {
            @include col(8);
            // display: grid;
            // grid-template-columns: repeat(2, 1fr);
        }
    }
}

@media (min-width: $screen-lg) {
    .property-grid-block {
        .grid-copy {
            @include col(8, 2);
        }

        .grid-copy-title {
            @include text-5xl;
        }

        .grid-tile-container {
            @include col(12);
        }

        &.room-card-desktop-split {
            .grid-tile-container.room-card-tile-container {
                display: grid;
                grid-template-columns: repeat(2, 1fr);

                .room-card {
                    .rmcr-content {
                        padding: $spacing-8 $spacing-8 $spacing-8 $spacing-5;
                    }
                }
            }
        }
    }
}
