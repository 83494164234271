@use '../../../1-core/' as *;

$algolia-logo-height    : 28px;

.powered-by-algolia-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: $spacing-7;
    @include text-md;

    > span {
        margin-right: $spacing-3;
    }

    .algolia-logo {
        height: $algolia-logo-height;
    }
}
