@use '../../1-core' as *;
@use '../../3-components/Buttons/buttons' as *;
@use '../../1-core/FormElements/form-element-wrapper' as *;

.ais-SearchBox-form {
    position: relative;

    .ais-SearchBox-input {
        padding-right: $element-with-icon-side-padding;
        height: $spacing-8;
        border-radius: 120px;
        @include transition(width);
        position: relative;
        color: $black;
    }

    .svg-icon {
        color: $black;
        width: $spacing-5;
        height: $spacing-5;
    }

    .ais-SearchBox-input[type="search"]::-webkit-search-cancel-button {
        display: none;
    }

    .ais-SearchBox-submit {
        @include button-reset;
        position: absolute;
        top: 12px;
        right: 16px;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover .svg-icon {
            color: $color-1-500;
        }

        &:focus {
            outline: 2px solid $black;
        }
    }

    .ais-SearchBox-reset {
        @include button-reset;
        position: absolute;
        top: 12px;
        right: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: $spacing-3;
        padding-right: $spacing-3;
        border-right: 1px solid $grey-1-100;

        &[hidden] {
            display: none;
        }

        &:hover .svg-icon {
            color: $color-1-500;
        }

        &:focus .svg-icon-wrapper {
            outline: 2px solid $black;
        }
    }
}
