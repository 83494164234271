@use "../colours" as *;

.theme-teal .use-theme,
.theme-teal.force-theme {
    // text
    --theme-text-prominent: #{$black};
    --theme-text-core: #{$black};
    --theme-text-reversed: #{$color-2-500};
    --theme-text-deemphasised: #{$black}7F;

    // text link
    --theme-link-text: #{$black};
    --theme-link-text-hover: #{$black}7F;
    --theme-link-text-active: #{$color-2-500};

    // background
    --theme-background: #{$color-1-500};
    --theme-background-accent: #{$color-2-500};
    --theme-background-reversed: #{$white};

    // icon
    --theme-icon: #{$black};
    --theme-icon-reversed: #{$color-2-500};
    --theme-icon-deemphasised: #{$white}4C;

    // border
    // --theme-border: #{$color-2-500};
    // --theme-border-deemphasised: #{$black};
    --theme-divider: #{$color-2-500};

    // button - primary
    --theme-button-primary: #{$color-2-500};
    --theme-button-primary-border: #{$color-2-500};
    --theme-button-primary-label: #{$white};
    --theme-button-primary-hover: #{$white};
    --theme-button-primary-border-hover: #{$white};
    --theme-button-primary-label-hover: #{$color-2-500};
    --theme-button-primary-active: #{$black};
    --theme-button-primary-border-active: #{$black};
    --theme-button-primary-label-active: #{$white};

    // button - secondary
    --theme-button-secondary: transparent;
    --theme-button-secondary-border: #{$white}33;
    --theme-button-secondary-label: #{$black};
    --theme-button-secondary-hover: transparent;
    --theme-button-secondary-border-hover: #{$black};
    --theme-button-secondary-label-hover: #{$black};
    --theme-button-secondary-active: transparent;
    --theme-button-secondary-border-active: #{$color-2-500};
    --theme-button-secondary-label-active: #{$color-2-500};

    // button - tertiary
    --theme-button-tertiary: transparent;
    --theme-button-tertiary-border: transparent;
    --theme-button-tertiary-label: #{$black};
    --theme-button-tertiary-hover: transparent;
    --theme-button-tertiary-border-hover: transparent;
    --theme-button-tertiary-label-hover: #{$black};
    --theme-button-tertiary-active: transparent;
    --theme-button-tertiary-border-active: transparent;
    --theme-button-tertiary-label-active: #{$color-2-500};

    // button - disabled
    --theme-button-disabled: #{$white}19;
    --theme-button-disabled-border: #{$white}19;
    --theme-button-disabled-label: #{$black}4C;

    // form
    // --theme-form-label: #{$white};
    // --theme-form-selectors: #{$color-1-500};
    // --theme-form-selectors-hover: #{$color-1-500}19;
}
