@use "../colours" as *;
@use "../animations" as *;
@use "text-styles" as *;
@use "../spacing-scale" as *;

// Links
// =======================================================================
@mixin text-link {
    text-decoration: underline;
    display: inline-block;
    cursor: pointer;
    color: var(--text-link-color);
    @include transition(color);
    // outline: var(--text-link-outline);
    // outline-offset: var(--text-link-outline-offset);
    // box-shadow: 0 2px 0 $color-1-300;
    // outline: none;
    text-underline-offset: $spacing-2;
    text-decoration-thickness: 2px;

    // &:not(.has-icon) {
    //     box-shadow: 0 1px 0 var(--text-link-color);
    // }

    &.has-icon {
        display: inline-flex;
        align-items: center;
    }

    &:hover {
        // box-shadow: 0 0 0 $color-1-300;
        --text-link-color: var(--theme-link-text-hover, #{$black});
        @include transition(all, 200ms);
    }

    &:active {
        // box-shadow: 0 0 0 $color-1-300;
        --text-link-color: var(--theme-link-text-active, #{$grey-1-700});
        @include transition(all, 200ms);
    }

    &:focus-visible {
        // box-shadow: 0 0 0 $color-1-300;
        // --text-link-color: #{$grey-1-700};
        --text-link-outline: 2px solid var(--theme-link-text-hover, #{$black});
        --text-link-outline-offset: 2px;
        --text-link-color: var(--theme-link-text-hover, #{$black});
        @include transition(all, 200ms);
    }

    &:not([href]) {
        cursor: not-allowed;

        --text-link-color: #{$grey-1-300};
    }

    --text-link-color: var(--theme-link-text, #{$color-1-500});
    --text-link-outline: none;
}

.text-link {
    @include text-link;
}

@mixin footer-link {
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    color: $white;

    &:hover {
        color: $color-1-500;
    }

    &:active {
        color: $color-dustyblue-500;
    }

    &:focus-visible {
        color: $white;
        outline: 2px solid $white;
        outline-offset: 2px;
    }

    // &:focus:not(:active) {
    //     color: $white;
    //     outline: 2px solid $white;
    //     outline-offset: 2px;
    // }
}
