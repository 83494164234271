@use "../../1-core" as *;
@use '../../scss/helpers' as *;

$element-size-lg:                  $spacing-9;
$element-size-md:                  $spacing-8;
$element-size-sm:                  $spacing-7;
$element-size-lg-icon-width:       $icon-md;
$element-size-md-icon-width:       $icon-sm;
$element-size-sm-icon-width:       $icon-xs;
$element-icon-color:                  $black; // $grey-1-300;
$element-side-padding:                $spacing-4;
$element-with-icon-side-padding:      $element-side-padding + $element-size-md-icon-width + $spacing-2;
$element-border-color-focus:          $color-1-500;

@mixin form-svg-base-styles {
    position: absolute;
    @include vertically-center;
    color: $element-icon-color;
    z-index: 2;
    pointer-events: none; // Needed so you can click the icon and still trigger the select
}

@mixin element-size-lg {
    input,
    select,
    textarea {
        @include text-base;
    }

    input,
    select {
        min-height: $element-size-lg;
    }

    .svg-icon {
        width: $element-size-lg-icon-width;
        height: $element-size-lg-icon-width;
    }
}

.element-size-lg {
    @include element-size-lg();
}

@mixin element-size-md {
    input,
    select,
    textarea {
        @include text-sm;
    }

    input,
    select {
        min-height: $element-size-md;
    }

    .svg-icon {
        width: $element-size-md-icon-width;
        height: $element-size-md-icon-width;
    }
}

.element-size-md {
    @include element-size-md();
}

@mixin element-size-sm {
    input,
    select,
    textarea {
        @include text-xs;
    }

    input,
    select {
        min-height: $element-size-sm;
    }

    .svg-icon {
        width: $element-size-sm-icon-width;
        height: $element-size-sm-icon-width;
    }
}

.element-size-sm {
    @include element-size-sm();
}

.form-element {
    & > .element-wrapper {
        position: relative;

        // .svg-icon {
        & > .svg-icon-wrapper {
            @include form-svg-base-styles();
        }

        // .prefix-icon .svg-icon {
        & > .prefix-icon.svg-icon-wrapper {
            left: $element-side-padding;
        }

        // .suffix-icon .svg-icon {
        & > .suffix-icon.svg-icon-wrapper {
            right: $element-side-padding;
        }

        &.has-prefix-icon {
            input {
                padding-left: $element-with-icon-side-padding;
            }
        }

        &.has-suffix-icon {
            input {
                padding-right: $element-with-icon-side-padding;
            }
        }

        input,
        textarea,
        select {
            @include transition(border-color);

            &:focus {
                @include transition(border-color, 200ms);
                border-color: $element-border-color-focus;
                outline: none;
            }
        }
    }
}
