@use "../../1-core" as *;
@use "../../3-components/Buttons/buttons" as *;

.hero-banner-text-content {
    padding-bottom: $spacing-10;
    margin-top: $spacing-6;

    .container {
        @include grid();
    }

    .hbt-text-content-wrapper {
        @include col(4);
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .hbt-anchors-container {
        @include col(4);
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        // row-gap: $spacing-3;
        margin-bottom: $spacing-8;

        .hbt-anchor {
            @include text-sm;
            text-align: center;
            margin: 0 $spacing-2 $spacing-3;
        }

        .hbt-anchor-separator {
            width: $spacing-1;
            height: $spacing-1;
            border-radius: calc(#{$spacing-1} / 2);
            margin: 0 $spacing-1;
            background-color: var(--theme-background-accent);
        }

        .hbt-anchor-separator:first-of-type {
            display: none;
        }

        &.no-page-anchor {
            display: none;
        }
    }

    .hbt-mobile-anchors-container {
        @include col(4);
        margin-bottom: $spacing-8;

        &.no-page-anchor {
            display: none;
        }
    }

    .hbt-section-separator {
        border: none;
        border-bottom: 1px solid var(--theme-divider, --theme-background-accent);
        margin: 0 0 $spacing-8;

        &.no-page-anchor {
            display: none;
        }
    }

    .hbt-subheading,
    .hbt-desc {
        text-align: center;
        margin-bottom: $spacing-7;
    }

    .hbt-subheading {
        @include text-4xl;
        color: var(--theme-text-prominent);
    }

    .hbt-desc {
        @include text-sm;
        color: var(--theme-text-core);
    }

    .hbt-buttons-container {
        display: flex;
        justify-content: center;
        flex-direction: column;

        .hbt-button:not(:first-child) {
            margin-top: $spacing-5;
        }

        .hbt-button {
            justify-content: center;
        }
    }
}

@media (max-width: $screen-xs-max) {
    .hero-banner-text-content {
        .hbt-anchors-container {
            display: none;
        }
    }
}

@media (min-width: $screen-sm) {
    .hero-banner-text-content {
        padding-bottom: $spacing-12;
        margin-top: $spacing-8;

        .hbt-text-content-wrapper {
            @include col(8);
        }

        .hbt-anchors-container {
            @include col(8);
        }

        .hbt-mobile-anchors-container {
            display: none;
        }

        .hbt-section-separator {
            margin-bottom: $spacing-10;
        }

        .hbt-subheading {
            @include text-5xl;
        }

        .hbt-desc {
            @include text-md;
            margin: 0 $spacing-5 $spacing-7;
        }

        .hbt-buttons-container {
            flex-direction: row;

            .hbt-button {
                @include button-size('lg');
            }

            .hbt-button:not(:first-child) {
                margin-top: 0;
                margin-left: $spacing-4;
            }
        }
    }
}

@media (min-width: $screen-lg) {
    .hero-banner-text-content {
        .hbt-text-content-wrapper {
            @include col(8, 2);
        }

        .hbt-anchors-container {
            @include col(8, 2);
        }
    }
}
