@use "./grids-breakpoints" as *;
@use "./spacing-scale" as *;

@mixin global-section-vertical-spacing($mobileSpace: $spacing-9, $tabletSpace: $spacing-10, $desktopSpace: $spacing-12) {
    margin: $mobileSpace 0 $mobileSpace;

    @media (min-width: $screen-sm) {
        margin: $tabletSpace 0 $tabletSpace;
    }

    @media (min-width: $screen-lg) {
        margin: $desktopSpace 0 $desktopSpace;
    }
}
