@use "../../1-core" as *;

.ribbon {
    background-color: var(--ribbon-background-color);
    color: var(--ribbon-text-color);
    padding: $spacing-2 $spacing-4;
    border-radius: $spacing-1;
    @include text-2xs;
    @include text-black;
    line-height: $spacing-3;
    margin-bottom: $spacing-6;

    --ribbon-background-color: #{$color-gold-500};
    --ribbon-text-color: #{$black};

    &.ribbon-skin-2 {
        --ribbon-background-color: #{$color-1-500};
        --ribbon-text-color: #{$white};
    }

    .ribbon-text {
        display: block;
    }

    .ribbon-badge {
        background-color: $color-gold-100;
        border-radius: 50%;
        display: inline-block;
        width: 14px;
        height: 14px;
        margin-left: 4px;
        text-align: center;
    }
}
