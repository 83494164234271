@use "../../1-core" as *;

.text-icon-grid-block {
    // margin: $spacing-8 0 $spacing-8;
    @include global-section-vertical-spacing;

    .container {
        @include grid();
    }

    .grid-copy {
        @include col(4);
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-bottom: $spacing-6;
    }

    .grid-copy-title {
        @include text-3xl;
        @include text-black;
        margin-bottom: $spacing-4;
    }

    .grid-copy-desc {
        @include text-md;
    }

    .grid-tile-container {
        @include col(4);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: $spacing-5;
    }
}

@media (min-width: $screen-sm) {
    .text-icon-grid-block {
        // margin: $spacing-9 0 $spacing-9;

        .grid-copy {
            @include col(6, 1);
            margin-bottom: $spacing-7;
        }

        .grid-copy-title {
            @include text-4xl;
        }

        .grid-tile-container {
            @include col(8);
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@media (min-width: $screen-lg) {
    .text-icon-grid-block {
        // margin: $spacing-10 0 $spacing-10;

        .grid-copy {
            @include col(8, 2);
        }

        .grid-copy-title {
            @include text-5xl;
        }

        .grid-tile-container {
            @include col(12);
            grid-template-columns: repeat(3, 1fr);
        }

        &.desktop-tile-per-row-2 {
            .grid-tile-container {
                grid-template-columns: repeat(2, 1fr);
            }

            .text-icon-grid {
                padding: $spacing-9;

                .ticg-icon-container .svg-icon {
                    width: $spacing-11;
                    height: $spacing-11;
                }
            }
        }

        &.side-layout {
            .grid-copy {
                @include col(4);
                text-align: left;
            }

            .grid-tile-container {
                @include col(8);
                grid-template-columns: repeat(2, 1fr);
            }
        }
    }
}
