@use "../../1-core" as *;
@use "../../3-components/Buttons/buttons" as *;

$cta-secondary-min-height-desktop: 236px;

.cta-secondary {
    @include grid;
    align-items: center;
    // padding: $spacing-7 0;

    .ctas-content-wrapper {
        @include col(4);
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .ctas-icon-container {
        width: $spacing-9;

        .ctas-icon .svg-icon {
            width: 100%;
            height: 100%;
        }
    }

    .ctas-copy {
        flex: 1;
        text-align: center;
    }

    .ctas-heading {
        @include text-xl;
    }

    .ctas-subheading {
        @include text-sm;
        margin-top: $spacing-4;
    }

    .ctas-action {
        margin-top: $spacing-6;
        display: flex;
        justify-content: flex-end;

        .ctas-action-button {
            --theme-button-primary: var(--theme-background);
            --theme-button-primary-border: var(--theme-background);
            --theme-button-primary-label: var(--theme-link-text);
            --theme-button-primary-hover: var(--theme-background-reversed);
            --theme-button-primary-border-hover: var(--theme-background);
            --theme-button-primary-label-hover: $black;
        }
    }
}

@media (min-width: $screen-sm) {
    .cta-secondary {
        .ctas-content-wrapper {
            @include col(6,1);
        }

        .ctas-heading {
            @include text-2xl;
        }
    }
}

@media (min-width: $screen-lg) {
    .cta-secondary {
        min-height: $cta-secondary-min-height-desktop;
        // padding: $spacing-9 0;

        .ctas-content-wrapper {
            @include col(10,1);
            flex-direction: row;
        }

        .ctas-icon-container {
            align-self: flex-start;
            margin-right: $spacing-5;
        }

        .ctas-copy {
            text-align: left;
        }

        .ctas-action {
            margin-left: $spacing-10;
            margin-top: 0;
        }

        .ctas-action-button {
            @include button-size('lg');
        }
    }
}
