@use "../../1-core" as *;
@use "../../3-components/Buttons/buttons" as *;

$hero-banner-desktop-ratio: '1440/693';
$hero-banner-min-height-mobile: 552px;
$hero-banner-min-height-tablet: 692px;

.hero-banner {
    position: relative;
    min-height: $hero-banner-min-height-mobile;
    display: flex;
    z-index: 1;

    .hrbn-image-container {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;

        img {
            width: 100%;
            min-height: $hero-banner-min-height-mobile;
        }

        figure picture {
            background-color: transparent;
            clip-path: url(#clip-path-hero-banner-mobile);
        }

        picture::after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba($color: $black, $alpha: 0.2);
            z-index: 1;
        }
    }

    .container {
        @include grid;
        align-items: center;
        padding-bottom: $spacing-10;
        justify-content: center;
    }

    .hrbn-copy {
        @include col(4);
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .hrbn-title {
        @include text-5xl;
        @include text-black;
        text-align: center;
        color: $white;
        margin-bottom: $spacing-5;
    }
}

@media (min-width: $screen-sm) {
    .hero-banner {
        min-height: $hero-banner-min-height-tablet;

        .hrbn-image-container {
            img {
                min-height: $hero-banner-min-height-tablet;
            }

            figure picture {
                clip-path: url(#clip-path-hero-banner-tablet);
            }
        }

        .container {
            padding-bottom: $spacing-13;
            justify-content: left;
        }

        .hrbn-copy {
            @include col(5, 1);
            align-items: flex-start;
        }

        .hrbn-title {
            @include text-7xl;
            text-align: left;
        }

        .hrbn-button {
            @include button-size('lg');
        }
    }
}

@media (min-width: $screen-lg) {
    .hero-banner {
        .hrbn-image-container {
            figure picture {
                clip-path: url(#clip-path-hero-banner-desktop);
            }
        }

        .hrbn-copy {
            @include col(6, 1);
            margin-bottom: $spacing-8;
        }

        .hrbn-title {
            @include text-8xl;
        }
    }
}

@media (min-width: ($screen-2xl + 1)) {
    .hero-banner {
        aspect-ratio: #{$hero-banner-desktop-ratio};

        .hrbn-image-container {
            --aspect-ratio: #{$hero-banner-desktop-ratio};
            --image-alignment: center center;
            clip-path: url(#clip-path-hero-banner-desktop-xl);

            img {
                width: 100%;
                height: auto;
            }

            figure picture {
                height: 0;
                width: 100%;
                padding-bottom: calc(100% / (var(--aspect-ratio)));
                position: relative;
                clip-path: url(#clip-path-hero-banner-desktop-xl);
            }
        }
    }
}
