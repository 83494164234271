@use "../../1-core" as *;

.notification {
    border: 2px solid var(--notif-border-color);
    border-radius: $spacing-2;
    background-color: var(--notif-background-color);
    padding: $spacing-6;

    .notif-icon {
        margin-bottom: $spacing-4;

        .svg-icon {
            color: var(--notif-text-color);
        }
    }

    .notif-content {
        color: var(--notif-text-color);
    }

    &.notification-warning {
        --notif-border-color: #{$support-warning-500};
        --notif-background-color: #{$support-warning-50};
        --notif-text-color: #{$support-warning-500};
    }

    &.notification-error {
        --notif-border-color: #{$support-error-500};
        --notif-background-color: #{$support-error-50};
        --notif-text-color: #{$support-error-500};
    }

    --notif-border-color: #{$support-success-500};
    --notif-background-color: #{$support-success-50};
    --notif-text-color: #{$support-success-500};
}

@media (min-width: $screen-sm) {
    .notification {
        .notif-container {
            display: flex;
            align-items: flex-start;
        }

        .notif-icon {
            margin-right: $spacing-4;
        }
    }
}
