@use "../../1-core" as *;
@use "../../1-core/typography/list-styles" as *;

$breadcrumb-item-min-height:    44px;
$breadcrumb-item-icon-size:     16px;

@mixin breadcrumb-link-color {
    color: $black;
    box-shadow: none;
    text-decoration: none;
    outline: 2px solid transparent;
    outline-offset: 2px;
    padding: 14px 0;

    &:hover {
        color: $grey-1-700;
    }

    &:active {
        color: $black;
        @include text-black;
    }

    &:focus-visible {
        color: $grey-1-700;
        outline-color: $grey-1-700;
    }
}

.breadcrumb-block {
    background-color: $white;

    .container {
        padding: 0 $spacing-4;
    }

    .breadcrumb {
        @include list-reset;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        .breadcrumb-item {
            @include text-xs;
            display: inline-flex;
            align-items: center;
            min-height: $breadcrumb-item-min-height;

            .svg-icon-wrapper {
                width: $breadcrumb-item-icon-size;
                height: $breadcrumb-item-icon-size;
                align-items: center;
            }

            .breadcrumb-item-icon {
                margin: 0 $spacing-2;

                .svg-icon {
                    color: $grey-1-700;
                }
            }

            &:first-of-type {
                .breadcrumb-item-icon {
                    display: none;
                }
            }
        }

        .breadcrumb-item-link {
            display: inline-flex;
            align-items: center;
            @include breadcrumb-link-color;
        }

        .active-breadcrumb-item {
            @include text-black;
        }
    }
}

@media (min-width: $screen-sm) {
    .breadcrumb-block {
        .container {
            padding: 0 $spacing-5;
        }
    }
}

@media (min-width: $screen-md) {
    .breadcrumb-block {
        .container {
            padding: 0 $spacing-6;
        }
    }
}

@media (min-width: $screen-xl) {
    .breadcrumb-block {
        .container {
            padding: 0 $spacing-5;
        }
    }
}

@media (max-width: $screen-xs-max) {
    .breadcrumb-block {
        .breadcrumb {
            .breadcrumb-item:not(:nth-of-type(2)) {
                display: none;
            }

            .breadcrumb-item:nth-of-type(2) {
                .breadcrumb-item-icon {
                    margin-left: 0;
                    transform: rotate(180deg);
                }
            }
        }
    }
}
