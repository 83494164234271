@use '../../../1-core' as *;
// @use '../../../1-core/typography/' as *;
@use '../../../3-components/Buttons/buttons' as *;

.form-element.filter-checkbox {
    @include button-skin("secondary");
    @include button-size("md");
    border-color: $grey-1-300;
    position: relative;

    .checkbox label {
        @include text-xs;
        color: $black;
        padding-left: $spacing-5;

        --label-height: 28px;
        --checkbox-size: 20px;

        &::before,
        &::after {
            width: var(--checkbox-size);
            height: var(--checkbox-size);
            margin-top: calc(((var(--label-height) - var(--checkbox-size)) / 2) - 1px);
        }
    }
}
