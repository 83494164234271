@use "../../1-core" as *;

.inpage-form-block:not(.footer-form-block) {
    @include global-section-vertical-spacing($spacing-10, $spacing-12, $spacing-13);

    .container {
        @include grid();
        grid-template-areas:
            "intro intro intro intro"
            "tile  tile  tile  tile"
            "form  form  form  form";
    }

    .form-intro {
        grid-area: intro;
        @include rich-text-spacing;
        text-align: center;
        margin-bottom: 32px;

        &.subscription-form-intro {
            text-align: left;

            .svg-icon-wrapper {
                svg {
                    width: $spacing-10;
                    height: $spacing-10;
                }
            }

            h2 {
                @include text-black;
                @include text-3xl;
                margin: $spacing-4 0;
            }

            p {
                @include text-base;
            }
        }
    }

    .form-intro-link-container {
        margin-top: $spacing-6;
        text-align: center;

        .form-intro-link {
            display: flex;
            align-items: center;
            justify-content: center;

            .svg-icon-wrapper {
                margin-right: $spacing-4;
            }
        }
    }

    .tel-link {
        @include text-2xl;
        @include text-black;
        box-shadow: none;

        .svg-icon-wrapper {
            margin-right: 16px;
        }

        .svg-icon-wrapper .svg-icon {
            color: $black;
        }
    }

    .grid-tile-container {
        grid-area: tile;
        border-top: 1px solid $grey-1-100;
        padding-top: $spacing-8;
        margin-bottom: $spacing-6;
    }

    .custom-form-wrapper {
        grid-area: form;
    }

    .notification.hidden {
        display: none;
    }
}

@media (min-width: $screen-sm) {
    .inpage-form-block:not(.footer-form-block) {
        .container {
            grid-template-areas:
                ". intro intro intro intro intro intro ."
                ". tile  tile  tile  tile  tile  tile  ."
                ". form  form  form  form  form  form  .";
        }

        .form-intro {
            margin-bottom: $spacing-7;
        }

        .grid-tile-container {
            margin-top: $spacing-7;
            padding-top: $spacing-9;
        }
    }
}

@media (min-width: $screen-lg) {
    .inpage-form-block:not(.footer-form-block) {
        .container {
            grid-template-areas:
                ". intro intro intro intro . form form form form form ."
                ". tile  tile  tile  tile  . form form form form form ."
                ". .     .     .     .     . form form form form form ."
                ". .     .     .     .     . form form form form form .";
        }

        .form-intro {
            text-align: left;

            &.subscription-form-intro {
                h2 {
                    @include text-5xl;
                }
            }
        }

        .form-intro-link-container {
            .form-intro-link {
                justify-content: left;
            }
        }

        .grid-tile-container {
            border-top: none;
            padding-top: 0;
        }
    }
}
