@use "../../1-core" as *;
@use "./desktop-mega-menu" as *;

.desktop-mega-menu-group {
    padding: 0;
    height: 100%;

    .desktop-mega-menu-item-title {
        @include text-2xs;
        line-height: #{$spacing-5 + $spacing-1}; // use text sm line height;
        @include text-normal;
        // text-transform: uppercase;
        margin-bottom: $spacing-4;
    }

    .desktop-mega-menu-item-title-link {
        @include text-sm;
        @include text-black;
        @include dmm-link-color;
    }

    .desktop-mega-menu-item {
        padding-left: 0;

        &::before {
            display: none;
        }
    }

    .desktop-mega-menu-link {
        @include text-sm;
        margin-bottom: $spacing-4;
        @include dmm-link-color;
    }
}

.mega-menu-column-container.sidebar {
    .desktop-mega-menu-group {
        display: flex;
        flex-direction: column;

        .desktop-mega-menu-item-list {
            flex: 1;
            display: flex;
            flex-direction: column;
        }

        .desktop-mega-menu-cta {
            margin-top: auto;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: stretch;
        }

        .cta-intro {
            @include text-xs;
        }

        .mega-menu-main-cta,
        .mega-menu-secondary-cta {
            margin-top: $spacing-4;
            // margin-bottom: $spacing-5;
        }

        .cta-intro + .mega-menu-main-cta,
        .cta-intro + .mega-menu-secondary-cta {
            margin-top: $spacing-5;
        }
    }
}
