@use "../../1-core" as *;

// .page-content {
//     > div[class|=embedsocial] {
//         @include container;
//     }
// }

.embed-social-block {
    margin: $spacing-10 0;

    .grid-copy {
        @include col(4);
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-bottom: $spacing-6;
    }

    .grid-copy-title {
        @include text-4xl;
        @include text-black;
        margin-bottom: $spacing-4;
    }

    .grid-copy-desc {
        @include text-md;
    }
}

@media (min-width: $screen-sm) {
    .embed-social-block {
        margin: $spacing-12 0;

        .grid-copy {
            margin-bottom: $spacing-8;
        }
    }
}

@media (min-width: $screen-lg) {
    .embed-social-block {
        .grid-copy {
            margin-bottom: $spacing-9;
        }

        .grid-copy-title {
            @include text-5xl;
        }
    }
}
