@use "../../1-core" as *;

.accordion .accordion-item.accordion-image-item {
    .accordion-heading button.accordion-title {
        padding: 0;
        border-radius: $spacing-2;
    }

    .acii-image-container {
        max-width: 320px;
    }

    .acii-image {
        border-radius: $spacing-2 0 0 $spacing-2;
        // clip-path: ellipse(67% 150% at 32% 43%);
        clip-path: ellipse(59% 100% at 37% 40%);
    }

    .acii-heading {
        flex: 1;
        display: flex;
        align-items: center;
        gap: $spacing-6;
        justify-content: space-between;
        padding: $spacing-5 $spacing-6;
        text-align: left;
    }

    .accordion-content {
        transform: translateY(-$spacing-2);
    }

    &.is-open {
        .accordion-content-display {
            padding: $spacing-5 $spacing-6;
        }

        .accordion-content {
            border-radius: 0 0 $spacing-2 $spacing-2;
            border: 1px solid $grey-1-900;
            border-top: none;
        }
    }
}
