@use "../../1-core" as *;

$alert-banner-container-padding-mobile:         16px 52px 16px 16px;
$alert-banner-container-padding-tablet:         16px 68px 16px 24px;
$alert-banner-container-padding-desktop:        8px 68px 8px 24px;

.alert-banner {
    position: relative;
    background-color: var(--theme-background);

    .alert-banner-container {
        padding: $alert-banner-container-padding-mobile;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .albnr-message-container {
        display: flex;
        align-items: center;
    }

    .albnr-message-content {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        row-gap: $spacing-4;
    }

    .albnr-message-text {
        margin-right: $spacing-5;

        &,
        & p {
            @include text-xs;
        }
    }

    .albnr-message-icon {
        margin-right: $spacing-2;
        align-self: flex-start;
    }

    .albnr-close-button {
        position: absolute;
        top: 0;
        right: $spacing-1;
        border: none;
    }
}

.alert-banner.dismissed {
    display: none;
}

@media (min-width: $screen-sm) {
    .alert-banner {
        .alert-banner-container {
            padding: $alert-banner-container-padding-tablet;
        }

        .albnr-message-text {
            &,
            & p {
                @include text-sm;
            }
        }

        .albnr-message-icon {
            margin: $spacing-2 $spacing-2 0 0;
        }

        .albnr-close-button {
            top: #{$spacing-2 + $spacing-1};
            right: #{$spacing-2 + $spacing-1};
        }
    }
}

@media (min-width: $screen-lg) {
    .alert-banner {
        .alert-banner-container {
            padding: $alert-banner-container-padding-desktop;
        }

        .albnr-close-button {
            top: $spacing-1;
            right: 0;
        }
    }
}
