@use "../../1-core/colours" as *;

$placeholder-color: $grey-1-300;

::placeholder {
    color: $placeholder-color;
    width: 100%;
}

select:invalid {
    color: $placeholder-color;
}
