@use "../../1-core" as *;

.headline-block {
    // margin: $spacing-8 0 $spacing-8;
    @include global-section-vertical-spacing;

    .container {
        @include grid();
    }

    .hdln-text-wrapper {
        @include col(4);
    }

    .hdln-heading {
        @include text-3xl;
        @include text-black;
        text-align: center;
    }

    .hdln-text,
    .hdln-text p {
        @include text-md;
        text-align: center;
        margin-bottom: $spacing-4;
    }
}

@media (min-width: $screen-sm) {
    .headline-block {
        .hdln-text-wrapper {
            @include col(6, 1);
        }

        .hdln-heading {
            @include text-4xl;
        }
    }
}

@media (min-width: $screen-lg) {
    .headline-block {
        .hdln-text-wrapper {
            @include col(8, 2);
        }

        .hdln-heading {
            @include text-5xl;
        }

        .hdln-text {
            @include text-md;
        }
    }
}
