@use "../../1-core/" as *;

$multistep-modal-cta-width: 348px;

.multistep-modal-cta {
    position: fixed;
    z-index: 999;
    bottom: 0;
    right: 0;
    width: 100%;
    background-color: $white;
    @include light-shadow('lg');

    &.show-on-scroll,
    &.show-with-delay {
        visibility: hidden;
        opacity: 0;
        transition: opacity 500ms ease-in;
    }

    .multistep-modal-header {
        display: flex;
        justify-content: space-between;

        .author-name {
            @include text-bold;
        }

        .multistep-modal-close {
            border: none;
            background: none;

            .svg-icon {
                color: $black;
            }
        }
    }

    .multistep-modal-content {
        display: flex;
        flex-direction: column;
        gap: $spacing-4;
        align-items: flex-start;
        transition: height 400ms, opacity 800ms ease-in;

        form {
            align-self: stretch;

            .multistep-modal-btn {
                margin-top: $spacing-4;
            }
        }

        .multistep-modal-btn.hidden {
            display: none;
        }
    }

    .multistep-modal-content + .multistep-modal-content {
        position: absolute;
        visibility: hidden;
        opacity: 0;
        height: 0;
    }

    .notification.hidden {
        display: none;
    }
}

@media (max-width: $screen-sm-max) {
    .multistep-modal-cta {
        &.teal-theme {
            background-color: $color-1-50;

            .multistep-modal-header {
                background-color: $color-1-50;
            }
        }

        .multistep-modal-header {
            margin: $spacing-4 $spacing-4 0 $spacing-4;
            padding-bottom: $spacing-4;
            border-bottom: 1px solid $grey-1-100;

            .author-avatar {
                width: $spacing-6;
                margin-right: $spacing-3;
            }

            .author-name {
                @include text-xs;
            }

            .author-info {
                display: flex;
                gap: $spacing-2;
                align-items: center;
            }
        }

        .multistep-modal-content {
            padding: $spacing-4;

            &.step-one {
                flex-direction: row;
                gap: $spacing-2;
                justify-content: space-between;
                align-items: center;
            }
        }
    }
}

@media (min-width: $screen-md) {
    .multistep-modal-cta {
        padding: $spacing-5;
        width: $multistep-modal-cta-width;
        border-radius: $spacing-1;
        bottom: $spacing-5;
        right: $spacing-5;

        .multistep-modal-header {
            position: relative;
            margin-bottom: $spacing-4;

            .multistep-modal-close {
                position: absolute;
                top: 0;
                right: 0;
            }
        }
    }
}
