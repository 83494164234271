@use "../../1-core" as *;

.property-map-block {
    @include global-section-vertical-spacing;

    .container {
        @include grid();
    }

    .grid-copy {
        @include col(4);
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-bottom: $spacing-6;
    }

    .grid-copy-title {
        @include text-3xl;
        @include text-black;
        margin-bottom: $spacing-4;
    }

    .grid-copy-desc {
        @include text-md;
    }

    .property-map,
    .property-map-iframe {
        @include col(4);
        // width: 100%;
        height: 306px;
    }
}

@media (min-width: $screen-sm) {
    .property-map-block {
        .grid-copy {
            @include col(6, 1);
            margin-bottom: $spacing-7;
        }

        .grid-copy-title {
            @include text-4xl;
        }

        .property-map,
        .property-map-iframe {
            @include col(8);
            height: 640px;
        }
    }
}

@media (min-width: $screen-lg) {
    .property-map-block {
        .grid-copy {
            @include col(8, 2);
        }

        .grid-copy-title {
            @include text-5xl;
        }

        .property-map,
        .property-map-iframe {
            @include col(12);
        }
    }
}
