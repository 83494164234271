@use '../../1-core' as *;
@use '../../1-core/typography/list-styles' as *;
@use '../../3-components/Buttons/buttons' as *;

.pa-register-form-wrapper {
    @include col(4);
    // margin: $spacing-5 0;

    form {
        .validation-summary-errors {
            background-color: $support-error-100;
            padding: $spacing-4;
            border-radius: $spacing-3;

            ul li {
                @include list-reset;
                color: $support-error-500;

                &::before {
                    display: none;
                }
            }
        }

        .field-validation-error {
            margin-top: $spacing-1;
            color: $support-error-500;
        }

        .form-element {
            margin-top: $spacing-3;

            label {
                color: $black;
            }
        }

        .btn-password-toggle {
            position: absolute;
            right: $spacing-2;
            top: 0.625rem;
            cursor: pointer;
            padding: $spacing-1;
            display: flex;
            background-color: transparent;
            border: none;

            .icon-eye {
                display: none;
            }
        }

        .btn-password-toggle.show-pass {
            .icon-eye {
                display: initial;
            }

            .icon-eye-off {
                display: none;
            }
        }

        input[type="password"] {
            letter-spacing: 0.15rem;
            padding-right: $spacing-7;
        }

        .form-actions {
            margin-top: $spacing-5;
            display: flex;
            flex-direction: row-reverse;
            gap: $spacing-5;

            .btn-register-action {
                flex: 1;

                --btn-bg: var(--theme-background, #{$btn-skin-primary-hover-bg});
                --btn-color: var(--theme-text-prominent, #{$btn-skin-primary-hover-text-color});
                --btn-border-color: var(--theme-background, #{$btn-skin-primary-hover-border-color});

                &:hover,
                &:focus-visible {
                    --btn-bg: var(--theme-button-primary, #{$btn-skin-primary-hover-bg});
                    --btn-color: var(--theme-button-primary-label, #{$btn-skin-primary-hover-text-color});
                    --btn-border-color: var(--theme-button-primary-border, #{$btn-skin-primary-hover-border-color});
                }

                &:focus-visible {
                    --btn-outline: #{$btn-skin-primary-focus-outline-width} solid var(--theme-button-primary-border, #{$btn-skin-primary-focus-outline-color});
                    --btn-outline-offset: #{$btn-skin-primary-focus-outline-offset};
                }
            }
        }
    }
}

@media (min-width: $screen-sm) {
    .pa-register-form-wrapper {
        @include col(6,1);
        // margin: $spacing-7 0;
    }
}

@media (min-width: $screen-lg) {
    .pa-register-form-wrapper {
        @include col(6,3);
    }
}
