@use "../../1-core/" as *;
@use "../../3-components/Buttons/buttons" as *;
@use "../../3-components/Image/caption" as *;

$video-embed-icon-size-mobile:  $spacing-9;
$video-embed-icon-size-desktop: $spacing-11;
$video-embed-icon-size:         96px;
$video-play-primary-colour:     $color-1-500;
$video-play-secondary-colour:   $white;

.video-embed {
    position: relative;
    border: 1px solid $grey-1-300;
    border-radius: $spacing-2;

    .video-embed-video-container {
        position: relative;
        padding-bottom: calc(100% / (var(--aspect-ratio)));

        iframe {
            border-radius: $spacing-2 $spacing-2 0 0;
        }
    }

    .video-embed-img-container {
        position: relative;
        border-radius: $spacing-2 $spacing-2 0 0;

        figure picture {
            background-color: transparent;
        }

        img {
            border-radius: $spacing-2 $spacing-2 0 0;
        }
    }

    .video-embed-video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .video-embed-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: rgba($color: $black, $alpha: 0.4);
        border-radius: $spacing-2 $spacing-2 0 0;
    }

    .video-embed-info {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 32px;
        pointer-events: none;
    }

    .video-embed-info-row {
        display: flex;
        align-items: center;
    }

    .video-embed-info-heading {
        @include text-xl;
        @include text-black;
        color: $black;
    }

    .video-embed-info-subheading {
        @include text-2xs;
        @include text-bold;
        color: $black;
    }

    .video-embed-play-btn {
        @include button-reset();
        display: inline-flex;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: calc(-1 * #{$video-embed-icon-size-mobile} / 2);
        margin-left: calc(-1 * #{$video-embed-icon-size-mobile} / 2);

        .svg-icon {
            @include transition(all);
            width: $video-embed-icon-size-mobile;
            height: $video-embed-icon-size-mobile;
            color: $white;

            path:first-child {
                color: $video-play-secondary-colour;
                fill-opacity: 1;
            }

            path:nth-child(2) {
                color: $video-play-primary-colour;
            }
        }
    }

    .video-caption {
        @include text-sm;
        color: $black;
    }

    &.show-video {
        .video-embed-overlay,
        .video-embed-img-container {
            display: none;
        }
    }

    &:not(.has-info) {
        .video-embed-video-container {
            iframe {
                border-radius: $spacing-2;
            }
        }

        .video-embed-img-container {
            border-radius: $spacing-2;

            img {
                border-radius: $spacing-2;
            }
        }

        .video-embed-overlay {
            border-radius: $spacing-2;
        }
    }
}

@media (min-width: $screen-sm) {
    .video-embed {
        .video-embed-video-container {
            iframe {
                border-radius: $spacing-2;
            }
        }

        .video-embed-img-container {
            border-radius: $spacing-2;

            img {
                border-radius: $spacing-2;
            }
        }

        .video-embed-overlay {
            border-radius: $spacing-2;
        }

        .video-embed-info {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            flex-direction: row;
            padding: 24px 40px;
            z-index: 1;
        }

        .video-embed-info-separator {
            display: block;
            border-left: 1px solid $white;
            margin: 0 32px;
        }

        .info-heading-row {
            max-width: 40%;
        }

        .info-caption-row {
            flex: 1;
        }

        .video-embed-info-heading {
            @include text-3xl;
            color: $white;
        }

        .video-embed-info-subheading {
            color: $white;
        }

        .video-embed-play-btn {
            @include button-reset();
            margin-top: calc(-1 * #{$video-embed-icon-size-desktop} / 2);
            margin-left: calc(-1 * #{$video-embed-icon-size-desktop} / 2);

            .svg-icon {
                width: $video-embed-icon-size-desktop;
                height: $video-embed-icon-size-desktop;
            }

            &:hover {
                .svg-icon,
                &::before {
                    transform: scale(1.2, 1.2);
                }
            }
        }

        .video-caption {
            color: $white;
        }

        &.show-video {
            .video-embed-info {
                display: none;
            }
        }
    }
}
