@use "../../1-core" as *;

.property-for-sale-detail {
    display: flex;
    flex-direction: column;
    gap: $spacing-5;

    .pld-status {
        width: fit-content;

        .ribbon {
            margin: 0;
        }
    }

    .pld-header {
        .pld-heading {
            @include text-5xl;
            @include text-black;
        }

        .pld-address {
            @include text-lg;
            margin-bottom: $spacing-2;
        }
    }

    .pld-features {
        display: flex;
        gap: $spacing-3;
        flex-direction: column;
    }

    .pld-desc {
        @include text-md;
    }

    .pld-price {
        @include text-3xl;
        @include text-bold;
        margin-top: $spacing-5;
    }
}

@media (min-width: $screen-xs) {
    .property-for-sale-detail {
        .pld-features {
            flex-direction: row;
            flex-wrap: wrap;
        }
    }
}
