@use "../../1-core/" as *;

.body-content-block {
    @include global-section-vertical-spacing;

    .container {
        @include grid;
    }

    .bcb-content {
        @include col(4);
    }

    .bcb-title {
        display: flex;
        align-items: center;
        margin-bottom: $spacing-4;
    }

    .bcb-heading {
        @include text-3xl;
        @include text-black;
    }

    .bcb-content-text {
        @include rich-text-spacing;

        img {
            width: 100%;
            margin: $spacing-4 0;
            border-radius: $spacing-2;
        }

        iframe {
            width: 100%;
            height: auto;
            min-height: 240px;
            aspect-ratio: 16/10;
            margin: $spacing-4 0;
            border-radius: $spacing-2;
        }
    }
}

@media (min-width: $screen-sm) {
    .body-content-block {
        .bcb-content {
            @include col(6, 1);
        }

        .bcb-heading {
            @include text-4xl;
        }

        .bcb-content-text {
            img {
                margin: $spacing-6 0;
            }

            iframe {
                margin: $spacing-6 0;
                min-height: 360px;
            }
        }
    }
}

@media (min-width: $screen-lg) {
    .body-content-block {
        .bcb-content {
            @include col(8, 2);
        }

        .bcb-heading {
            @include text-5xl;
        }

        .bcb-content-text {
            img {
                margin: $spacing-9 0;
            }

            iframe {
                margin: $spacing-9 0;
                min-height: 480px;
            }
        }
    }
}
