@use "../../1-core" as *;
@use "../../1-core/typography/list-styles" as *;

.property-feature-item {
    display: flex;
    margin-bottom: $spacing-2;

    .svg-icon-wrapper {
        margin-right: $spacing-4;
    }

    & > span,
    & > a {
        @include text-sm;
    }

    a {
        @include text-link;
        outline: none;
        text-decoration: underline;
        text-underline-offset: $spacing-2;
        word-break: break-word;

        &:not(.has-icon) {
            box-shadow: none;
        }
    }
}
