@use "../../1-core" as *;

.inpage-form-center-block {
    @include global-section-vertical-spacing($spacing-5, $spacing-7, $spacing-8);

    &.remove-top-spacing {
        margin-top: 0;
    }

    .container {
        @include grid();
    }
}

