@use "../../1-core" as *;

$icon-width: 133px;

.error-page {
    min-height: 100vh;

    .error-content {
        margin: $spacing-10 auto;
        max-width: 600px;
        padding: $spacing-9;
        text-align: center;

        h2 {
            @include text-xl;
        }

        .svg-icon-wrapper {
            background-color: $color-1-50;
            border-radius: 100%;
            padding: $spacing-6;
        }

        .svg-icon {
            height: auto;
            width: $icon-width;
        }

        .error-text {
            margin-top: $spacing-6;
        }

        p {
            @include text-md;
            margin: $spacing-2 0;

            a {
                color: $black;
            }
        }

        .btn {
            display: inline-flex;
            margin: $spacing-6 0;
        }
    }

    &.server-error {
        display: flex;
        align-items: center;

        .error-content {
            margin: 0 auto;
        }
    }
}

@media (min-width: $screen-lg) {
    .error-page {
        .error-content {
            margin-bottom: $spacing-12;
            margin-top: $spacing-12;
        }
    }
}
