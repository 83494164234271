@use "../colours" as *;

:root {// base styles
    // text
    --theme-text-prominent: #{$black};
    --theme-text-core: #{$black};
    --theme-text-reversed: #{$white};
    --theme-text-deemphasised: #{$grey-1-300};

    // text link
    --theme-link-text: #{$color-1-500};
    --theme-link-text-hover: #{$black};
    --theme-link-text-active: #{$grey-1-700};

    // background
    --theme-background: #{$color-1-500};
    --theme-background-accent: #{$color-2-500};
    --theme-background-reversed: #{$white};

    // icon
    --theme-icon: #{$black};
    --theme-icon-deemphasised: #{$grey-1-300};

    // border
    --theme-border: #{$grey-1-300};
    --theme-border-deemphasised: #{$black};
    --theme-divider: #{$grey-1-100};

    // button - primary
    --theme-button-primary: #{$color-1-500};
    --theme-button-primary-border: #{$color-1-500};
    --theme-button-primary-label: #{$white};
    --theme-button-primary-hover: #{$color-2-500};
    --theme-button-primary-border-hover: #{$color-2-500};
    --theme-button-primary-label-hover: #{$white};
    --theme-button-primary-active: #{$black};
    --theme-button-primary-border-active: #{$black};
    --theme-button-primary-label-active: #{$white};

    // button - secondary
    --theme-button-secondary: transparent;
    --theme-button-secondary-border: #{$color-1-500};
    --theme-button-secondary-label: #{$color-1-500};
    --theme-button-secondary-hover: transparent;
    --theme-button-secondary-border-hover: #{$color-2-500};
    --theme-button-secondary-label-hover: #{$color-2-500};
    --theme-button-secondary-active: transparent;
    --theme-button-secondary-border-active: #{$black};
    --theme-button-secondary-label-active: #{$black};

    // button - tertiary
    --theme-button-tertiary: transparent;
    --theme-button-tertiary-border: transparent;
    --theme-button-tertiary-label: #{$color-1-500};
    --theme-button-tertiary-hover: transparent;
    --theme-button-tertiary-border-hover: transparent;
    --theme-button-tertiary-label-hover: #{$color-2-500};
    --theme-button-tertiary-active: transparent;
    --theme-button-tertiary-border-active: transparent;
    --theme-button-tertiary-label-active: #{$black};

    // button - disabled
    --theme-button-disabled: #{$grey-1-50};
    --theme-button-disabled-border: #{$grey-1-50};
    --theme-button-disabled-label: #{$grey-1-300};

    // form
    --theme-form-label: #{$black};
    --theme-form-selectors: #{$color-1-500};
    --theme-form-selectors-hover: #{$color-1-500}19;
}
