@use "spacing-scale" as *;

// Breakpoints
// =======================================================================
$screen-xs:               375px;
$screen-sm:               640px;
$screen-md:               768px;
$screen-lg:               1024px;
$screen-xl:               1280px;
$screen-2xl:              1440px;

$screen-xxs-max:          ($screen-xs - 1);
$screen-xs-max:           ($screen-sm - 1);
$screen-sm-max:           ($screen-md - 1);
$screen-md-max:           ($screen-lg - 1);
$screen-lg-max:           ($screen-xl - 1);
$screen-xl-max:           ($screen-2xl - 1);

$container-max-width:     $screen-2xl; // how wide should the container be before it 'locks'

// In Design parlance:
// grid margin = the either side of a grid.
// gutter = gap between columns.

$container-padding-xs:    $spacing-4;
$container-padding-sm:    $spacing-5;
$container-padding-md:    $spacing-6;
$container-padding-lg:    $spacing-6;
$container-padding-xl:    $spacing-9;
$container-padding-2xl:   $spacing-10;

$gutter-width-xs:         $spacing-4;
$gutter-width-sm:         $spacing-5;
$gutter-width-md:         $spacing-5;
$gutter-width-lg:         $spacing-5;
$gutter-width-xl:         $spacing-5;
$gutter-width-2xl:        $spacing-5;

$grid-cols-xs:            4;
$grid-cols-sm:            8;
$grid-cols-lg:            12;

/*
* CONTAINER STYLES
*/
@mixin container {
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    max-width: $container-max-width;

    @media (min-width: 0) and (max-width: $screen-xs-max) {
        padding-left: $container-padding-xs;
        padding-right: $container-padding-xs;
    }

    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
        padding-left: $container-padding-sm;
        padding-right: $container-padding-sm;
    }

    @media (min-width: $screen-md) and (max-width: $screen-md-max) {
        padding-left: $container-padding-md;
        padding-right: $container-padding-md;
    }

    @media (min-width: $screen-lg) and (max-width: $screen-lg-max) {
        padding-left: $container-padding-lg;
        padding-right: $container-padding-lg;
    }

    @media (min-width: $screen-xl) and (max-width: $screen-xl-max) {
        padding-left: $container-padding-xl;
        padding-right: $container-padding-xl;
    }

    @media (min-width: $screen-2xl) {
        padding-left: $container-padding-2xl;
        padding-right: $container-padding-2xl;
    }
}

.container {
    @include container();
}

@mixin responsive-gutters($withRowGap: true) {
    @media (min-width: 0) and (max-width: $screen-xs-max) {
        column-gap: $gutter-width-xs;

        @if ($withRowGap) {
            row-gap: $gutter-width-xs;
        }
    }

    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
        column-gap: $gutter-width-sm;

        @if ($withRowGap) {
            row-gap: $gutter-width-sm;
        }
    }

    @media (min-width: $screen-md) and (max-width: $screen-md-max) {
        column-gap: $gutter-width-md;

        @if ($withRowGap) {
            row-gap: $gutter-width-md;
        }
    }

    @media (min-width: $screen-lg) and (max-width: $screen-lg-max) {
        column-gap: $gutter-width-lg;

        @if ($withRowGap) {
            row-gap: $gutter-width-lg;
        }
    }

    @media (min-width: $screen-xl) and (max-width: $screen-xl-max) {
        column-gap: $gutter-width-xl;

        @if ($withRowGap) {
            row-gap: $gutter-width-xl;
        }
    }

    @media (min-width: $screen-2xl) {
        column-gap: $gutter-width-2xl;

        @if ($withRowGap) {
            row-gap: $gutter-width-2xl;
        }
    }
}

@mixin base-grid-styles($withRowGap: true) {
    display: grid;
    @include responsive-gutters($withRowGap);
    grid-auto-flow: row;
}

@mixin grid($withRowGap: true) {
    @include base-grid-styles($withRowGap);
    grid-auto-flow: row;

    @media (min-width: 0) and (max-width: $screen-xs-max) {
        grid-template-columns: repeat($grid-cols-xs, 1fr);
    }

    @media (min-width: $screen-sm) and (max-width: $screen-md-max) {
        grid-template-columns: repeat($grid-cols-sm, 1fr);
    }

    @media (min-width: $screen-lg) {
        grid-template-columns: repeat($grid-cols-lg, 1fr);
    }
}

@mixin subgrid($col) {
    display: grid;
    grid-template-columns: repeat($col, 1fr);
    @include responsive-gutters();
}

@mixin col($col, $offset: 0) {
    @if ($offset > 0) {
        grid-column: #{$offset + 1} / span $col;
    }
    @else {
        grid-column: span $col;
    }
}


@mixin container-bleed {
    @media (min-width: 0) and (max-width: $screen-xs-max) {
        margin-left: -$container-padding-xs;
        margin-right: -$container-padding-xs;
    }

    @media (min-width: $screen-sm) and (max-width: $screen-sm-max) {
        margin-left: -$container-padding-sm;
        margin-right: -$container-padding-sm;
    }

    @media (min-width: $screen-md) and (max-width: $screen-md-max) {
        margin-left: -$container-padding-md;
        margin-right: -$container-padding-md;
    }

    @media (min-width: $screen-lg) and (max-width: $screen-lg-max) {
        margin-left: -$container-padding-lg;
        margin-right: -$container-padding-lg;
    }

    @media (min-width: $screen-xl) and (max-width: $screen-xl-max) {
        margin-left: -$container-padding-xl;
        margin-right: -$container-padding-xl;
    }

    @media (min-width: $screen-2xl) {
        margin-left: -$container-padding-2xl;
        margin-right: -$container-padding-2xl;
    }
}

@mixin full-bleed {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
}

@mixin six-col-grid {
    @include base-grid-styles;
    grid-template-columns: repeat(6, 1fr);
}

.six-col-grid {
    @include six-col-grid;
}

@mixin five-col-grid {
    @include base-grid-styles;
    grid-template-columns: repeat(5, 1fr);
}

.five-col-grid {
    @include five-col-grid;
}

@mixin four-col-grid {
    @include base-grid-styles;
    grid-template-columns: repeat(4, 1fr);
}

.four-col-grid {
    @include four-col-grid;
}

@mixin three-col-grid {
    @include base-grid-styles;
    grid-template-columns: repeat(3, 1fr);
}

.three-col-grid {
    @include three-col-grid;
}

@mixin two-col-grid {
    @include base-grid-styles;
    grid-template-columns: repeat(2, 1fr);
}

.two-col-grid {
    @include two-col-grid;
}

@mixin one-col-grid {
    @include base-grid-styles;
    grid-template-columns: 1fr;
}

.one-col-grid {
    @include one-col-grid;
}
