@use '../../../1-core' as *;
@use '../../../1-core/typography/list-styles' as *;

.header-search-hits {
    margin-bottom: $spacing-5;
    background-color: $white;
    position: relative;
    border-radius: 0 0 $spacing-5 $spacing-5;
    border: 1px solid $black;
    padding: $spacing-7 $spacing-1 $spacing-4 0;
    margin-top: -$spacing-5;
    border-top: none;
    z-index: map-get($z-index, "ground");

    h4 {
        margin-bottom: $spacing-1;
    }

    .ais-Hits {
        overflow-y: scroll;
        max-height: 250px;
    }

    .ais-Hits-list {
        @include list-reset;
    }
}
