@use "../../../1-core" as *;
@use "../../../1-core/typography/list-styles" as *;

.custom-refinement-list-mobile {
    display: flex;
    flex-direction: column;

    .refinement-button {
        margin: 0;
        justify-content: space-between;
        @include text-xs;
        @include text-bold;

        --btn-border-color: #{$grey-1-300};
        --btn-color: $black;

        .button-active-refinement-count {
            background-color: $color-1-300;
            padding: $spacing-1;
            border-radius: 20px;
            min-width: 28px;
            display: inline-block;
        }
    }

    .refinement-container {
        display: none;
    }

    .ais-RefinementList-list {
        @include list-reset;
        padding: 0;
        border: none;

        .ais-RefinementList-item {
            .ais-RefinementList-label {
                margin-top: 8px;
                margin-bottom: 0;

                &::before {
                    content: " ";
                    display: block;
                    width: $spacing-5;
                    height: $spacing-5;
                    background: $color-1-500;
                    mask-image: url("/assets/icons/square.svg");
                    background-size: $spacing-5 $spacing-5;
                    mask-repeat: no-repeat;
                    flex-shrink: 0;
                }
            }

            .ais-RefinementList-labelText {
                margin-left: $spacing-2;
                color: $black;
            }

            .ais-RefinementList-count {
                margin-left: $spacing-2;
                color: $black;

                &::before {
                    content: "(";
                }

                &::after {
                    content: ")";
                }
            }

            &.ais-RefinementList-item--selected .ais-RefinementList-label {
                &::before {
                    mask-image: url("/assets/icons/check-square-fill.svg");
                }
            }

            .ais-RefinementList-checkbox {
                display: none;
            }
        }
    }

    &.expanded {
        .refinement-container {
            display: block;
            position: relative;
        }

        .refinement-button {
            border-bottom: none;
            border-radius: $spacing-5 $spacing-5 0 0;

            .svg-icon {
                transform: rotate(180deg);
            }
        }

        .ais-RefinementList {
            border: 2px solid $grey-1-300;
            border-top: none;
            border-radius: 0 0 $spacing-5 $spacing-5;
            background-color: $white;
            position: absolute;
            left: 0;
            right: 0;
            z-index: 3;
        }

        .ais-RefinementList--noRefinement {
            height: $spacing-5;
        }
    }
}
