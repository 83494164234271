@use "../../1-core" as *;
@use "../../1-core/typography/list-styles" as *;
@use "../../1-core/typography/link-styles" as *;
@use "../../3-components/Buttons/buttons" as *;

.property-for-sale-listing {
    margin-bottom: $spacing-10;

    .result-info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: $spacing-7;
        margin-top: $spacing-7;

        .bl-custom-stats {
            margin-bottom: 0;
        }

        .mode-toggle-container {
            display: flex;
            flex-shrink: 0;

            .view-toggle-button {
                --btn-side-padding: #{$spacing-2};
            }

            .view-toggle-button:not(:first-of-type) {
                margin-left: $spacing-4;
            }
        }
    }

    .prsc-info-action {
        .action-button {
            text-align: center;
        }
    }

    .property-for-sale-listing-results .ais-Hits .ais-Hits-list {
        @include list-reset;
        display: grid;
        grid-template-columns: 1fr;
        gap: $spacing-5;
        margin-bottom: $spacing-5;
    }

    .ais-Pagination {
        margin-bottom: $spacing-7;

        .ais-Pagination-list {
            justify-content: center;
        }
    }

    .mobile-filter-toggle-button {
        margin-bottom: $spacing-7;
        justify-content: center;
        width: 100%;
    }
}

@media (max-width: $screen-xs-max) {
    .property-for-sale-listing {
        .result-info {
            .mode-toggle-container .view-toggle-button .btn-text {
                display: none;
            }
        }

        .filters-container.tablet-filter {
            display: none;
        }

        .prsc-info-action {
            .action-button {
                @include button-size('sm');
            }
        }
    }

    .ReactModalPortal .mobile-filter-modal-overlay {
        align-items: flex-end;
    }

    .ReactModalPortal .mobile-filter-modal-container {
        max-width: unset;
        width: 100%;
        border-radius: $spacing-2 $spacing-2 0 0;
        position: relative;
        max-height: 80vh;
    }

    .ReactModalPortal .mobile-filter-modal-container .modal-header {
        h2 {
            @include text-sm;
        }

        button.modal-close {
            color: $black;
        }
    }

    .ReactModalPortal .mobile-filter-modal-container .modal-content {
        .accordion {
            padding: 0;
            background-color: $white;
            margin: $spacing-6 0;
        }

        .accordion > .container {
            padding: 0;
        }

        .accordion .accordion-item {
            .accordion-heading button.accordion-title {
                @include text-xs;
                background-color: $white;
                color: $black;
                padding: $spacing-2 0;
            }

            &.is-open {
                border-radius: 0;

                .accordion-heading button.accordion-title .svg-icon {
                    transform: rotateZ(180deg);
                }

                .accordion-content {
                    border-radius: 0;
                }
            }
        }

        .accordion .accordion-content-display {
            padding: 0;
        }

        .mobile-filter-checkbox {
            grid-column: span 4;
            color: $black;

            .checkbox label {
                color: $black;
                @include text-xs;
                @include text-bold;

                &::before,
                &::after {
                    width: 24px;
                    height: 24px;
                    background-color: $color-1-500;
                }
            }
        }

        .filter-separator {
            width: 100vw;
            border-width: 1px 0 0;
            border-color: $grey-1-100;
            position: relative;
            left: 50%;
            transform: translateX(-50%);

            &.black-separator {
                border-color: $black;
            }
        }

        .mobile-filter-action {
            margin: 24px 0;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .ais-ClearRefinements-button {
                @include button-reset;
                color: $color-1-500;
                box-shadow: 0 1px 0 $color-1-500;
                // margin-bottom: $spacing-4;

                &.ais-ClearRefinements-button--disabled {
                    display: none;
                }
            }
        }
    }
}

@media (min-width: $screen-sm) {
    .property-for-sale-listing {
        .result-info {
            margin-bottom: $spacing-4;
            margin-top: $spacing-5;

            .mode-toggle-container {
                .view-toggle-button {
                    --btn-side-padding: #{$spacing-5};
                }
            }
        }

        .hr-separator {
            &.extra-bottom-space {
                margin-bottom: $spacing-9;
            }
        }

        .filters-container.tablet-filter {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: $spacing-7;

            .filter-group-label {
                @include text-2xs;
                text-transform: uppercase;
                width: 100%;
                margin-bottom: $spacing-4;
                display: flex;
                justify-content: space-between;
            }

            .custom-refinement-list {
                margin-right: $spacing-4;
                margin-bottom: $spacing-4;

                &:nth-child(3n + 1) {
                    margin-right: 0;
                }
            }

            .ais-ClearRefinements {
                display: flex;
                align-items: center;
            }

            .ais-ClearRefinements-button {
                @include button-reset;
                color: $color-1-500;
                box-shadow: 0 1px 0 $color-1-500;
                // margin-bottom: $spacing-4;

                &.ais-ClearRefinements-button--disabled {
                    display: none;
                }
            }
        }

        .mobile-filter-toggle-button {
            display: none;
        }
    }
}

@media (min-width: $screen-lg) {
    .property-for-sale-listing {
        .property-for-sale-listing-results .ais-Hits .ais-Hits-list {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}
