@use "../../1-core" as *;

.author-component {
    display: flex;
    align-items: center;

    .author-avatar {
        width: $spacing-8;
        border-radius: 50%;
        position: relative;
        margin-right: $spacing-4;
        @include light-shadow(sm);
        border: 2px solid $white;

        img {
            border-radius: 50%;
        }
    }

    .author-info {
        .author-name {
            color: $black;
            @include text-sm;
        }

        .author-role {
            color: #{$black}99;
            @include text-2xs;
        }
    }
}
