@use "../../1-core" as *;

$video-embed-icon-size-desktop-xl:      104px;

.video-section {
    // margin: $spacing-8 0 $spacing-8;
    @include global-section-vertical-spacing;

    .container {
        @include grid();
    }

    .grid-copy {
        @include col(4);
        display: flex;
        flex-direction: column;
        text-align: center;
        margin-bottom: $spacing-6;
    }

    .grid-copy-title {
        @include text-4xl;
        @include text-black;
        margin-bottom: $spacing-4;
    }

    .grid-copy-desc {
        @include text-md;
    }

    .vdosc-row {
        @include col(4);
        display: flex;
        flex-direction: column;
        gap: $spacing-5;
    }
}

@media (min-width: $screen-sm) {
    .video-section {
        // margin: $spacing-10 0 $spacing-10;

        .grid-copy {
            @include col(6, 1);
        }

        .vdosc-row {
            @include col(8);
        }
    }
}

@media (min-width: $screen-lg) {
    .video-section {
        // margin: $spacing-10 0 $spacing-10;

        .grid-copy {
            @include col(8,2);
        }

        .grid-copy-title {
            @include text-5xl;
        }

        .vdosc-row {
            @include col(12);
        }
    }
}

@media (min-width: $screen-xl) {
    .video-section {
        .vdosc-row {
            display: grid;
            grid-template-columns: repeat(2, 1fr);

            &.big-first-video {
                .video-embed:first-of-type {
                    @include col(2);

                    .video-embed-play-btn {
                        margin-top: calc(-1 * #{$video-embed-icon-size-desktop-xl} / 2);
                        margin-left: calc(-1 * #{$video-embed-icon-size-desktop-xl} / 2);

                        .svg-icon {
                            width: $video-embed-icon-size-desktop-xl;
                            height: $video-embed-icon-size-desktop-xl;
                        }
                    }
                }
            }
        }
    }
}
