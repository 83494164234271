@use '../../1-core' as *;

@forward "./widgets/header-search-hits";
@forward "./widgets/header-search-result";

.header-search {
    height: $spacing-8;
    position: relative;

    button {
        z-index: map-get($z-index, 'above-ground');
    }

    .ais-SearchBox-input {
        z-index: map-get($z-index, 'above-ground');
        @include transition(box-shadow border-color);
    }

    .ais-SearchBox-submit,
    .ais-SearchBox-reset {
        top: 14px;

        .svg-icon {
            width: 20px;
            height: 20px;
        }
    }

    &.active {
        .ais-SearchBox-input {
            outline: 1px solid $black;
            @include light-shadow('sm');
            @include transition(box-shadow border-color, 200ms);
            padding-right: $spacing-11;
        }
    }

    .header-hits {
        display: none;
    }
}
