@use "../../1-core" as *;
@use "../../3-components/Buttons/buttons" as *;

$input-autocomplete-clear-button-size: 26px;
$input-autocomplete-clear-button-top: 10px;
$input-autocomplete-clear-button-right: $spacing-2;
$input-autocomplete-clear-button-label-icon-size: 22px;

.input-autocomplete-wrapper {
    width: 100%;

    input.show-autocomplete {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }

    .downshift-dropdown {
        background-color: $white;
        position: absolute;
        width: 100%;
        top: 100%;
        padding-bottom: $spacing-5;
        border: 1px solid $grey-1-500;
        border-radius: 0 0 $spacing-6 $spacing-6;
    }

    .dropdown-item {
        padding: $spacing-2 $spacing-3;
        cursor: pointer;
    }

    .input-autocomplete-row {
        .input-autocomplete-element {
            position: relative;
        }
    }

    .input-autocomplete-clear-button {
        position: absolute;
        width: $input-autocomplete-clear-button-size;
        height: $input-autocomplete-clear-button-size;
        right: $input-autocomplete-clear-button-right;
        top: $input-autocomplete-clear-button-top;
        visibility: hidden;

        --btn-height: #{$spacing-5};
        --label-icon-width: #{$input-autocomplete-clear-button-label-icon-size};
        --btn-side-padding: 0;
        --btn-bg: transparent;
        --btn-border-color: transparent;
        --btn-color: #{$grey-1-500};

        &.visible {
            visibility: visible;
        }
    }

    .input-autocomplete-action-button {
        @include transition();
        visibility: hidden;
        width: 100%;
        margin-top: $spacing-2;

        &.visible {
            visibility: visible;
        }
    }
}

@media (min-width: $screen-sm) {
    .input-autocomplete-wrapper {
        display: flex;
        align-items: flex-end;

        .input-autocomplete-action-button {
            width: auto;
        }
    }
}

@media (min-width: $screen-lg) {
    .input-autocomplete-wrapper {
        .input-autocomplete-row {
            display: flex;

            .input-autocomplete-element {
                flex: 1;
                margin-right: $spacing-4;
            }
        }
    }
}
