@use "../../1-core" as *;
@use "./desktop-header" as *;

@mixin dsh-link-color {
    color: $white;
    outline: 2px solid transparent;
    outline-offset: 2px;
    text-decoration: none;

    &:hover {
        color: $color-1-500;

        .dsh-nav-item-icon .svg-icon {
            color: $color-1-500;
        }
    }

    &:active {
        color: $color-dustyblue-500;

        .dsh-nav-item-icon .svg-icon {
            color: $color-dustyblue-500;
        }
    }

    &:focus-visible {
        outline: 2px solid $white;
    }
}

.desktop-header {
    .secondary-header {
        background-color: $color-navy-500;
        height: 48px;

        .dsh-container {
            display: flex;
            justify-content: flex-end;
            color: white;
            height: 100%;
        }

        .dsh-alert-button {
            border-radius: 0;
            background-color: $color-dustyblue-500;

            --btn-height: unset;
        }

        .dsh-nav-container {
            display: flex;
            align-items: center;
        }

        .dsh-nav-items {
            display: flex;
            align-items: center;
            margin: $spacing-2 0;
        }

        .dsh-nav-item {
            padding-left: 0;
            margin-left: $spacing-5;
            margin-bottom: 0;
            position: initial;

            &::before {
                display: none;
            }

            .dsh-mega-menu {
                background-color: $grey-1-50;
                position: absolute;
                left: 0;
                right: 0;
                top: 48px;
                height: 0;
                display: none;
                opacity: 0;
            }
        }

        .dsh-nav-item.active-dsh-nav-item {
            .dsh-mega-menu {
                height: 48px;
                display: block;
                opacity: 1;
                border-bottom: 1px solid $grey-1-100;
            }
        }

        .dsh-mega-menu {
            & > .container {
                display: flex;
                height: 100%;
                align-items: center;
                justify-content: flex-end;
            }

            .dsh-mega-menu-list {
                display: flex;
            }

            .dsh-mega-menu-item {
                padding-left: 0;
                margin-bottom: 0;
                margin-left: 24px;

                &::before {
                    display: none;
                }
            }
        }

        .dsh-nav-item-link {
            display: flex;
            gap: $spacing-2;
            align-items: center;
            @include text-xs;
            @include dsh-link-color;

            .dsh-nav-item-icon {
                margin-left: 4px;

                .svg-icon {
                    width: 16px;
                    height: 16px;
                    color: #{$white}4C;
                }
            }
        }

        .dsh-mega-menu-item-link {
            @include text-xs;
            @include dh-link-color;
        }

        .dsh-nav-separator {
            width: 1px;
            height: 100%;
            background-color: $grey-1-500;
        }

        .dsh-label-font {
            cursor: default;
        }

        .dsh-label-font-small {
            @include text-xs;
        }

        .dsh-font-size-change-button {
            color: $white;
            border: 2px solid transparent;
        }
    }
}
