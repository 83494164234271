@use "../../1-core" as *;

.kentico-extended-form-block {
    background-color: $color-1-50;
    padding: $spacing-10 0;

    .container {
        @include grid();
    }

    .form-intro {
        @include col(4);
        @include rich-text-spacing;
        margin-bottom: $spacing-9;

        .btn {
            display: inline-flex;
            height: auto;
            min-height: var(--btn-height);
        }
    }

    .custom-form-wrapper {
        @include col(4);
        border-radius: $spacing-2;
        background-color: $white;
        padding: $spacing-9 $spacing-5;
        @include light-shadow(sm);

        .notification.hidden {
            display: none;
        }
    }

    .custom-form-wrapper input {
        width: auto;
    }

    .custom-form-wrapper form {
        label {
            color: $black;
        }

        .editing-form-control-nested-control {
            display: flex;
            flex-direction: column;
        }
    }
}

@media (min-width: $screen-sm) {
    .kentico-extended-form-block {
        padding: $spacing-12 0;

        .form-intro {
            @include col(6, 1);
        }

        .custom-form-wrapper {
            @include col(8);
            padding: $spacing-10 $spacing-9;
        }
    }
}

@media (min-width: $screen-lg) {
    .kentico-extended-form-block {
        padding: $spacing-13 0;

        .container {
            // @include grid();
            grid-template-areas: "intro intro intro intro . form form form form form form form";
        }

        .form-intro {
            // @include col(4);
            grid-area: intro;
        }

        .custom-form-wrapper {
            @include col(7);
            // padding: $spacing-10;
            grid-area: form;
        }
    }
}
